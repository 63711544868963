import {t} from "PlattixUI/PlattixReactCore/i18n";
import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import {LoginFormContent} from "PlattixUI/core/forms/account/LoginForm";
import {availableUrlParams, LoginResponseModel} from "pages/Auth/Auth";
import {doPost, isHttpError} from "PlattixUI/PlattixReactCore/api/Api";
import {UserLoginModel} from "PlattixUI/PlattixReactCore/UserSlice";
import {BaseApiEndpoints} from "PlattixUI/PlattixReactCore/api/BaseApiEndpointMap";
import {toast} from "react-toastify";
import {HttpError} from "PlattixUI/PlattixReactCore/CoreTypes";

export interface LoginProps {
    
}

export function Login(props: LoginProps) {
    const [submitResultErrors, setSubmitResultErrors] = useState<HttpError | undefined>(undefined);
    
    const urlParams = useMemo(() => new URLSearchParams(window.location.search), [window.location.search]);
    const returnUrl = urlParams.get(availableUrlParams.returnUrl);
    
    const onSubmitHandler = async (model) => {
        const m = {
            ...model,
            returnUrl: returnUrl,
        };
        
        const response = await doPost<UserLoginModel>(BaseApiEndpoints.Account.Login, m) as (LoginResponseModel | HttpError);
        if (isHttpError(response)) {
            if (!!response?.errors?.['needs2fa']) toast.info(t('Login.Error.2FA.Toast'))
            else toast.error(t('Error'));
            setSubmitResultErrors(response);
            return;
        }
        toast.success(t('Success'))
        !!response.redirectUri && window.location.replace(response.redirectUri);
    };
    
    return (
        <>
            <div className="login-form">
                <h5 className="login-title">{t('Login')}</h5>
                <LoginFormContent 
                    onSubmit={onSubmitHandler} 
                    returnUrl={returnUrl}
                    needs2FA={!!submitResultErrors?.errors?.['needs2fa']}
                    emailNotConfirmed={!!submitResultErrors?.errors?.['emailNotConfirmed']}
                    globalError={submitResultErrors?.errors?.['_global']}
                />
            </div>
        </>
    );
}