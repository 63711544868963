import React, {
    Fragment,
    InputHTMLAttributes,
    MouseEventHandler,
    PropsWithChildren,
    Ref,
    RefObject,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import {
    Controller,
    FieldError,
    FieldPath,
    Path,
    PathValue,
    useFieldArray,
    UseFormRegisterReturn,
    UseFormReturn,
    ValidateResult
} from "react-hook-form";
import {HttpError} from "PlattixUI/PlattixReactCore/CoreTypes";
import {getErrorMessage, isFieldError} from "./formUtil";
import {filterProps} from "PlattixUI/util/ElementProperties";
import NumberFormat, {FormatInputValueFunction, NumberFormatValues} from "react-number-format";
import {getDecimalSeparator, getThousandSeparator} from "PlattixUI/PlattixReactCore/types/Languages";
import htmlRaw from "PlattixUI/util/HtmlRaw";
import {AddButton, ConfirmButton, DeleteButton} from "PlattixUI/core/components/Buttons";
import {t, useTranslation} from "PlattixUI/PlattixReactCore/i18n";
import {PlattixCodeSelect, PlattixSelect, SelectOption} from "PlattixUI/core/components/form/Select";
import {
    AccountPlattixSubmitField,
    AddressPlattixSubmitField,
    ArrayPlattixSubmitField,
    BasePlattixSubmitField,
    CodeSelectPlattixSubmitField,
    DateTimeLocalPlattixSubmitField,
    FilePlattixSubmitField,
    InvoicePlattixSubmitField,
    NumberFormatPlattixSubmitField,
    NumberPlattixSubmitField,
    PhoneNumberPlattixSubmitField,
    SelectPlattixSubmitField,
    TranslationPlattixSubmitField
} from "PlattixUI/core/components/form/Form";
import {FileUploaderProps, PlattixFileInput} from "PlattixUI/core/components/FileUploader";
import {AddressComponent, AddressComponentProps} from "PlattixUI/core/components/AddressComponent";
import {TranslationPlattixInput} from "PlattixUI/core/components/form/InputFields/TranslationPlattixInput";
import {AccountPlattixInput, ChooseAccountOptions} from "./InputFields/AccountPlattixInput";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Collapse from "@mui/material/Collapse";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons/faSearch";
import {PercentPlattixInput} from "PlattixUI/core/components/form/InputFields/PercentPlattixInput";
import {Validator} from "PlattixUI/core/forms/Validators/Validator";
import {css, styled} from "goober";
import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {ContentCardButtons, PlattixTooltip} from "../ContentCard";
import {ChooseInvoiceOptions, InvoicePlattixInput} from "./InputFields/InvoicePlattixInput";
import moment from "moment";
import {RegisterOptions} from "react-hook-form/dist/types/validator";
import PhoneInputWithCountrySelect from "react-phone-number-input"
import {Country} from "react-phone-number-input/index";
import 'react-phone-number-input/style.css';
import {SpinnerInputContainer} from "PlattixUI/core/components/Loader";


/**
 * Default html input types supported by modern browsers
 */
type DefaultInputTypes =
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week';

/**
 * All Input types that are supported
 */
export type PlattixInputTypes =
    | DefaultInputTypes
    | "textarea"
    | "textareaAutoSize"
    | "number-format"
    | "select"
    | "file"
    | 'address'
    | 'account'
    | 'code-select'
    | 'translation'
    | 'percent'
    | 'array'
    | 'invoice'
    | 'phone-number';

/**
 * Types that use a custom component for rendering and support extra options
 */
type CustomRenderedType =
    | "number-format"
    | "select"
    | "file"
    | 'address'
    | 'code-select'
    | 'translation'
    | 'account'
    | 'percent'
    | 'invoice'
    | 'datetime-local'
    | 'phone-number';

/**
 * All input types that use the default HTML inputs
 */
export type DefaultRenderedTypes = Exclude<PlattixInputTypes, CustomRenderedType>



interface PlattixInputBaseProps extends InputHTMLAttributes<HTMLInputElement> {
    type?: PlattixInputTypes
    label?: string,
    // onChange?: React.ChangeEventHandler<HTMLInputElement>,
    error?: FieldError | string | string[] | undefined | HttpError | null | React.ReactNode,

    description?: JSX.Element | string,
    suffix?: PlattixFormSuffixType,

    readOnly?: boolean,
    clickable?: 1 | 0,

    hideLabel?: boolean,

    actionButtons?: JSX.Element
    loading?: boolean;
    loadingText?: string;
}

/**
 * Number formatting options for number-format inputs
 *
 * These options are a subset form the component
 * see full docs at {@link https://www.npmjs.com/package/react-number-format}
 */
export type NumberFormatOptions = {
    /**
     * How many decimals to show
     */
    decimalScale: number;
    /**
     * Always show {@see decimalScale} decimals even if all zeros
     * default true
     */
    fixedDecimalScale?: boolean;
    /**
     * Use custom thousands separator
     * default is fetched from user locale
     */
    thousandSeparator?: boolean | string;
    /**
     * Use custom decimal separator
     * default is fetched from user locale
     */
    decimalSeparator?: string;
    /**
     * prefix to prepend on formatted value
     */
    prefix?: string;

    suffix?: string;
    format?: string | FormatInputValueFunction;
    removeFormatting?: (formattedValue: string) => string;
    mask?: string | string[];
    isNumericString?: boolean;
    allowNegative?: boolean;
    allowEmptyFormatting?: boolean;
    allowLeadingZeros?: boolean;
    type?: 'text' | 'tel' | 'password';
    isAllowed?: (values: NumberFormatValues) => boolean;
}

/**
 * Properties needed to register a controlled number-format input
 * {@see https://www.npmjs.com/package/react-number-format}
 */
interface NumberFormatProps extends PlattixInputBaseProps {
    type: "number-format",
    /**
     * Name of property to register
     */
    name: string,
    /**
     * Form hook is needed to correctly register input in form hook
     */
    formHook: UseFormReturn,
    /**
     * Number formatting options
     */
    numberFormatOptions: NumberFormatOptions,
}

/**
 * Properties needed to register a controlled number-format input
 * {@see https://www.npmjs.com/package/react-number-format}
 */
interface SelectInputProps extends PlattixInputBaseProps {
    type: "select",
    /**
     * Name of property to register
     */
    name: string,
    /**
     * Form hook is needed to correctly register input in form hook
     */
    formHook: UseFormReturn<any, object>,
    /**
     * Number formatting options
     */
    options: SelectOption[]

    /**
     * Add an unselectable option that forces the user to pick an option
     */
    chooseOption?: boolean,
    /**
     * Should the 'choose Option' option be unselectable
     */
    chooseOptionDisabled?: boolean,

    isLoading?: boolean,
    isDisabled?: boolean,
    isMulti?: boolean
}

/**
 * Properties needed to register a controlled number-format input
 * {@see https://www.npmjs.com/package/react-number-format}
 */
interface CodeSelectInputProps extends PlattixInputBaseProps {
    type: "code-select",
    /**
     * Name of property to register
     */
    name: string,
    /**
     * Form hook is needed to correctly register input in form hook
     */
    formHook: UseFormReturn<any, object>,
    /**
     * Add an unselectable option that forces the user to pick an option
     */
    chooseOption?: boolean,
    /**
     * Should the 'choose Option' option be unselectable
     */
    chooseOptionDisabled?: boolean,

    namespace?: string,
    tableName: string,

    isLoading?: boolean,
    isDisabled?: boolean,
    isMulti?: boolean
}

/**
 * Properties needed to register a controlled FileUploader
 */
export interface FileInputProps extends Omit<PlattixInputBaseProps, "onChange" | "onBlur"> {
    type: "file",
    /**
     * Name of property to register
     */
    name: string,
    /**
     * Form hook is needed to correctly register input in form hook
     */
    formHook: UseFormReturn<any, object>,
    /**
     * File Uplaod Options
     */
    options: Omit<FileUploaderProps, "setFiles">,
}

/**
 * Properties needed to register a controlled FileUploader
 */
export interface AddressInputProps extends Omit<PlattixInputBaseProps, "onChange" | "onBlur">  {
    type: "address",
    /**
     * Name of property to register
     */
    name: string,
    /**
     * Form hook is needed to correctly register input in form hook
     */
    formHook: UseFormReturn<any, object>,
    /**
     * Address component options
     */
    options: Omit<AddressComponentProps, "form" | "addressModel">
}

/**
 * Properties needed to register a controlled FileUploader
 */
export interface AccountInputProps extends PlattixInputBaseProps  {
    type: "account",
    /**
     * Name of property to register
     */
    name: string,
    /**
     * Form hook is needed to correctly register input in form hook
     */
    formHook: UseFormReturn<any, object>,
    /**
     * Address component options
     */
    options: ChooseAccountOptions,
    gridCode?: string,
    gridParameters?: { [key: string]: string }
}

/**
 * Properties needed to register a controlled InvoiceSelected
 */
export interface InvoiceInputProps extends PlattixInputBaseProps  {
    type: "invoice",
    /**
     * Name of property to register
     */
    name: string,
    /**
     * Form hook is needed to correctly register input in form hook
     */
    formHook: UseFormReturn<any, object>,
    /**
     * Address component options
     */
    options: ChooseInvoiceOptions,
    gridCode?: string,
    gridParameters?: { [key: string]: string }
}

/**
 * Properties needed to register a controlled FileUploader
 */
export interface PercentInputProps extends PlattixInputBaseProps  {
    type: "percent",
    /**
     * Name of property to register
     */
    name: string,
    /**
     * Form hook is needed to correctly register input in form hook
     */
    formHook: UseFormReturn<any, object>
}

/**
 * Properties needed to register a controlled translation input
 */
export interface TranslationInputProps extends PlattixInputBaseProps {
    type: "translation",
    /**
     * Name of property to register
     */
    name: string,
    /**
     * Form hook is needed to correctly register input in form hook
     */
    formHook: UseFormReturn,
    required?: boolean,
    collapsable?: boolean
}

/**
 * Properties for a normal registered Plattix input
 */
interface RegisteredProps extends PlattixInputBaseProps {
    register: UseFormRegisterReturn,
    type?: DefaultRenderedTypes
    name?: string,
    autoComplete?: string | undefined;
}

export type PlattixInputProps =
    NumberFormatProps
    | RegisteredProps
    | SelectInputProps
    | FileInputProps
    | AddressInputProps
    | AccountInputProps
    | TranslationInputProps
    | CodeSelectInputProps
    | PercentInputProps
    | InvoiceInputProps
    | DateTimeLocalInputProps
    | PhoneNumberPlattixInputProps


/**
 * Filter out non-default html properties from Props so they are not passed to the component
 * @param props
 */
function filterFormProps(props: any) {
    const invalidHtmlInputAttrs = ['label', 'formHook', 'validation', 'register', 'numberFormatOptions', 'hideLabel', 'actionButtons']
    return filterProps(props, invalidHtmlInputAttrs)
}

/**
 * Creates a controled Number Format input
 * @param props
 * @constructor
 */
function PlattixNumberFormatInput(props: NumberFormatProps) {
    
    const onValueChangeHandler = (values, field) => {
        // console.log(`values`, values);
        /* Het gebruik van "values.value" zorgt voor problemen met "Maximum update depth exceeded.". */
        // field.onChange(values.value);
        field.onChange(values.floatValue);
    };
    
    return <Controller
        render={({field}) => {
            if (props.type === 'number-format' && props.formHook && props.name)
                return (
                    <NumberFormat
                        {...field}
                        onValueChange={(values) => onValueChangeHandler(values, field)}
                        {...props.numberFormatOptions}
                        fixedDecimalScale={props.numberFormatOptions?.fixedDecimalScale ?? true}
                        thousandSeparator={props.numberFormatOptions?.thousandSeparator ?? getThousandSeparator()}
                        decimalSeparator={props.numberFormatOptions?.decimalSeparator ?? getDecimalSeparator()}
                        thousandsGroupStyle={"thousand"}
                        onChange={props.onChange}
                        disabled={!!props.readOnly}
                        readOnly={!!props.readOnly}
                        isNumericString={false}
                        allowEmptyFormatting
                    />
                );
            return <></>
        }}
        name={props.name}
        control={props.formHook.control}
    />
}

export interface PhoneNumberPlattixInputProps extends PlattixInputBaseProps {
    type: "phone-number",
    /**
     * Name of property to register
     */
    name: string,
    /**
     * Form hook is needed to correctly register input in form hook
     */
    formHook: UseFormReturn,
    /**
     * Number formatting options
     */
    options?: PhoneNumberInputOptions,
}

// https://www.npmjs.com/package/react-phone-number-input
export interface PhoneNumberInputOptions {
    withCountryCallingCode?: boolean;
    country?: Country;
    international?: boolean;
    defaultCountry?: Country;
    useNationalFormatForDefaultCountryValue?: boolean;
}

export function PhoneNumberPlattixInput(props: PhoneNumberPlattixInputProps) {
    const onValueChangeHandler = (value : string | undefined, field) => {
        field.onChange(value);
    };

    return <Controller
        render={({field}) => {
            if (props.type === 'phone-number' && props.formHook && props.name)
                return (
                    <PhoneInputWithCountrySelect
                        onChange={(value) => onValueChangeHandler(value?.toString(), field)}
                        disabled={!!props.readOnly}
                        readOnly={!!props.readOnly}
                        international={props.options?.international ?? true}
                        defaultCountry={props.options?.defaultCountry ?? "BE"}
                        value={field.value}
                    />
                );
            return <></>
        }}
        name={props.name}
        control={props.formHook.control}
    />
}

interface DateTimeLocalInputProps extends PlattixInputBaseProps {
    type: "datetime-local",
    /**
     * Name of property to register
     */
    name: string,
    /**
     * Form hook is needed to correctly register input in form hook
     */
    formHook: UseFormReturn,
    /**
     * Update timezone to local timezone, but do not update time value
     * @link https://momentjs.com/docs/#/manipulating/local/
     */
    keepLocalTime?: boolean
}

/**
 * DateTime picker that auto-converts datetimes with timezone to client's local datetime
 * 
 * Posted values will include the timezone of the client
 * @param props
 * @constructor
 * 
 */
function PlattixDateTimeLocalInput(props: DateTimeLocalInputProps) {
    const outputValue = (e) => {
        if (!e.target.value) {
            return undefined;
        }
        //posted values require timezone information
        return moment(e.target.value).format();
    }
    
    const inputValue = (value) => {
        if (!value) {
            return '';
        }
        //HTML input type 'datetime-local' does not accept timezones
        const formattedValue = moment(value).local(props.keepLocalTime).format('YYYY-MM-DDTHH:mm');
        return formattedValue;
    }
    
    let filteredProps = filterProps(props, ['postedFormat','keepLocalTime']);
    filteredProps = filterFormProps(filteredProps);

    return <Controller
        render={({field}) => {
            if (props.type === 'datetime-local' && props.formHook && props.name)
                return (
                    <input
                        {...filteredProps}
                        type={props.type}
                        className={`form-control`}
                        id={props.id ?? props.name}
                        defaultValue={props.value}
                        onChange={(e) => field.onChange(outputValue(e))}                       
                        value={inputValue(field.value)}
                        readOnly={!!props.readOnly}
                        disabled={!!props.readOnly}
                    />
                );
            return <></>
        }}
        name={props.name}
        control={props.formHook.control}
    />
}

export type PlattixInputRefHandler<T> = {
    // pressAlert: () => void;
    ref: RefObject<HTMLInputElement>;
};

export type InnerRef<T> = { innerRef?: Ref<T> };

export interface PlattixRequiredInputLabelProps {
    required?: boolean;
}

export function PlattixRequiredInputLabel(props: PropsWithChildren<PlattixRequiredInputLabelProps>) {
    
    if (!!props.required) return (
        <>
            {props.children}
            <PlattixTooltip
                title={t('PlattixRequiredInputLabel.Title')}
                children={(
                    <RequiredInputLabelAsterisk>*</RequiredInputLabelAsterisk>
                )}
            />
        </>
    );
    
    return (<>{props.children}</>);
}

export const RequiredInputLabelAsterisk = styled('span', React.forwardRef)(() => {
    return `
        color: var(--textColorRed);
    `;
});

export const PlattixInput = React.forwardRef<HTMLDivElement, PlattixInputProps>((props: PlattixInputProps, ref) => {

    // if (!!props.register && props.register?.name === 'decimals') console.log(props.register.ref())
    function getInputErrorMessage() {
        if (props.type === 'address') return null;
        let name;
        if (props.type === 'number-format'
            || props.type === 'select'
            || props.type === 'file'
            || props.type === 'code-select'
            || props.type === 'translation'
            || props.type === 'account'
            || props.type === 'percent'
            || props.type === 'invoice'
            || props.type === 'array'
            || props.type === 'datetime-local'
            || props.type === 'phone-number'
        ) name = props.name
        else name = props.register.name
        return getErrorMessage(name, props.error)
    }

    function getLabel(){
        if (props.label) return props.label;

        let name;
        if (props.type === 'number-format'
            || props.type === 'select'
            || props.type === 'file'
            || props.type === 'code-select'
            || props.type === 'translation'
            || props.type === 'address'
            || props.type === 'account'
            || props.type === 'percent'
            || props.type === 'invoice'
            || props.type === 'array'
            || props.type === 'datetime-local'
            || props.type === 'phone-number'
        ) name = props.name
        else name = props.register.name

        return t(name);
        // return (
        //     <PlattixRequiredInputLabel
        //         required={props.required}
        //     >
        //         {t(name)}
        //     </PlattixRequiredInputLabel>
        // );
    }

    const placeholderText = props.placeholder ?? getLabel();

    async function handleChange(event) {
        if (props.type === 'select' || props.type === 'code-select') {
            await props.onChange?.(event);
        } else {
            if (isRegisteredType(props)){
                await props.register.onChange?.(event)
            }
            if (props.type !== "file" && props.type !== "address")
                props.onChange?.(event);
        }


    }
    async function handleBlur(event) {
        if (isRegisteredType(props)){
            await props.register.onBlur(event)
        }
        if (props.type !== "file" && props.type !== "address")
            props.onBlur?.(event);
    }

    function renderInput() {
        switch (props.type) {
            case ('number-format'):
                return <PlattixNumberFormatInput {...filterProps(props, ['loading', 'loadingText'])} />
            case ('file'):
                return <PlattixFileInput {...filterProps(props, ['loading', 'loadingText'])} />
            case ('address'):
                return <AddressComponent  {...props.options} addressModel={props.name} form={props.formHook}/>
            case ('account'):
                return <AccountPlattixInput
                    {...props.options}
                    required={props.required}
                    readonly={props.readOnly}
                    options={props.options}
                    name={props.name}
                    form={props.formHook}
                    />
            case ('invoice'):
                return <InvoicePlattixInput
                    {...props.options}
                    required={props.required}
                    readonly={props.readOnly}
                    options={props.options}
                    name={props.name}
                    form={props.formHook}
                    />
            case ('translation'):
                return <TranslationPlattixInput {...filterProps(props, ['loading', 'loadingText'])}/>
            case ('select'):
                return <PlattixSelect<any>
                    {...filterFormProps(props)}
                    name={props.name}
                    form={props.formHook}
                    options={props.options}
                    chooseOption={props.chooseOption}
                    chooseOptionDisabled={props.chooseOptionDisabled}
                    onChange={handleChange}
                    isDisabled={props.readOnly}
                    required={props.required}
                    isLoading={props.isLoading}
                    isMulti={props.isMulti}
                />
            case ('code-select'):
                return <PlattixCodeSelect<any>
                    {...filterFormProps(props)}
                    name={props.name} form={props.formHook}
                    chooseOption={props.chooseOption}
                    chooseOptionDisabled={props.chooseOptionDisabled}
                    namespace={props.namespace}
                    tableName={props.tableName}
                    onChange={handleChange}
                    isDisabled={props.readOnly}
                    required={props.required}
                    isLoading={props.isLoading}
                    isMulti={props.isMulti}
                />
            case ('textarea'):
                return <textarea
                    {...filterFormProps(props)}
                    className={`form-control ${props.clickable ? 'clickable' : ''}`}
                    id={props.id ?? props.name}
                    defaultValue={props.value}
                    placeholder={placeholderText}
                    cols={4}
                    {...props.register}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            case ('textareaAutoSize'):
                return <TextareaAutosize
                    {...filterFormProps(props)}
                    className={`form-control ${props.clickable ? 'clickable' : ''}`}
                    id={props.id ?? props.name}
                    defaultValue={props.value}
                    placeholder={placeholderText}
                    minRows={4}
                    {...props.register}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            case ('percent'):
                return <PercentPlattixInput
                    {...filterFormProps(props)}
                    name={props.name}
                    formHook={props.formHook}
                    id={props.id ?? props.name}
                    defaultValue={props.value}
                    placeholder={placeholderText}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                />       
            case ('datetime-local'):
                return <PlattixDateTimeLocalInput {...filterProps(props, ['loading', 'loadingText'])} />
            case ('phone-number'):
                return <PhoneNumberPlattixInput 
                    type={"phone-number"} 
                    name={props.name} 
                    formHook={props.formHook} 
                    options={props.options}
                />
                
            default:
                return <input
                    {...filterFormProps(props)}
                    type={props.type ?? "text"}
                    className={`form-control ${props.clickable ? 'clickable' : ''}`}
                    id={props.id ?? props.name}
                    defaultValue={props.value}
                    placeholder={placeholderText}
                    autoComplete={props.autoComplete ?? 'off'}
                    // onKeyPress={(e) => {
                    //     e.key === 'Enter' && e.preventDefault();
                    // }}
                    {...props.register}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    step={(props.step !== undefined) ? props.step : (props.type === 'number') ? "any" : undefined}
                    readOnly={!!props.readOnly}
                    disabled={!!props.readOnly}
                />
        }
    }

    if (props.type === "hidden") {
        return (<div ref={ref}>
            <div>
                <input
                    {...filterFormProps(props)}
                    type={props.type}
                    className="form-control"
                    id={props.id ?? props.name}
                    onChange={(e) => handleChange(e)}
                />
            </div>
        </div>);
    } else {
        return (
            <div className={`form-group row ${props.type === 'checkbox' ? 'form-group-checkbox' : ''}`} ref={ref}>
                <div className="module-tab-content-body-group">
                    <div className={'input-wrapper'}>
                        {(!props.hideLabel && props.type !== 'address') &&
                            <label className="module-tab-content-title" htmlFor={props.id ?? props.name}>
                                {/*{htmlRaw(getLabel())}*/}
                                <PlattixRequiredInputLabel required={props.required}>
                                    {htmlRaw(getLabel())}
                                </PlattixRequiredInputLabel>
                            </label>
                        }
                        <div style={{
                            display: "flex",
                            width: props.type === 'checkbox' ? undefined : "100%",
                            alignItems: props.type === 'checkbox' ? "center" : undefined,
                            position: 'relative',
                        }}>
                            <SpinnerInputContainer loading={!!props.loading ? 'true' : undefined} loadingText={props.loadingText ?? undefined}>
                                <div style={{position: 'relative', flexGrow: 1}}>
                                    {renderInput()}
                                    {(props.suffix || props.type === 'percent') &&
                                        <PlattixFormSuffix type={props.suffix ?? 'percent'}/>}
                                </div>
                            </SpinnerInputContainer>

                            {!!props.actionButtons &&
                                <div style={{marginLeft: '10px'}}>
                                    {props.actionButtons}
                                </div>
                            }
                        </div>
                    </div>


                    {(props.error) && <span className="text-danger">{getInputErrorMessage()}</span>}

                    {props.description && <div className="formInputDescription">{props.description}</div>}
                </div>
            </div>
        );
    }
});

function isRegisteredType(props: PlattixInputProps): props is RegisteredProps{
    if (props === undefined) return false;
    return (props as RegisteredProps).register !== undefined;
}

export function PlattixCheckbox(props: RegisteredProps) {
    const {t} = useTranslation(['translation']);
    return (
        <div className="form-group row form-group-checkbox">
            <div className="module-tab-content-body-group">
                <div className={'input-wrapper'}>
                    <label 
                        className="module-tab-content-title"
                        htmlFor={props.id ?? props.name ?? undefined}
                    >
                        <PlattixRequiredInputLabel required={props.required}>
                            {htmlRaw(props.label ?? props.register.name )}
                        </PlattixRequiredInputLabel>
                    </label>
                    <input
                        {...filterFormProps(props)}
                        type={"checkbox"}
                        className="form-control"
                        id={props.id ?? props.name ?? undefined}
                        defaultValue={props.value}
                        placeholder={props.placeholder ?? props.name ?? undefined}
                        {...props.register}
                    />
                </div>

                {(props.error) &&
                    <span className="text-danger">{getErrorMessage(props.register.name, props.error)}</span>}

            </div>
        </div>
    );
}

export interface PlattixSubmitButtonProps extends InputHTMLAttributes<HTMLButtonElement> {
    name: string,
    loading?: boolean,
    form?: string,
    onClick?: MouseEventHandler<HTMLButtonElement>,
}
export function PlattixSubmitButton(props: PlattixSubmitButtonProps) {
    return (
        // <div className="module-content-tab-btns-2">
        <ConfirmButton
            disabled={props.disabled}
            loading={props.loading}
            type="submit"
            form={props.form}
            onClick={props.onClick}
        >
            {props.name}
        </ConfirmButton>
        // </div>
    )
}

export type ShowIfValue<TModel> = {field: FieldPath<TModel>, value: any, or?: boolean};
export type ShowIfSingle<TModel> = FieldPath<TModel> | boolean | ShowIfValue<TModel>;
export type ShowIfType<TModel> = ShowIfSingle<TModel> | ShowIfSingle<TModel>[];


export function isShowIfValue<TModel>(showIf: ShowIfValue<TModel> | ShowIfType<TModel>): showIf is ShowIfValue<TModel> {
    return (showIf as ShowIfValue<TModel> ).field !== undefined ;
}

export type PlattixValidatedBaseInputProps<TFieldValues> = {
    formHook: UseFormReturn<TFieldValues, object>;
    name: FieldPath<TFieldValues>;
    validation?: Validator<TFieldValues> | Validator<TFieldValues>[];
    showIf?: ShowIfType<TFieldValues>;
    readOnly?: boolean;
    onClick?: (any) => any;
    customName?: FieldPath<TFieldValues>;
    fieldName?: string;
    arrayFieldName?: string;
    // registerProps?: UseFormRegister<TFieldValues>;
    registerProps?: RegisterOptions<TFieldValues>;
}

// Dit soort type noemt men een "Discriminated Union".
export type PlattixValidatedInputProps<TFieldValues> = Omit<PlattixInputBaseProps,'name'> & PlattixValidatedBaseInputProps<TFieldValues> & (
    | NumberFormatPlattixSubmitField
    | NumberPlattixSubmitField
    | SelectPlattixSubmitField
    | CodeSelectPlattixSubmitField
    | BasePlattixSubmitField
    | FilePlattixSubmitField
    | TranslationPlattixSubmitField
    | AddressPlattixSubmitField
    | AccountPlattixSubmitField
    | ArrayPlattixSubmitField<TFieldValues>
    | InvoicePlattixSubmitField
    | DateTimeLocalPlattixSubmitField
    | PhoneNumberPlattixSubmitField
);

export type PlattixArrayValidatedInputProps<TFieldValues> = Omit<PlattixInputBaseProps,'name'> & PlattixValidatedBaseInputProps<TFieldValues> & ArrayPlattixSubmitField<TFieldValues>;

/**
 * Form component om inputs te kunnen toevoegen a.d.h.v. arrays.
 * Als je ooit tot het punt bent gekomen waarbij dit nodig is, dan is het beter om een "useFieldArray" te gebruiken.
 * Zie https://react-hook-form.com/docs/usefieldarray
 **/
export function PlattixValidatedArrayInput<TFieldValues>(props: PlattixArrayValidatedInputProps<TFieldValues>){
    const {formState: {errors}, control} = props.formHook;
    const { fields, append, remove } = useFieldArray({
        control,
        name: props.arrayName,
    });

    const [display, setDisplay] = useState(true)

    let validation: {
        [key: string]: (value: PathValue<TFieldValues, Path<TFieldValues>>)
            => ValidateResult | Promise<ValidateResult>
    } = {}


    const showIfArray = useMemo(() => {

        function showIfToArray(showIf: ShowIfType<TFieldValues> | string | undefined): FieldPath<TFieldValues>[] {
            if (showIf === undefined) return [];

            if (Array.isArray(showIf)) {
                return showIf.flatMap(v => showIfToArray(v)) as FieldPath<TFieldValues>[];
            }

            if (typeof showIf === 'boolean') return [];
            if (typeof showIf === 'string') return []
            if (isShowIfValue(showIf)) return [showIf.field]
            if (showIf) return [showIf];
            return []
        }

        return showIfToArray(props.showIf)

    }, [props.showIf])

    const watch = props.formHook.watch(showIfArray)
    useEffect(() => {
        if (props.showIf === undefined) return;

        if (!Array.isArray(props.showIf)) {
            if (typeof props.showIf === 'boolean') {
                setDisplay(props.showIf)
            } else if (isShowIfValue(props.showIf))
                setDisplay(watch[0] === props.showIf.value)
            else {
                setDisplay(watch.every(v => !!v))
            }
        } else {
            setDisplay(
                props.showIf.filter(s => typeof s === 'boolean').every(b => b)
                && props.showIf
                    .filter(s => typeof s !== 'boolean')
                    .every((v, idx) => {
                        if (isShowIfValue(v)) return v.value === watch[idx]
                        return !!watch[idx]
                    })
            )
        }
    }, [props.showIf, watch]);
    


    const fieldError = props.formHook.getFieldState(props.name).error
    const errorMessage = useMemo(() => {
        let errorType = fieldError?.type
        if (props.error && isFieldError(props.error))
            errorType = props.error.type

        if (props.validation) {
            if (Array.isArray(props.validation)) {
                const validator = props.validation.find(v => v.name === errorType)
                if (validator) return validator.getErrorMessage();
            } else {
                if (props.validation.name === errorType && props.validation.getErrorMessage()) return props.validation.getErrorMessage();
            }
        }

        return getErrorMessage(props.name, props.error ?? errors[props.name as string])
            ?? props.formHook.getFieldState(props.name).error?.message

    }, [errors, fieldError, props.error, props.formHook, props.name, props.validation])

    const dateTypes: PlattixInputTypes[] = ["date"]
    const valueAsDate = dateTypes.includes(props.type ?? 'text')
    const numberTypes: PlattixInputTypes[] = ["number", "number-format"]
    const valueAsNumber = numberTypes.includes(props.type ?? 'text')
    const getFields = () => {
        return fields.map((item, index) => {
            return <Fragment key={`${item.id}`}>
                <fieldset>
                    <legend>{props.getLabel?.(index) ??`${props.label ?? props.arrayName}: ${(index + 1).toString()}`}</legend>
                    {props.arrayFields.map((item2, ind2) => {
                    const n : FieldPath<TFieldValues> = (`${props.arrayName}.${index}.${item2.fieldName}` as string) as FieldPath<TFieldValues>;
                    const fieldProps: PlattixValidatedInputProps<TFieldValues> = {
                        name: n,
                        label: item2.label,
                        formHook: props.formHook,
                        validation: item2.validation,
                        type: "text",
                        error: props.error,
                        description: item2.description,
                        suffix: item2.suffix,
                        readOnly: props.readOnly || item2.readOnly,
                        showIf: item2.showIf,
                        onChange: item2.onChange,
                        onClick: item2.onClick,
                        actionButtons: item2.actionButtons,
                        placeholder: item2.placeholder,
                    };
    
                    switch (item2.type) {
                        case 'number-format':
                            return <PlattixValidatedInput<TFieldValues>
                                key={n}
                                {...fieldProps}
                                type={'number-format'}
                                numberFormatOptions={item2.numberFormatOptions}
                            />
                        case 'array':
                            return <PlattixValidatedArrayInput<TFieldValues>
                                key={n}
                                {...fieldProps}
                                arrayName={item2.arrayName}
                                type={'array'}
                                arrayFields={item2.arrayFields}
                            />
                        case 'number':
                            return <PlattixValidatedInput<TFieldValues>
                                key={n}
                                {...fieldProps}
                                type={'number'}
                                step={item2.step}
                            />
                        case 'select':
                            return <PlattixValidatedInput<TFieldValues>
                                key={n}
                                {...fieldProps}
                                type={'select'}
                                options={item2.options}
                                chooseOption={item2.chooseOption}
                                chooseOptionDisabled={item2.chooseOptionDisabled}
                                isMulti={item2.isMulti}
                                isDisabled={item2.isDisabled}
                                isLoading={item2.isLoading}
                            />
                        case 'code-select':
                            return <PlattixValidatedInput<TFieldValues>
                                key={n}
                                {...fieldProps}
                                type={'code-select'}
                                tableName={item2.tableName}
                                namespace={item2.namespace}
                                chooseOption={item2.chooseOption}
                                chooseOptionDisabled={item2.chooseOptionDisabled}
                                isMulti={item2.isMulti}
                                isDisabled={item2.isDisabled}
                                isLoading={item2.isLoading}
                            />
                        case 'file':
                            return <PlattixValidatedInput<TFieldValues>
                                key={n}
                                {...fieldProps}
                                type={'file'}
                                fileUploadOptions={item2.fileUploadOptions}
                            />
                        case 'address':
                            return <PlattixValidatedInput<TFieldValues>
                                key={n}
                                {...fieldProps}
                                type={'address'}
                                addressOptions={{...item2.addressOptions, isReadonly: item2.readOnly}}
                            />
                        case 'account':
                            return <PlattixValidatedInput<TFieldValues>
                                key={n}
                                {...fieldProps}
                                type={'account'}
                                accountOptions={item2.accountOptions}
                            />
                        case 'invoice':
                            return <PlattixValidatedInput<TFieldValues>
                                key={n}
                                {...fieldProps}
                                type={'invoice'}
                                invoiceOptions={item2.invoiceOptions}
                            />
                        case 'translation':
                            return <PlattixValidatedInput<TFieldValues>
                                key={n}
                                collapsable={item2.collapsable}
                                {...fieldProps}
                                type={'translation'}
                            />
                        case 'datetime-local':
                            return <PlattixValidatedInput<TFieldValues>
                                key={n}
                                {...fieldProps}
                                type={'datetime-local'}
                            />
                        default:
                            return <PlattixValidatedInput<TFieldValues>
                                key={n}
                                {...fieldProps}
                                type={item2.type}
                            />
                    }

        })}
                    {!props.readOnly && props.showDeleteButton && <ContentCardButtons>
                        <DeleteButton onClick={() => remove(index)} name={props.deleteButtonName}>
                            <FontAwesomeIcon icon={faTrash}/>
                        </DeleteButton>
                    </ContentCardButtons>}
                </fieldset>
            </Fragment>
        })
    }
    
    return <>
        <fieldset>
            {/*font-weight:bold;font-size:larger;*/}
            <legend style={{fontWeight:'bold',fontSize:"larger"}}>{props.label}</legend>
            {getFields()}
            {!props.readOnly && props.showAddButton && <ContentCardButtons>
                <AddButton onClick={() => append(props.defaultValue ?? {})} name={props.addButtonName}>
                    <FontAwesomeIcon icon={faPlus}/>
                </AddButton>
            </ContentCardButtons>}
        </fieldset>
    </>
    
    
    // return (
    //     <>
    //         {getFields()}
    //         {props.showAddButton && <ContentCardButtons>
    //             <AddButton onClick={() => append(props.defaultValue ?? {})}>
    //                 <FontAwesomeIcon icon={faPlus}/>
    //             </AddButton>
    //         </ContentCardButtons>}
    //     </>
    //    
    //    
    // );
}

export function PlattixValidatedInput<TFieldValues>(props: PlattixValidatedInputProps<TFieldValues>) {
    const {formState: {errors}} = props.formHook;

    const [display, setDisplay] = useState(true)

    let validation: {
        [key: string]: (value: PathValue<TFieldValues, Path<TFieldValues>>)
            => ValidateResult | Promise<ValidateResult>
    } = {}


    const showIfArray = useMemo(() => {

        function showIfToArray(showIf: ShowIfType<TFieldValues> | undefined): FieldPath<TFieldValues>[] {
            if (showIf === undefined) return [];

            if (Array.isArray(showIf)) {
                return showIf.flatMap(v => showIfToArray(v)) as FieldPath<TFieldValues>[];
            }

            if (typeof showIf === 'boolean') return [];
            if (isShowIfValue(showIf)) return [showIf.field]
            if (showIf) return [showIf];
            return []
        }

        return showIfToArray(props.showIf)

    }, [props.showIf])

    const watch = props.formHook.watch(showIfArray)
    useEffect(() => {
        if (props.showIf === undefined) return;

        if (!Array.isArray(props.showIf)) {
            if (typeof props.showIf === 'boolean') {
                setDisplay(props.showIf)
            } else if (isShowIfValue(props.showIf))
                setDisplay(watch[0] === props.showIf.value)
            else {
                setDisplay(watch.every(v => !!v))
            }
        } else {
            setDisplay(
                props.showIf.filter(s => typeof s === 'boolean').every(b => b)
                && props.showIf
                    .filter(s => typeof s !== 'boolean')
                    .every((v, idx) => {
                        if (isShowIfValue(v) && !v.or) {
                            return v.value === watch[idx]
                        }
                        return !!watch[idx]
                    })
                && props.showIf
                    .filter(s => typeof s !== 'boolean')
                    .some((v, idx) => {
                        if (isShowIfValue(v) && v.or) {
                            return v.value === watch[idx]
                        }
                        return true
                    })
            )
        }
    }, [props.showIf, watch]);

    if (props.validation) {
        if (Array.isArray(props.validation)) {
            props.validation.forEach(v => v._register(props))
            validation = Object.fromEntries(props.validation.map(v => [v.name, (x) => v.validate(x)]))
        } else if (props.validation instanceof Validator) {
            const validator = props.validation;
            validator._register(props);
            
            validation[props.validation.name] = (v) => validator.validate(v);
        }
    }

    const fieldError = props.formHook.getFieldState(props.name).error
    const errorMessage = useMemo(() => {
        let errorType = fieldError?.type
        if (props.error && isFieldError(props.error))
            errorType = props.error.type
        
        // if (!!props.error && isHttpError((props.error))) {
        //     return getErrorMessage(nestedInputKeyHandler(props.name), props.error ?? errors[props.name as string])
        //         ?? props.formHook.getFieldState(props.name).error?.message;
        // }

        if (props.validation) {
            if (Array.isArray(props.validation)) {
                const validator = props.validation.find(v => v.name === errorType)
                if (validator) return validator.getErrorMessage();
            } else {
                if (props.validation.name === errorType && props.validation.getErrorMessage()) return props.validation.getErrorMessage();
            }
        }

        return getErrorMessage(nestedInputKeyHandler(props.name), props.error ?? errors[props.name as string])
            ?? props.formHook.getFieldState(props.name).error?.message;

    }, [errors, fieldError, props.error, props.formHook, props.name, props.validation])


    const dateTypes: PlattixInputTypes[] = ["date"]
    const valueAsDate = dateTypes.includes(props.type ?? 'text')
    const numberTypes: PlattixInputTypes[] = ["number", "number-format"]
    const valueAsNumber = numberTypes.includes(props.type ?? 'text')
    
    const required = useMemo(() => {
        if (!props.validation) return false;
        if (Array.isArray(props.validation)) return !!props.validation?.find(v => v.name === 'required');

        return props.validation.name === 'required';
    }, [props.validation]);
    
    return (
        <Collapse in={display} style={{width: '100%'}}>
            <PlattixInput
                {...filterProps(props, ['showIf', 'registerProps'])}
                register={ 
                    props.formHook.register(props.name, {
                        ...props.registerProps,
                        validate: validation,
                        valueAsNumber: valueAsNumber,
                        valueAsDate: valueAsDate
                    })
                }
                formHook={props.formHook as UseFormReturn}
                numberFormatOptions={props.type === 'number-format' ? props.numberFormatOptions : undefined}
                // @ts-ignore
                options={
                    props.type === 'file' ? props.fileUploadOptions :
                        props.type === 'select' ? props.options : 
                            props.type === 'address' ? props.addressOptions : 
                                props.type === 'account' ? props.accountOptions : 
                                    props.type === 'invoice' ? props.invoiceOptions :
                                        props.type === 'phone-number' ? props.phoneOptions :
                                            undefined
                }
                label={props.label}
                error={errorMessage}
                onChange={props.onChange}
                required={required}
            />
        </Collapse>
    );
}

export async function ValidateField<TFieldValues>(form: UseFormReturn<TFieldValues, object>, field: PlattixValidatedInputProps<TFieldValues>) {
    let fieldValid = true

    const fieldValue = form.getValues(field.name)
    if (Array.isArray(field.validation)) {

        for (let i = 0; i < field.validation.length; i++) {
            const valid = await field.validation[i].validate(fieldValue)
            if (!valid) addFormError(form, field.name, field.validation[i].name)
            fieldValid = fieldValid && !!valid
        }
    } else if (field.validation) {
        fieldValid = !!(await field.validation.validate(fieldValue))
        if (!fieldValid) addFormError(form, field.name, field.validation.name)
    }


    return fieldValid

}

export function addFormError<TFieldValues>(form: UseFormReturn<TFieldValues, object>, name: FieldPath<TFieldValues>, error: string | null | undefined) {
    if (error)
        form.setError(name, {message: error})
    else
        form.clearErrors(name)
}

export type PlattixFormSuffixType =
    'euro'
    | 'percent'
    | 'degrees-celsius'
    | 'g'
    | 'kg'
    | 'W'
    | 'kW'
    | 'kWh'
    | 'months'
    | 'days'
    | 'year'
    | 'm2'
    | 'Wp'
    | 'kWp'
    | 'km'
    | 'kWh/100km'
    | 'A'
    | 'hours'
    | 'px'
    | 'eurokwh'
    | 'kVARh';

export interface PlattixFormSuffixProps {
    type: PlattixFormSuffixType;
    className?: string;
}

export function PlattixFormSuffix(props: PlattixFormSuffixProps) {
    const {t} = useTranslation()
    if (props.type === 'year') return <span className={`suffix ${props.className || ''}`}>{t('Years')}</span>
    if (props.type === 'months') return <span className={`suffix ${props.className || ''}`}>{t('Months')}</span>
    if (props.type === 'days') return <span className={`suffix ${props.className || ''}`}>{t('Days')}</span>

    return <span className={`suffix-${props.type} ${props.className || ''}`}/>
}

interface SearchInputProps {
    id?: string,
}

export function SearchInput(props: SearchInputProps) {
    const searchInput = useRef<HTMLInputElement>(null);
    const [searchValue, setSearchValue] = useState<string | undefined>('');

    const searchHandler = (e) => {
        setSearchValue(searchInput.current?.value)
    };

    return (
        <div className="module-content-search">
            <input
                className="searchBar"
                type="text"
                id={props.id}
                placeholder={t('Search')}
                onInput={searchHandler}
                ref={searchInput}
            />
            <button onClick={searchHandler}>
                <FontAwesomeIcon icon={faSearch} name={'searchIcon'}/>
            </button>
        </div>
    );
}
//
// export interface SameHeightAsInputComponentProps {}
//
// export function SameHeightAsInputComponent(props: PropsWithChildren<SameHeightAsInputComponentProps>) {
//     return (
//         <>
//            
//             <div className={`form-group row`}>
//                 <div className="module-tab-content-body-group">
//                     <div className={'input-wrapper'}>
//                         <label className="module-tab-content-title">
//                             <span>{' '}</span>
//                         </label>
//                         {props.children}
//                     </div>
//
//                     {/*{(props.suffix || props.type === 'percent') && <PlattixFormSuffix type={props.suffix ?? 'percent'}/>}*/}
//
//                     {/*{(props.error) && <span className="text-danger">{getInputErrorMessage()}</span>}*/}
//
//                     {/*{props.description && <div className="formInputDescription">{props.description}</div>}*/}
//                 </div>
//             </div>
//         </>
//     );
// }

// export interface PlattixSingleInputProps extends HTMLAttributes<HTMLInputElement> {
// export interface PlattixSingleInputProps extends HTMLInputElement {
export interface PlattixSingleInputProps extends InputHTMLAttributes<HTMLInputElement> {
// export interface PlattixSingleInputProps extends RefAttributes<HTMLInputElement> {
//     suffix?: PlattixFormSuffixType;

}

export const PlattixSingleInput = React.forwardRef<HTMLInputElement, PlattixSingleInputProps>(
    (props: PlattixSingleInputProps, ref) => {
        return (
            <>
                <input
                    {...filterProps(props, ['loading', 'loadingText'])}
                    ref={ref}
                    className={`form-control ${PlattixSingleInputStyling}`}
                    //{(props.suffix || props.type === 'percent') && <PlattixFormSuffix type={props.suffix ?? 'percent'}/>}
                />
            </>
        );
    }
)

export const PlattixSingleInputStyling = css`
    font-weight: var(--fontWeight2);
    font-size: var(--inputFontSize);
    width: 100%;
    float: left !important;
    border: none;
    color: var(--styleColor4);
    padding: var(--padding4);
    margin: 0;
    background: var(--backgroundColor2);
    border-radius: var(--roundCorner2);
`;

/**
 * Deze functie transformeert nested keys van de react-hook-form zoals het van de backend wordt teruggegeven.
 * 
 * input:   bankAccountNumbers.1.BankAccountNumber
 * output:  bankAccountNumbers[1].BankAccountNumber
 **/
export const nestedInputKeyHandler = (key: string) => {
    const keySplit = key.split('.');
    if (keySplit.length <= 1) return key;
    
    let newKey = ``;
    const capitalizeFirstLetter = (string: string) => {
        if (!string?.length) return string;
        const firstLetter = string[0].toUpperCase();
        const restOfWord = string.slice(1);
        
        return `${firstLetter}${restOfWord}`;
    } 
    
    for (let ks of keySplit) {
        if (!newKey.length) {
            newKey += `${ks}`;
            continue;
        }
        
        if (ks.match(/(^\d+$)/)) {
            newKey += `[${ks}]`;
            continue;
        }
        
        newKey += `.${capitalizeFirstLetter(ks)}`;
    }
    
    return newKey;
}