import axios, {AxiosRequestConfig} from "axios";

export interface DownloadFileProps {
    /*
    * Url of the download link.
    * */
    url: string;

    /*
    * Url of the download link.
    * */
    fileName?: string;

    /*
    * Function which is executed just before calling the API.
    * This function can be used as a pre hook to do any tasks which needs to be done before the API call is made.
    * @example: Disable button / change button state to loading.
    * */
    onDownloadStart?: () => void;

    /*
    * Function which is executed after making the API call.
    * This function can be used as a post hook to do any tasks which needs to be done after the API call is made.
    * @example: Enable button / change button state to primary.
    * */
    onDownloadComplete?: () => void;

    /*
    * Function to be called when API has resulted in failure.
    * Error handling can be done over here.
    * @example: Show an alert to the user saying something has went wrong and the file is not downloaded.
    * */
    onDownloadError?: (error) => void;

    /*
    * Configuration options for Axios request.
    * */
    requestConfig?: AxiosRequestConfig<any> | undefined;
}

export async function DownloadFile(props: DownloadFileProps) : Promise<string | undefined> {
    try {
        props.onDownloadStart?.();

        const response = await axios.get(
            props.url,
            {
                responseType: "blob",
                withCredentials: true,
                ...props.requestConfig
            },
        );

        let headerLine = response.headers['content-disposition'];

        let headerLineMap = headerLine
            ?.split(';')
            ?.map(str => str.trim());

        let fileNameString = headerLineMap
            ?.find(str => str.includes('filename='))
            ?.split('=')[1]
            ?.replaceAll('"','');

        // console.log(`fileNameString`, fileNameString);
        // console.log(`props.fileName`, props.fileName);
        const fileName = props.fileName ? props.fileName : fileNameString ?? 'file.pdf';

        const url = URL.createObjectURL(response.data);

        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);

        props.onDownloadComplete?.();
        
        return fileName;
    } catch (error) {
        props.onDownloadError?.(error);         
    }
}