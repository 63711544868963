import React from "react";
import {
    LandingPageBackgroundContainer,
    LandingPageBackgroundOverlay,
    LandingPageContainer,
    LandingPageLogo,
    LandingPageLogoContainer
} from "./LandingpageStyling";
import {PlattixImage} from "PlattixUI/util/thumbor/PlattixImage";
import {PlattixConfiguration} from "configuration/Configuration";
import {ContentLoginContainerProps} from "PlattixUI/core/ContentLoginContainer";

export interface LandingpageProps extends Pick<ContentLoginContainerProps, 'identityServer'>{
    
}

export function Landingpage(props: LandingpageProps) {
    const isApp: boolean = !!props.identityServer?.app;

    return (
        <LandingPageContainer>
            <LandingPageBackgroundContainer>
                {isApp ?
                    <PlattixImage
                        width={window.innerWidth ?? 500}
                        height={window.innerHeight ?? 500}
                        alt={'Background'}
                        fileSource={props.identityServer?.app?.backgroundImage}
                    />
                    :
                    <img src={PlattixConfiguration.loginScreen.background} alt="Background"/>
                }
                <LandingPageBackgroundOverlay />
            </LandingPageBackgroundContainer>
            
            <LandingPageLogoContainer>
                <LandingPageLogo>
                    <PlattixImage
                        width={0}
                        height={100}
                        fileSource={props.identityServer?.app?.logo ?? PlattixConfiguration.mainLogo}
                        alt={`Logo ${PlattixConfiguration.platformName}`}
                    />
                </LandingPageLogo>
            </LandingPageLogoContainer>
        </LandingPageContainer>
    );
}