import {availableUrlParams, BaseAuthProps, LoginResponseModel} from "pages/Auth/Auth";
import {RegisterPage} from "PlattixUI/core/pages/Account/Register";
import {useMemo} from "react";
import {doPost, isHttpError} from "PlattixUI/PlattixReactCore/api/Api";
import {UserLoginModel, UserRegisterModel} from "PlattixUI/PlattixReactCore/UserSlice";
import {BaseApiEndpoints} from "PlattixUI/PlattixReactCore/api/BaseApiEndpointMap";
import {HttpError} from "PlattixUI/PlattixReactCore/CoreTypes";
import {toast} from "react-toastify";
import {t} from "PlattixUI/PlattixReactCore/i18n";

export interface RegisterProps {}

export function Register(props: RegisterProps) {
    const urlParams = useMemo(() => new URLSearchParams(window.location.search), [window.location.search]);
    const returnUrl = urlParams.get(availableUrlParams.returnUrl);

    const onSubmitHandler = async (model) => {
        const m = {
            ...model,
            returnUrl: returnUrl,
        };
        
        const response = await doPost<UserRegisterModel>(BaseApiEndpoints.Account.Register, m) as (UserRegisterModel | HttpError);
        if (isHttpError(response)) {
            toast.error(t('Error'));
            return;
        }
        
        toast.success(t('Success'));
        
        !!response.returnUrl && window.location.replace(response.returnUrl);
    };
    
    return (
        <>
            <RegisterPage onSubmit={onSubmitHandler} returnUrl={returnUrl} />
        </>
    );
}