import produce from "immer";

export const randomKey = () => {
    return (Math.random() + 1).toString(36).substring(2);
};

/**
 * Moves the position of an item inside an array
 * */
export function moveInArray(array: any[], from: number, to: number) {
    const newArray = [...array];
    const item = newArray.splice(from, 1)[0];
    newArray.splice(to, 0, item);
    return newArray;
}

function selectMany<TIn, TOut>(input: TIn[], selectListFn: (t: TIn) => TOut[]): TOut[] {
    return input.reduce((out, inx) => {
        out.push(...selectListFn(inx));
        return out;
    }, new Array<TOut>());
}

// region Loading
// ----------------------------------------
// ----------------------------------------

export interface LoadingState {
    id: string;
    loading: boolean;
    // [key: string]: boolean | string | number | undefined | null | unknown;
}

export const updateLoadingState = (newState: LoadingState) => {
    return (draft) => {
        if (!newState) return;
        const index = draft.findIndex(d => d.id === newState.id);
        if (index === -1) draft.push(newState)
        else draft[index] = newState;
    };
}

export const checkLoadingState = (state: LoadingState[], id: string) => {
    return state.find(s => s.id === id)?.loading;
}

// ----------------------------------------
// ----------------------------------------
// endregion

