import {styled} from "goober";
import React from "react";

const vars = {
    colors: {
        green: '#007566',
        yellow: '#fdd21c',
        grey: '#dddddd',
        red: '#ca4d35',
    },
};

export const HorizontalTabsContainer = styled('div')`
    width: 100%;
    height: 100%;
    display: flex;
    gap: 10px;
`;

export interface HoriTabContainerProps {
    expanded: boolean,
}

export const HoriTabContainer = styled('div')((props: HoriTabContainerProps) => {
    return `
        width: ${props.expanded ? '100%' : 'clamp(30px, 30%, 100px)'};
        display: flex;
        gap: 10px;
        transition: 0.2s ease-in-out;
    `
});

export const LandingPageContainer = styled('div')`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    padding: 5vw 5vw;
    gap: 50px;
`;

export const LandingPageBackgroundContainer = styled('div')`
    position: fixed;
    width: 100vw;
    height: 100vh;
    user-select: none;
    top: 0;
    left: 0;
    
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: blur(5px);
    }
`;

export const LandingPageBackgroundOverlay = styled('div', React.forwardRef)(() => {
    return `
        background: #f5f5f5AA;
        width: 100%;
        height: 100%;
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
    `;
});

export const HoriTabHeader = styled('div')`
    width: 100%;
    display: flex;
    cursor: pointer;
    padding: 50px 10px;
    background: ${vars.colors.grey};
    
    * {
        margin: 0;
    }
    
    h5 {
        writing-mode: vertical-rl;
        width: 100%;
        display: flex;
        align-items: center;
    }
`;

export const HoriTabBody = styled('div')`
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    gap: 20px;
`;

export const HoriTabSection = styled('div')`
    width: 100%;
    border: 1px dotted red;
    display: flex;
    flex-flow: column nowrap;
    padding: 15px;
    
    h2 {
        width: 100%;
    }
    
    p {
        width: 80%;
    }
`;

export const LandingPageLogoContainer = styled('div')`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
`;

export const LandingPageLogo = styled('div')`
    z-index: 1;
    
    img {
        max-width: 100%;
        max-height: 100%;
    }
`;

export const BackgroundDetails = styled('div')`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    z-index: -1;
`;

export const BDetail = styled('div')`
    display: flex;
    
    &.top {
        justify-content: flex-end;
        align-items: flex-start;
        height: 5vw;
        max-width: 100%;
    }
    
    &.bottom {
        justify-content: flex-start;
        align-items: flex-end;
        height: 5vw;
        max-width: 100%;
        padding-left: 5vw;
    }
    
    &.right {
        justify-content: flex-end;
        align-items: flex-start;
        width: 5vw;
        max-height: 100%;
    }
    
    img {
        max-width: 100%;
        max-height: 100%;
    }
`;

export const BDetailHorizontal = styled('div')`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    
    img {
        max-width: 100%;
        max-height: 100%;
    }
`;

export const BDetailVertical = styled('div')`
    width: fit-content;
    height: 100%;
    display: flex;
    
    img {
        max-width: 100%;
        max-height: 100%;
    }
`;