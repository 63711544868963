import {FilePond, registerPlugin} from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

import 'filepond/dist/filepond.min.css';
import {FilePondFile, FilePondInitialFile} from "filepond";
import {t} from "../../PlattixReactCore/i18n";
import './FileUploader.scss';
import {FileInputProps} from "PlattixUI/core/components/form/Input";
import {useEffect, useState} from "react";


registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

/**
 * Properties for {@link FileUploader}
 */
export interface FileUploaderProps {
    /**
     * Optional - List of files to add to file uploader programatically
     */
    files?: (string | FilePondInitialFile | Blob | File)[],
    /**
     * Function that is called when files are added or removed from FileUploader
     * @param files - {@link FilePondFile}[]
     */
    setFiles: (files: FilePondFile[]) => void,
    // dropOnPage?: boolean,
    /**
     * Is a file required
     */
    required?: boolean,

    /**
     * Optional - Array of accepted file types.
     * 
     * @description - Can be mime types or wild cards. For instance ['image/*'] will accept all images. 
     * 
     * @example ['image/png', 'image/jpeg'] will only accepts PNGs and JPEGs.
     * @see {@link https://pqina.nl/filepond/docs/api/plugins/file-validate-type/}
     */
    acceptedFileTypes?: string[],
    /**
     * Optional - Maximum number of files that may be uploaded
     * 
     * @description - For unlimited files, set undefined
     */
    maxFiles?: number,

    /**
     * Maximum allowed file size
     * 
     * file size must be provided as a string
     * @example '5MB' or '750KB'
     * 
     * @see {@link https://pqina.nl/filepond/docs/api/plugins/file-validate-size/}
     */
    maxFileSize?: string,

    /**
     * Disable file uploader
     */
    disabled?: boolean,

    /**
     * Catch all files drop on the page, not only on the file uploader
     * 
     * TODO: Does not work as expected
     */
    dropOnPage?: boolean,

    previewFile?: FileUploaderProps['files'],
}

/**
 * Component that will add a file drop component
 * @param props - {@link FileUploaderProps }
 * @see {@link https://pqina.nl/filepond/docs/}
 * @returns JSX element
 */
export function FileUploader(props: FileUploaderProps) {

    const labels= {

        /**
         * The decimal separator used to render numbers.
         * By default this is determined automatically.
         * @default 'auto'
         */
        labelDecimalSeparator: 'auto',
        /**
         * The thousands separator used to render numbers.
         * By default this is determined automatically.
         * @default 'auto'
         */
        labelThousandsSeparator: 'auto',
        /**
         * Default label shown to indicate this is a drop area.
         * FilePond will automatically bind browse file events to
         * the element with CSS class .filepond--label-action.
         * @default 'Drag & Drop your files or <span class="filepond--label-action"> Browse </span>'
         */
        labelIdle: `<div class="FileUploadTextContainer">${t('FileUpload.DragDrop')} <span class="filepond--label-action content-btn content-btn-1">${t('FileUpload.DragDrop.Browse')}</span></div>`,
        /**
         * Label shown when the field contains invalid files and is validated by the parent form.
         * @default 'Field contains invalid files'
         */
        labelInvalidField: 'Field contains invalid files',
        /**
         * Label used while waiting for file size information.
         * @default 'Waiting for size'
         */
        labelFileWaitingForSize: 'Waiting for size',
        /**
         * Label used when no file size information was received.
         * @default 'Size not available'
         */
        labelFileSizeNotAvailable: 'Size not available',
        /**
         * Label used when showing the number of files and there is only one.
         * @default 'file in list'
         */
        labelFileCountSingular: 'file in list',
        /**
         * Label used when showing the number of files and there is more than one.
         * @default 'files in list'
         */
        labelFileCountPlural: 'files in list',
        /**
         * Label used while loading a file.
         * @default 'Loading'
         */
        labelFileLoading: 'Loading',
        /**
         * Label used when file is added (assistive only).
         * @default 'Added'
         */
        labelFileAdded: 'Added',
        /**
         * Label used when file load failed.
         * @default 'Error during load'
         */
        labelFileLoadError: ((error: any) => 'Error during load'),
        /**
         * Label used when file is removed (assistive only).
         * @default 'Removed'
         */
        labelFileRemoved: 'Removed',
        /**
         * Label used when something went during during removing the file upload.
         * @default 'Error during remove'
         */
        labelFileRemoveError: ((error: any) => 'Error during remove'),
        /**
         * Label used when uploading a file.
         * @default 'Uploading'
         */
        labelFileProcessing: 'Uploading',
        /**
         * Label used when file upload has completed.
         * @default 'Upload complete'
         */
        labelFileProcessingComplete: 'Upload complete',
        /**
         * Label used when upload was cancelled.
         * @default 'Upload cancelled'
         */
        labelFileProcessingAborted: 'Upload cancelled',
        /**
         * Label used when something went wrong during file upload.
         * @default 'Error during upload'
         */
        labelFileProcessingError: ((error: any) => 'Error during upload'),
        /**
         * Label used when something went wrong during reverting the file upload.
         * @default 'Error during revert'
         */
        labelFileProcessingRevertError: ((error: any) => 'Error during revert'),
        /**
         * Label used to indicate to the user that an action can be cancelled.
         * @default 'tap to cancel'
         */
        labelTapToCancel: 'tap to cancel',
        /**
         * Label used to indicate to the user that an action can be retried.
         * @default 'tap to retry'
         */
        labelTapToRetry: 'tap to retry',
        /**
         * Label used to indicate to the user that an action can be undone.
         * @default 'tap to undo'
         */
        labelTapToUndo: 'tap to undo',
        /**
         * Label used for remove button.
         * @default 'Remove'
         */
        labelButtonRemoveItem: 'Remove',
        /**
         * Label used for abort load button.
         * @default 'Abort'
         */
        labelButtonAbortItemLoad: 'Abort',
        /**
         * Label used for retry load.
         * @default 'Retry'
         */
        labelButtonRetryItemLoad: 'Retry',
        /**
         * Label used for abort upload button.
         * @default 'Cancel'
         */
        labelButtonAbortItemProcessing: 'Cancel',
        /**
         * Label used for undo upload button.
         * @default 'Undo'
         */
        labelButtonUndoItemProcessing: 'Undo',
        /**
         * Label used for retry upload button.
         * @default 'Retry'
         */
        labelButtonRetryItemProcessing: 'Retry',
        /**
         * Label used for upload button.
         * @default 'Upload'
         */
        labelButtonProcessItem: 'Upload',
        /**
         * Message shown to indicate the allowed file types.
         * Available placeholders are {allTypes}, {allButLastType}, {lastType}.
         */
        fileValidateTypeLabelExpectedTypes: t('FileUpload.ExpectsFilesOfType'),
        /**
         * 	Allows mapping the file type to a more visually appealing label, { 'image/jpeg': '.jpg' }
         * 	will show .jpg in the expected types label. Set to null to hide a type from the label.
         */
        fileValidateTypeLabelExpectedTypesMap:  {
            'image/jpeg': '.jpg',
            'image/png': '.png',
            'application/pdf': '.pdf',
            'text/csv': '.csv',
            'application/vnd.ms-excel': '.xls',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx'
        },

        labelFileTypeNotAllowed: t('Fileupload.FileTypeNotAllowed')
    }

    function detectFileType(file: File, type: string): Promise<string>{
        return new Promise<string>((accept, reject) => {
            
            const split = file.name.split('.')
            const extension = split[split.length -1]
            
            if (extension === 'csv'){
                // Firefox detects .csv as 'application/vnd.ms-excel'
                accept('text/csv')
            }
            
            if (extension === 'cod'){
                // For some reason wordt ".cod" niet als mime-type "application/vnd.rim.cod" herkend, maar als "text/plain".
                // accept('text/plain')
                accept('application/vnd.rim.cod')
            }

            accept(type)
        })
    }
    
    return <FilePond
        files={props.files}
        onupdatefiles={props.setFiles}

        allowMultiple={props.maxFiles !== 1}
        allowReplace={props.maxFiles === 1}
        maxFiles={props.maxFiles}
        name="files"
        instantUpload={false}
        // dropValidation={true}

        allowFileSizeValidation={true}
        maxFileSize={props.maxFileSize ?? "50MB"}

        allowImagePreview={true}

        allowFileTypeValidation={!!props.acceptedFileTypes?.length}
        acceptedFileTypes={props.acceptedFileTypes}

        required={props.required}

        checkValidity={true}
        // dropOnPage={props.dropOnPage}
        fileValidateTypeDetectType={detectFileType}
        
        disabled={props.disabled}
        
        {...labels}
    />
}


export function PlattixFileInput(props: FileInputProps) {
    const [files, setFiles] = useState<FilePondFile[]>([]);

    useEffect(() => {
        props.formHook.setValue(props.name, files.map(f => f.file as File), {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true
        })
    }, [files, props.formHook, props.name]);
    
    return <>
        <FileUploader {...props.options} setFiles={setFiles} files={files.map(f => f.file)}  />
    </>
}