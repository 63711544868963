import {FieldArrayPath, FieldPath, UnpackNestedValue, useForm, UseFormReturn} from "react-hook-form";
import Modal, {ModalBaseProps, ModalProps} from "../Modal";
import React, {FormHTMLAttributes, PropsWithChildren, useEffect, useState} from "react";
import {HttpError} from "PlattixUI/PlattixReactCore/CoreTypes";
import {doPost, isHttpError} from "PlattixUI/PlattixReactCore/api/Api";
import {FormErrorMessage} from "../ActionBar";
import {filterProps} from "../../../util/ElementProperties";
import {CancelButton} from "../Buttons";
import {t, useTranslation} from "PlattixUI/PlattixReactCore/i18n";
import {toast} from "react-toastify";
import {
    NumberFormatOptions,
    PhoneNumberInputOptions,
    PlattixFormSuffixType,
    PlattixInputTypes,
    PlattixSubmitButton,
    PlattixValidatedArrayInput,
    PlattixValidatedInput,
    PlattixValidatedInputProps,
    ShowIfType
} from "./Input";
import {SelectOption} from "PlattixUI/core/components/form/Select";
import {FileUploaderProps} from "PlattixUI/core/components/FileUploader";
import {AddressComponentProps} from "PlattixUI/core/components/AddressComponent";
import {ChooseAccountOptions} from "PlattixUI/core/components/form/InputFields/AccountPlattixInput";
import {Validator} from "PlattixUI/core/forms/Validators/Validator";
import {FieldErrors} from "react-hook-form/dist/types/errors";
import {ContentCardButtons} from "PlattixUI/core/components/ContentCard";
import {ChooseInvoiceOptions} from "./InputFields/InvoicePlattixInput";
import {styled} from "goober";
import {checkForUnsavedChanges} from "PlattixUI/core/components/form/formUtil";

interface PlattixFormProps extends FormHTMLAttributes<HTMLFormElement> {
    onSubmit?: React.FormEventHandler<HTMLFormElement>,
    autocomplete?: string,
    error?: HttpError | null;
}

export function PlattixForm(props: React.PropsWithChildren<PlattixFormProps>) {
    
    const checkKeyDown = (e) => {
        if (e.key === 'Enter') e.preventDefault();
    };
    
    const onSubmitHandler = async (event) => {
        await props.onSubmit?.(event);
        /* Voorkomen dat de pagina herladen wordt. */
        event.preventDefault();
    };
    
    return (
        <form id={props.id ?? props.name} {...props} onSubmit={onSubmitHandler} className={`PlattixForm`} /*onKeyDown={checkKeyDown}*/>
            {/*<div style={{width: '100%'}}>*/}
                {props.children}
            {/*</div>*/}
        </form>
    );
}

export interface PlattixFormModalProps extends PlattixFormProps {
    submitButtonText?: string,
    onClose: () => void,
    show: boolean,
    loading: boolean
}

export function PlattixFormModal(props: React.PropsWithChildren<PlattixFormModalProps>) {
    const {t} = useTranslation()

    const submitBtnText = props.submitButtonText ?? t('Save')
    return <Modal
        onClose={props.onClose} show={props.show}
        showConfirmButton={false}
        customButton={<PlattixSubmitButton form={"ModalForm"} name={submitBtnText} loading={props.loading}/>}
        closeOnOutsideClick={false}
        loading={props.loading}
        title={props.title}
    >
        <PlattixForm id={"ModalForm"} {...filterProps(props, ['submitButtonText', 'onCLose', 'show', 'loading'])} >
            {props.children}
        </PlattixForm>
    </Modal>
}

export type PlattixSubmitFormModalProps<TModel, TResponse> = ModalBaseProps & PlattixSubmitFormProps<TModel, TResponse> & {
    title: string,
    description?: string,
    /**
     * Show a toast message on success.
     */
    toastOnSuccess?: string
}

export function PlattixSubmitFormModal<TModel, TResponse>(props: PlattixSubmitFormModalProps<TModel, TResponse>) {
    function onSuccess(model) {
        console.log(`onSuccess model`, model);
        if (props.onSuccess) props.onSuccess(model)
        if (props.toastOnSuccess) toast.success(props.toastOnSuccess)
        props.onClose()
    }

    return <Modal
        title={props.title}
        show={props.show}
        onClose={props.onClose}
        showCancelButton={false}
        showConfirmButton={false}
        closeOnOutsideClick={props.closeOnOutsideClick}
    >
        {!!props.description &&
            <ModalDescriptionContainer>
                <p>{props.description}</p>
                <hr/>
            </ModalDescriptionContainer>
        }
        <PlattixSubmitForm
            onSuccess={onSuccess}
            onSubmit={props.onSubmit}
            postUrl={props.postUrl}
            onCancel={props.onClose}
            fields={props.fields}
            showButtonsWhenNotDirty={true}
            defaultValue={props.defaultValue}
        />
    </Modal>
}

const ModalDescriptionContainer = styled('div', React.forwardRef)(() => {
    return `
        display: flex;
        flex-flow: column nowrap;
        gap: 10px;
        margin-bottom: 10px;
    `;
});

export type ArrayPlattixSubmitField<TModel> = {
    type: "array",
    arrayName: FieldArrayPath<TModel>
    arrayFields: PlattixSubmitField<TModel>[],
    showDeleteButton?: boolean,
    deleteButtonName?: string,
    showAddButton?: boolean,
    addButtonName?: string,
    defaultValue?: any,
    getLabel?: (index: number) => string 
}

export type NumberFormatPlattixSubmitField = {
    type: "number-format",
    numberFormatOptions: NumberFormatOptions
}
export type NumberPlattixSubmitField = {
    type: "number",
    step?: number
}
export type SelectPlattixSubmitField = {
    type: "select";
    options: SelectOption[];
    /**
     * Add an unselectable option that forces the user to pick an option
     */
    chooseOption?: boolean;
    /**
     * Should the 'choose Option' option be unselectable
     */
    chooseOptionDisabled?: boolean;

    isLoading?: boolean;
    isDisabled?: boolean;
    isMulti?: boolean;
    acceptNewValues?: boolean;
}

export type CodeSelectPlattixSubmitField = {
    type: "code-select",
    namespace?: string,
    tableName: string,
    /**
     * Add an unselectable option that forces the user to pick an option
     */
    chooseOption?: boolean,
    /**
     * Should the 'choose Option' option be unselectable
     */
    chooseOptionDisabled?: boolean,
    
    isLoading?: boolean,
    isDisabled?: boolean,
    isMulti?: boolean
}
export type FilePlattixSubmitField = {
    type: "file",
    fileUploadOptions: Omit<FileUploaderProps, "setFiles">
}
export type AddressPlattixSubmitField = {
    type: "address",
    addressOptions?: Omit<AddressComponentProps, "form" | "addressModel">
}
export type AccountPlattixSubmitField = {
    type: "account",
    accountOptions?: ChooseAccountOptions
}
export type InvoicePlattixSubmitField = {
    type: "invoice",
    invoiceOptions?: ChooseInvoiceOptions
}
export type SpacerPlattixSubmitField = {
    type: "spacer",
    id: string,
    title: string,
    description: string | JSX.Element,
}
export type PhoneNumberPlattixSubmitField = {
    type: "phone-number",
    phoneOptions?: PhoneNumberInputOptions
}   
export type TranslationPlattixSubmitField = {
    type: "translation",
    required?: boolean,
    collapsable?: boolean
}
export type DateTimeLocalPlattixSubmitField = {
    type: "datetime-local"
    /**
     * Update only timezone to local timezone, but do not update time value
     * @link https://momentjs.com/docs/#/manipulating/local/
     */
    keepLocalTime?: boolean
}
export type BasePlattixSubmitField = {
    type?: Exclude<PlattixInputTypes, 'number-format' | 'number' | 'select' | 'file' | 'address' | 'code-select' | 'translation' | 'account' | 'array' | 'invoice' | 'datetime-local' | 'phone-number'>
}

export type PlattixSubmitField2<TModel> = Omit<PlattixValidatedInputProps<TModel>, 'formHook'> & {
// export type PlattixSubmitField<TModel> = PlattixValidatedInputProps<TModel> & {
    label?: string,
    description?: string | JSX.Element,
    suffix?: PlattixFormSuffixType,
    onChange?: (e: any) => void,
    onBlur?: (e: any) => void,
    actionButtons?: JSX.Element,
};
 
export type PlattixSubmitField<TModel> = {
    label?: string,
    name: FieldPath<TModel>,
    validation?: Validator<TModel> | Validator<TModel>[],
    readOnly?: boolean,
    showIf?: ShowIfType<TModel>,
    placeholder?: string,

    description?: string | JSX.Element,
    suffix?: PlattixFormSuffixType,
    onChange?: (e: any) => void,
    onBlur?: (e: any) => void,

    onClick?: (any) => any,
    actionButtons?: JSX.Element,
    
    fieldName?: string,
} & (
    | NumberFormatPlattixSubmitField
    | NumberPlattixSubmitField
    | SelectPlattixSubmitField
    | CodeSelectPlattixSubmitField
    | FilePlattixSubmitField
    | AddressPlattixSubmitField
    | TranslationPlattixSubmitField
    | BasePlattixSubmitField
    | AccountPlattixSubmitField
    | ArrayPlattixSubmitField<TModel>
    | InvoicePlattixSubmitField
    | DateTimeLocalPlattixSubmitField
    | PhoneNumberPlattixSubmitField
);

// | SpacerPlattixSubmitField

export function PlattixSubmitFieldComponent<TModel>(props: {
    field: PlattixSubmitField<TModel>,
    form: UseFormReturn<TModel, object>,
    error: HttpError | null | undefined,
    // label: string | null | undefined,
    readOnly?: boolean
}) {
    // if (props.field.type === 'spacer') return <FormSpacer {...props.field} />;

    const fieldProps: PlattixValidatedInputProps<TModel> = {
        name: props.field.name,
        label: props.field.label,
        formHook: props.form,
        validation: props.field.validation,
        type: "text",
        error: props.error,
        description: props.field.description,
        suffix: props.field.suffix,
        readOnly: props.readOnly || props.field.readOnly,
        showIf: props.field.showIf,
        onChange: props.field.onChange,
        onClick: props.field.onClick,
        actionButtons: props.field.actionButtons,
        placeholder: props.field.placeholder,
    };
    
    switch (props.field.type) {
        case 'number-format':
            return <PlattixValidatedInput<TModel>
                key={props.field.name}
                {...fieldProps}
                type={'number-format'}
                numberFormatOptions={props.field.numberFormatOptions}
            />
        case 'array':
            return <PlattixValidatedArrayInput<TModel>
                key={props.field.name}
                {...fieldProps}
                arrayName={props.field.arrayName}
                type={'array'}
                arrayFields={props.field.arrayFields}
                showAddButton={props.field.showAddButton}
                addButtonName={props.field.addButtonName}
                showDeleteButton={props.field.showDeleteButton}
                deleteButtonName={props.field.deleteButtonName}
                defaultValue={props.field.defaultValue}
                getLabel={props.field.getLabel}
                showIf={props.field.showIf}
            />
        case 'number':
            return <PlattixValidatedInput<TModel>
                key={props.field.name}
                {...fieldProps}
                type={'number'}
                step={props.field.step}
            />
        case 'select':
            return <PlattixValidatedInput<TModel>
                key={props.field.name}
                {...fieldProps}
                type={'select'}
                options={props.field.options}
                chooseOption={props.field.chooseOption}
                chooseOptionDisabled={props.field.chooseOptionDisabled}
                isMulti={props.field.isMulti}
                isDisabled={props.field.isDisabled}
                isLoading={props.field.isLoading}
                acceptNewValues={props.field.acceptNewValues}
            />
        case 'code-select':
            return <PlattixValidatedInput<TModel>
                key={props.field.name}
                {...fieldProps}
                type={'code-select'}
                tableName={props.field.tableName}
                namespace={props.field.namespace}
                chooseOption={props.field.chooseOption}
                chooseOptionDisabled={props.field.chooseOptionDisabled}
                isMulti={props.field.isMulti}
                isDisabled={props.field.isDisabled}
                isLoading={props.field.isLoading}
            />
        case 'file':
            return <PlattixValidatedInput<TModel>
                key={props.field.name}
                {...fieldProps}
                type={'file'}
                fileUploadOptions={props.field.fileUploadOptions}
            />
        case 'address':
            return <PlattixValidatedInput<TModel>
                key={props.field.name}
                {...fieldProps}
                type={'address'}
                addressOptions={{...props.field.addressOptions, isReadonly: props.readOnly}}
            />
        case 'account':
            return <PlattixValidatedInput<TModel>
                key={props.field.name}
                {...fieldProps}
                type={'account'}
                accountOptions={props.field.accountOptions}
            />
        case 'invoice':
            return <PlattixValidatedInput<TModel>
                key={props.field.name}
                {...fieldProps}
                type={'invoice'}
                invoiceOptions={props.field.invoiceOptions}
            />
        case 'translation':
            return <PlattixValidatedInput<TModel>
                key={props.field.name}
                collapsable={props.field.collapsable}
                {...fieldProps}
                type={'translation'}
            />
        case 'datetime-local':
            return <PlattixValidatedInput<TModel>
                key={props.field.name}
                {...fieldProps}
                type={'datetime-local'}
                keepLocalTime={props.field.keepLocalTime}            
            />
        case 'phone-number':
            return <PlattixValidatedInput<TModel>
                key={props.field.name}
                {...fieldProps}
                type={'phone-number'}
                phoneOptions={props.field.phoneOptions}
            />
        default:
            return <PlattixValidatedInput<TModel>
                key={props.field.name}
                {...fieldProps}
                type={props.field.type}
            />
    }
}

export type PlattixAutoFormProps<TModel> = {
    formName?: string,
    /**
     * List of fields to include in the form
     */
    fields: PlattixSubmitField<TModel>[],
    /**
     * Object containing the default form values.
     * 
     * Cannot be set when form is not null
     */
    defaultValue?: Partial<TModel> | TModel,

    refreshOnDefaultChange?: boolean,

    readOnly?: boolean,
    loading?: boolean,

    showSubmitButton?: boolean,
    onSubmit?: (model: TModel) => void,
    SubmitButtonText?: string,

    showCancelButton?: boolean,
    onCancel?: () => void,
    CancelButtonText?: string,
    
    onInvalid?: (errors: FieldErrors<TModel>, event?: React.BaseSyntheticEvent) => any | Promise<any>,

    /**
     * If set to false (default), the cancel and submit buttons will not be shown unless the form is dirty
     */
    showButtonsWhenNotDirty?: boolean,

    /**
     * Should the form be reset if the Cancel button is clicked
     */
    resetOnCancel?: boolean,

    error?: HttpError | null,

    showIf?: FieldPath<TModel>[],
    
    form?: UseFormReturn<TModel, object>,
    
    label?: string,

} & (
    PlattixAutoFormPropsAutoFormType<TModel>
    | PlattixAutoFormPropsManualFormType<TModel>
)

export type PlattixAutoFormPropsAutoFormType<TModel> = {
    /**
     * Object containing the default form values.
     */
    defaultValue?: TModel | Partial<TModel>,
    form?: undefined
}

export type  PlattixAutoFormPropsManualFormType<TModel> = {
    form: UseFormReturn<TModel, object>,
    defaultValue?: undefined,
}

/**
 * Generate a form for the specified fields
 * @param props
 */
export function PlattixAutoForm<TModel>(props: PlattixAutoFormProps<TModel>) {
    const {t} = useTranslation();
    const autoForm = useForm<TModel>({
        defaultValues: props.defaultValue as any, 
        mode: 'onBlur', 
        reValidateMode: "onChange", 
        // resetOptions: {
        //     keepDirtyValues: true, // user-interacted input will be retained
        // },
    })
    const form = props.form ?? autoForm;
    async function onSubmit(model: UnpackNestedValue<TModel>) {
        await props.onSubmit?.(model as TModel)
    }
    
    useEffect(() => {
        if (props.refreshOnDefaultChange) {
            form.reset(props.defaultValue as any)
        }
    }, [props.defaultValue]);

    useEffect(() => {
        checkForUnsavedChanges(form.formState.isDirty);
    }, [form.formState.isDirty]);

    async function onCancel() {
        if (props.resetOnCancel ?? true) {
            form.reset(props.defaultValue as any)
        }
        props.onCancel?.()
    }
    return <PlattixForm
        name={props.formName}
        onSubmit={form.handleSubmit(onSubmit, props.onInvalid)}
    >
        
        {/*{props.error?.title &&*/}
        {/*    <ErrorMessage>{props.error?.title}</ErrorMessage>*/}
        {/*}*/}
        
        {props.error?.title &&
            <FormErrorMessage 
                title={props.error.title}
                detail={props.error?.detail}
            />
        }

        {props.fields.map(field => {
            return <PlattixSubmitFieldComponent<TModel>
                // key={field.type === 'spacer' ? field.id : field.name}
                key={field.name}
                field={field}
                form={form}
                error={props.error}
                readOnly={props.readOnly}
                // label={props.label}
            />
        })}

        {!props.readOnly && (props.showButtonsWhenNotDirty || form.formState.isDirty) && (props.showCancelButton !== false || props.showSubmitButton !== false) &&
            // <div className={'module-content-tab-btns'}>
                <ContentCardButtons
                    padding={['top']}
                >
                    {(props.showCancelButton ?? true) &&
                        <CancelButton disabled={props.loading}
                                      onClick={onCancel}>
                            {props.CancelButtonText ?? t('Cancel')}
                        </CancelButton>
                    }
                    {
                        (props.showSubmitButton ?? true) &&
                        <PlattixSubmitButton loading={props.loading} name={props.SubmitButtonText ?? t("Save")}/>
                    }
                </ContentCardButtons>
                
            // </div>
        }
    </PlattixForm>
}

export type PlattixAutoFormModalProps<TModel> =  ModalProps & PlattixAutoFormProps<TModel> & {
    title: string;
    description?: string;
    /**
     * Show a toast message on success.
     */
    toastOnSuccess?: string;
}

export function PlattixAutoFormModal<TModel>(props: PropsWithChildren<PlattixAutoFormModalProps<TModel>>) {

    return <Modal
        closeOnOutsideClick={props.closeOnOutsideClick}
        // onConfirm={props.onConfirm}
        showConfirmButton={false}
        showCancelButton={false}
        {...props}
    >
        {!!props.description &&
            <ModalDescriptionContainer>
                <p>{props.description}</p>
                <hr/>
            </ModalDescriptionContainer>
        }
        
        {props.children}
        {!!props.form &&
            <PlattixAutoForm
                showSubmitButton={props.showSubmitButton}
                onSubmit={props.onSubmit}
                SubmitButtonText={props.SubmitButtonText}
                showCancelButton={props.showCancelButton}
                onCancel={props.onClose}
                fields={props.fields}
                showButtonsWhenNotDirty={props.showButtonsWhenNotDirty ?? true}
                loading={props.loading}
                error={props.error}
                form={props.form}
            />
        }
        {!props.form && <PlattixAutoForm
            showSubmitButton={props.showSubmitButton}
            onSubmit={props.onSubmit}
            SubmitButtonText={props.SubmitButtonText}
            showCancelButton={props.showCancelButton}
            onCancel={props.onClose}
            fields={props.fields}
            showButtonsWhenNotDirty={props.showButtonsWhenNotDirty ?? true}
            defaultValue={props.defaultValue}
            refreshOnDefaultChange={props.refreshOnDefaultChange}
            loading={props.loading}
            error={props.error}
        />}
    </Modal>
}

export type PlattixSubmitFormProps<TModel, TResponse> = PlattixAutoFormProps<TModel> & {
    /**
     * Url to post form data to
     */
    postUrl: string,
    /**
     * Callback function that is called when the form was successfully submitted
     * @param model: The server response
     */
    onSuccess?: (model: TResponse) => void,

    form?: UseFormReturn<TModel, object>,
}

/**
 * Generate a PlattixAutoForm that will post the form to the postUrl provided in the properties
 * @param props
 * @constructor
 */
export function PlattixSubmitForm<TModel, TResponse>(props: PlattixSubmitFormProps<TModel, TResponse>) {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<HttpError | null>(null)
    
    // useEffect(() => {
    //     console.log(`error:`, error);
    // }, [error]);

    async function onSubmit(model) {
        setLoading(true)
        
        const response = await doPost<TResponse>(props.postUrl, model)
        setLoading(false)

        if (isHttpError(response)) {
            setError(response)
        } else {
            setError(null)

            if (props.onSuccess) props.onSuccess(response)
        }
    }

    return <PlattixAutoForm
        {...props}
        onSubmit={onSubmit}
        loading={loading}
        error={error}
    />

}

export interface InvalidFormFieldsErrorParams<TModel> {
    errors: FieldErrors<TModel>;
    event?: React.BaseSyntheticEvent;
    onClose?: () => void;
    onShow?: () => void;
    fields: PlattixSubmitField<TModel>[];
}

export function InvalidFormFieldsError<TModel>(params: InvalidFormFieldsErrorParams<TModel>)  {
    if (!Object.keys(params.errors).length) return;
    
    const missingKeys = Object.keys(params.errors);
    params.onShow?.();
    
    return toast.error((
        <>
            <b>{t("Form.Missing.Fields")}</b>
            <ul>
                {missingKeys.map(mk => {
                    const field = params.fields.find(f => f.name === mk);
                    const label = !!field?.label ? field.label : t(mk);
                    return (<li key={mk}>{label}</li>)
                })}
            </ul>
        </>
    ), {
        onClose: () => params.onClose?.(),
        autoClose: false,
    });
}