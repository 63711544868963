import React from "react";
import {PlattixValidatedInputProps} from "PlattixUI/core/components/form/Input";
import {Path, PathValue, ValidateResult} from "react-hook-form";

export class Validator<TFieldValues> {

    protected get field() {
        return this.props?.name;
    }

    protected get form() {
        return this.props?.formHook;
    }

    public name = "Validator";
    public message: React.ReactNode | null = null;
    
    protected get isValid(): boolean {
    // public get isValid(): boolean {
        if (!this.form || !this.field) return false;
        return !!this.validate(this.form?.getValues(this.field));
    }
    
    get isLoading(): boolean {
        // by default, validators are synchronous
        return false
    }

    private _props: PlattixValidatedInputProps<TFieldValues> | null = null;

    get props(): PlattixValidatedInputProps<TFieldValues> | null {
        return this._props;
    }

    set props(value: PlattixValidatedInputProps<TFieldValues> | null) {
        this._props = value;
    }

    public _register(props: PlattixValidatedInputProps<TFieldValues>) {
        this.props = props;
    }

    validate(value: PathValue<TFieldValues, Path<TFieldValues>>): ValidateResult | Promise<ValidateResult> {
        return true;
    }

    getErrorMessage(): React.ReactNode {
        if (!this.form || !this.field) throw new Error("Form and/or field not set");
        if (!this.isValid)
            return this.message ?? this.name;
        return null;
    }
}