import React, {Suspense, useEffect} from 'react';

import 'PlattixUI/scss/Index.scss';
import {setup} from 'goober';

import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';

import 'moment/locale/nl-be';
import 'moment/locale/fr';
import 'moment/locale/en-in';
import 'moment/locale/de';
import 'react-toastify/dist/ReactToastify.css';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {setLicenseKeys} from 'PlattixUI/core/License';
import {prefix} from 'goober/prefixer';
import {PlattixToastContainer} from 'PlattixUI/core/components/ToastContent';
import {ResponsiveChecker} from 'PlattixUI/core/components/ResponsiveChecker';
import { anonymousRoutes } from 'pages/Routers/AnonymousRoutes';
import { ContentComponent } from 'PlattixUI/core/Content';
import {ResponsiveComponent} from "PlattixUI/core/components/Responsive";
import {NavigationComponent} from "PlattixUI/core/Navigation";
import {SidebarComponent} from "PlattixUI/core/Sidebar";
import {CheckIdentityRouters} from "pages/Auth/CheckIdentityRouters";
import {CheckLoginComponent, CheckLoginForIdentityServer} from 'PlattixUI/core/pages/Account/CheckLoginComponent';
import {userSelector} from "PlattixUI/PlattixReactCore/UserSlice";
import { useAppSelector } from 'PlattixUI/PlattixReactCore/hooks';

// @ts-ignore
library.add(fas)

setup(React.createElement);
setup(React.createElement, prefix);

const queryClient = new QueryClient()

function App() {

  // license keys componenten ophalen
  setLicenseKeys();

  if (process.env.NODE_ENV === 'production') {
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}
  }

  
  const identityServer: CheckLoginForIdentityServer = {
    app: {
      platformName: 'Energytix',
      clientName: 'Energytix',
      returnUrl: '',
      logo: '28f0c824bd114fb6bd92d90c5a6d8ffd/Tekengebied 3.png',
      backgroundImage: '7492ccf01c9548b19b577f5d8fa4cfff/login.png',
      sponsors: [{
        name: 'Vlaio',
        logo: '230cf4146a634a3a8d47ae91d317f39e/logo.png',
      }],
      externalLoginProviders: ['none'],
    },
  }

  return (
      <QueryClientProvider client={queryClient}>
          <div id="root" className={"login-body"}>
              
              <CheckIdentityRouters
                identityServer={identityServer}
              />
              
              <PlattixToastContainer/>
              <ResponsiveChecker show={false}/>
          </div>
      </QueryClientProvider>
  );
}

export default App;