import React, {HTMLAttributes, useEffect, useRef, useState} from "react";
import {PlattixInput} from "./form/Input";
import {t} from "PlattixUI/PlattixReactCore/i18n";
import {doGet, PlattixDataHasBeenLoaded, throwOnHttpError, usePlattixQuery} from "PlattixUI/PlattixReactCore/api/Api";
import {useQuery} from '@tanstack/react-query';
import {SelectOption} from "./form/Select";
import {sortLabels} from "PlattixUI/util/StringUtil";
import {useImmer} from "use-immer";
import {checkLoadingState, LoadingState, updateLoadingState} from "PlattixUI/core/CoreUtil";


export interface AddressComponentProps extends HTMLAttributes<any> {
    form: any,
    isRequired?: boolean,
    isReadonly?: boolean,
    altCountryTranslation?: string,
    altCityTranslation?: string,
    altStreetTranslation?: string,
    altHousenumberTranslation?: string,
    addressModel: string
}

AddressComponent.defaultProps =
    {
        isRequired: true,
        isReadonly: false
    }

export type AddressType = "mailing" | "invoice" | "delivery";

export interface AddressModel {
    address: string,
    addressId?: number,
    addressObjectId?: number,
    city?: string,
    cityId?: number,
    country: string,
    countryId?: number,
    customAddress: string,
    customCity: string,
    customPostalCode: string,
    customStreet: string,
    postalCodeCity: string,
    postalCodeCityId?: number,
    street: string,
    streetObjectId?: number,
}

type KeyValuePair = {
    key: number,
    value: string
}

export function AddressComponent(props: AddressComponentProps) {

    const {register, setValue, watch} = props.form;
    const [oldPostalcodeCityId, setOldpostalcodeCityId] = useState<Number>(0);
    const [oldStreetObjectId, setOldStreetObjectId] = useState<Number>(0);
    const [oldCountryId, setOldCountryId] = useState<Number>(0);

    const postalcodeCityId = watch(`${props.addressModel}.postalCodeCityId`);
    const street = watch(`${props.addressModel}.street`);
    const streetId = watch(`${props.addressModel}.streetObjectId`);
    const countryId = watch(`${props.addressModel}.countryId`);
    
    const streetInput = watch(`${props.addressModel}.Street`);
    
    const [autoComplete, setAutoComplete] = useState<boolean>(true);
    const [customAddress, setCustomAddress] = useState<boolean>(false);
    const [initializedAddress, setInitializedAddress] = useState<boolean>(false);
    
    const [loading, setLoading] = useImmer<LoadingState[]>([
        {
            id: `${props.addressModel}.Country`,
            loading: true,
        },
        {
            id: `${props.addressModel}.PostalCodeCity`,
            loading: true,
        },
        {
            id: `${props.addressModel}.Street`,
            loading: true,
        },
        {
            id: `${props.addressModel}.Address`,
            loading: true,
        },
    ]);
    
    const addressesRef = useRef<HTMLDivElement>(null);

    /* *
    * Ophalen van alle landen.
    * */
    // const countries = useQuery<SelectOption[]>(['Countries'],
    //     () => throwOnHttpError(doGet<SelectOption[]>('/Address/GetCountries')),
    //     {
    //         staleTime: 5 * 60 * 1000, // cache for an hour ,
    //         cacheTime: 60 * 60 * 1000, // cache for an hour ,
    //         keepPreviousData: true,
    //         enabled: autoComplete,
    //     }
    // )
    const countries = usePlattixQuery<SelectOption[]>(
        ['Countries'],
        '/Address/GetCountries',
        undefined,
        {
            staleTime: 5 * 60 * 1000, // cache for an hour ,
            cacheTime: 60 * 60 * 1000, // cache for an hour ,
            keepPreviousData: true,
            enabled: (autoComplete),
        }
    )
    
    useEffect(() => {
        if (!initializedAddress && countries.data) {
            setLoading(updateLoadingState({
                id: `${props.addressModel}.Country`,
                loading: false
            }));
            
            let country = countries.data?.find((x) => x.label === props.form.getValues(`${props.addressModel}.country`));
            const id = country?.value;
            setCustomAddress(!!id && !country?.selected);
            setInitializedAddress(true);
        }
    }, [countries.data]);

    /* *
    * Ophalen van alle postcodes + steden.
    * */
    // const postalCodeCities = useQuery<KeyValuePair[]>(['PostalcodeCities'],
    //     () => throwOnHttpError(doGet<KeyValuePair[]>('/Address/GetPostalcodeCities')),
    //     {
    //         staleTime: 5 * 60 * 1000, // cache for an hour ,
    //         cacheTime: 60 * 60 * 1000, // cache for an hour ,
    //         keepPreviousData: true,
    //         enabled: autoComplete,
    //     }
    // )
    const postalCodeCities = usePlattixQuery<KeyValuePair[]>(
        ['PostalcodeCities', countryId],
        '/Address/GetPostalcodeCities',
        undefined,
        {
            staleTime: 5 * 60 * 1000, // cache for an hour ,
            cacheTime: 60 * 60 * 1000, // cache for an hour ,
            keepPreviousData: true,
            enabled: (autoComplete && !!countryId),
        }
    )

    useEffect(() => {
        if (
            !!postalCodeCities.data?.length 
            && postalCodeCities.isFetched 
            && checkLoadingState(loading, `${props.addressModel}.PostalCodeCity`)
        ) {
            setLoading(updateLoadingState({
                id: `${props.addressModel}.PostalCodeCity`,
                loading: false
            }));
        }
    }, [postalCodeCities]);

    /* *
    * Ophalen van alle straten.
    * */
    // const streets = useQuery<KeyValuePair[]>(['Streets', postalcodeCityId],
    //     () => {
    //         if (!postalcodeCityId) return []
    //         return throwOnHttpError(doGet<KeyValuePair[]>('/Address/GetStreets', {postalcodeCityId: postalcodeCityId}))
    //     },
    //     {
    //         staleTime: 5 * 60 * 1000, // cache for an hour ,
    //         cacheTime: 60 * 60 * 1000, // cache for an hour ,
    //         keepPreviousData: true,
    //         enabled: autoComplete,
    //     }
    // )
    const streets = usePlattixQuery<KeyValuePair[]>(
        ['Streets', postalcodeCityId],
        '/Address/GetStreets', 
        {
            postalcodeCityId: postalcodeCityId
        },
        {
            staleTime: 5 * 60 * 1000, // cache for an hour ,
            cacheTime: 60 * 60 * 1000, // cache for an hour ,
            keepPreviousData: true,
            enabled: (autoComplete && !!postalcodeCityId),
        }
    )

    useEffect(() => {
        if (
            !!streets.data?.length 
            && streets.isFetched 
            && checkLoadingState(loading, `${props.addressModel}.Street`)
        ) {
            setLoading(updateLoadingState({
                id: `${props.addressModel}.Street`,
                loading: false
            }));
        }
    }, [streets]);

    /* *
    * Ophalen van alle huisnummers.
    * */
    // const addresses = useQuery<KeyValuePair[]>(['Addresses', postalcodeCityId, streetId],
    //     () => {
    //         if (!postalcodeCityId || !street || !streetId) return []
    //         return throwOnHttpError(doGet<KeyValuePair[]>('/Address/GetAddresses', {
    //             postalcodeCityId: postalcodeCityId,
    //             street: street
    //         }))
    //     },
    //     {
    //         staleTime: 5 * 60 * 1000, // cache for an hour ,
    //         cacheTime: 60 * 60 * 1000, // cache for an hour ,
    //         keepPreviousData: true,
    //         enabled: autoComplete,
    //     }
    // )
    const addresses = usePlattixQuery<KeyValuePair[]>(
        ['Addresses', postalcodeCityId, street, streetId],
    '/Address/GetAddresses', 
    {
                postalcodeCityId: postalcodeCityId,
                street: street,
        },
        {
            staleTime: 5 * 60 * 1000, // cache for an hour ,
            cacheTime: 60 * 60 * 1000, // cache for an hour ,
            keepPreviousData: true,
            enabled: (autoComplete && !!postalcodeCityId && !!street && !!streetId),
        }
    )

    useEffect(() => {
        if (
            // !!addresses.data?.length &&
            addresses.isFetched 
            && checkLoadingState(loading, `${props.addressModel}.Address`)
        ) {
            setLoading(updateLoadingState({
                id: `${props.addressModel}.Address`,
                loading: false
            }));
        }
    }, [addresses]);
    
    const onChangeCountryHandler = async (e) => {
        let country = await countries.data?.find((x) => x.label === props.form.getValues(`${props.addressModel}.country`));
        const id = country?.value;
        if (id !== oldCountryId && !!id) {
            await setValue(`${props.addressModel}.countryId`, Number(id));
            await setOldCountryId(Number(id));
            clearCityValues();
        }
        if (!id) {
            await setValue(`${props.addressModel}.countryId`, null);
            clearCityValues();
        }
        if (!!id && country?.selected) {
            setCustomAddress(false)
            
        }
        else if (!!id && !country?.selected) {
            setCustomAddress(true)
        }
    };
    
    const clearCityValues = () => {
        setValue(`${props.addressModel}.postalCodeCity`, null);
        setValue(`${props.addressModel}.postalCodeCityId`, null);

        setLoading(updateLoadingState({
            id: `${props.addressModel}.PostalCodeCity`,
            loading: true
        }));
        
        clearStreetValues();
    }

    /* *
    * Resetten van de straten.
    * */
    const clearStreetValues = () => {
        setValue(`${props.addressModel}.street`, null);
        setValue(`${props.addressModel}.streetObjectId`, null);
        
        setLoading(updateLoadingState({
            id: `${props.addressModel}.Street`,
            loading: true
        }));
        
        clearAddressValues();
    }
    
    const getStreets = async (e) => {
        let id = await postalCodeCities.data?.find((x) => x.value === props.form.getValues(`${props.addressModel}.postalCodeCity`))?.key;

        if (id !== oldPostalcodeCityId && !!id) {
            await setValue(`${props.addressModel}.postalCodeCityId`, Number(id));
            await setOldpostalcodeCityId(id);
            await clearStreetValues();
            
            // const nextInput = await addressesRef.current?.querySelector(`#${props.addressModel}_SearchStreet`) as HTMLElement | null;
            // await nextInput?.focus();
        }

        if (!id) {
            await setValue(`${props.addressModel}.postalCodeCityId`, null);
            await clearStreetValues();
        }

    }

    /* *
    * Resetten van de huisnummers.
    * */
    function clearAddressValues() {
        setValue(`${props.addressModel}.address`, null);
        setValue(`${props.addressModel}.addressObjectId`, null);
        
        setLoading(updateLoadingState({
            id: `${props.addressModel}.Address`,
            loading: true
        }));
    }

    const getAddresses = async (e) => {
        let id = await streets.data?.find((x) => x.value === props.form.getValues(`${props.addressModel}.street`))?.key;
        
        if (id !== oldStreetObjectId && !!id) {
            await setValue(`${props.addressModel}.streetObjectId`, Number(id));
            await setOldStreetObjectId(id);
            await clearAddressValues();

            // const nextInput = await addressesRef.current?.querySelector(`#${props.addressModel}_SearchAddress`) as HTMLElement | null;
            // await nextInput?.focus();
        }

        if (!id) {
            await setValue(`${props.addressModel}.streetObjectId`, null);
            await clearAddressValues();
        }
    }

    const getAddress = async (e) => {
        let id = await addresses.data?.find((x) => x.value === props.form.getValues(`${props.addressModel}.address`))?.key;

        await setValue(`${props.addressModel}.addressObjectId`, Number(id ?? -1));

        // const nextInput = await addressesRef.current?.querySelector(`#${props.addressModel}_SearchStreet`) as HTMLElement | null;
        // await nextInput?.focus();
    }
    
    const setAddressObjectId = async (e) => {
        await setValue(`${props.addressModel}.addressObjectId`, Number(-1));
    }
    
    /* *
    * Alleen renderen wanneer de inputs readonly zijn.
    * */
    if (props.isReadonly) {
        return <div>
            <PlattixInput
                id={`${props.addressModel}_SearchPostalCodeCity`}
                name={`${props.addressModel}.PostalCodeCity`}
                register={register(`${props.addressModel}.postalCodeCity`)}
                label={t(props.altCityTranslation ?? 'Address.City.Description')}
                readOnly={true}
                autoComplete={'off'}
            />

            <PlattixInput
                id={`${props.addressModel}_SearchStreet`}
                name={`${props.addressModel}.Street`}
                register={register(`${props.addressModel}.street`)}
                label={t(props.altStreetTranslation ?? "street")}
                readOnly={true}
                autoComplete={'off'}
            />

            <PlattixInput
                id={`${props.addressModel}_SearchAddress`}
                name={`${props.addressModel}.Address`}
                register={register(`${props.addressModel}.address`)}
                label={t(props.altHousenumberTranslation ?? "housenumber")}
                readOnly={true}
                autoComplete={'off'}
            />
        </div>
    }

    return (
        <>
            <div className="AddressPartial" id={`ap_${props.addressModel}`} ref={addressesRef}>
                
                <PlattixInput
                    id={`${props.addressModel}_SearchCountry`}
                    name={`${props.addressModel}.Country`}
                    register={register(`${props.addressModel}.country`, {
                        required: props.isRequired,
                        onChange: (e) => onChangeCountryHandler(e)
                    })}
                    label={t(props.altCountryTranslation ?? "country")}
                    list={`${props.addressModel}_Countries`}
                    error={countries.isError ? t('Address.Error.FailedToLoad') : props.form.getFieldState(`${props.addressModel}.country`)?.error}
                    required={props.isRequired}
                    loading={checkLoadingState(loading, `${props.addressModel}.Country`)}
                />
                
                <datalist id={`${props.addressModel}_Countries`}>
                    <select multiple size={8}>
                        {countries.data
                            ?.sort(sortLabels)
                            ?.map((o) => {
                                return (
                                    <option key={o.value.toString()} id={o.value.toString()} value={o.label.toString()} />
                                )
                            })
                        }
                    </select>
                </datalist>

                {customAddress &&
                    <>
                        <PlattixInput
                            id={`${props.addressModel}_SearchCustomCity`}
                            name={`${props.addressModel}.CustomCity`}
                            register={register(`${props.addressModel}.customCity`, {
                                required: props.isRequired,
                            })}
                            label={t(props.altCityTranslation ?? 'Address.City.Description')}
                            required={props.isRequired}
                        />
                        <PlattixInput
                            id={`${props.addressModel}_SearchCustomPostalCode`}
                            name={`${props.addressModel}.CustomPostalCode`}
                            register={register(`${props.addressModel}.customPostalCode`, {
                                required: props.isRequired,
                            })}
                            label={t("postalcode")}
                            required={props.isRequired}
                        />
                        <PlattixInput
                            id={`${props.addressModel}_SearchCustomStreet`}
                            name={`${props.addressModel}.CustomStreet`}
                            register={register(`${props.addressModel}.customStreet`, {
                                required: props.isRequired,
                            })}
                            label={t(props.altStreetTranslation ?? "street")}
                            required={props.isRequired}
                        />
                        <PlattixInput
                            id={`${props.addressModel}_SearchCustomAddress`}
                            name={`${props.addressModel}.CustomAddress`}
                            register={register(`${props.addressModel}.customAddress`, {
                                required: props.isRequired,
                                onChange: (e) => setAddressObjectId(e)
                            })}
                            label={t(props.altHousenumberTranslation ?? "housenumber")}
                            required={props.isRequired}
                        />
                        
                    </>
                }

                
                {!customAddress && 
                    <>
                        <PlattixInput
                            id={`${props.addressModel}_SearchPostalCodeCity`}
                            name={`${props.addressModel}.PostalCodeCity`}
                            register={register(`${props.addressModel}.postalCodeCity`, {
                                required: props.isRequired,
                                onChange: (e) => getStreets(e)
                            })}
                            label={t(props.altCityTranslation ?? 'Address.City.Description')}
                            list={`${props.addressModel}_PostalCodeCities`}
                            error={postalCodeCities.isError ? t('Address.Error.FailedToLoad') : props.form.getFieldState(`${props.addressModel}.postalCodeCity`)?.error}
                            disabled={!countryId}
                            required={props.isRequired}
                            loading={checkLoadingState(loading, `${props.addressModel}.PostalCodeCity`)}
                            loadingText={!countryId ? t('Address.Select.country.First', {
                                country: t('country').toLowerCase()
                            }) : undefined}
                        />
                        <datalist id={`${props.addressModel}_PostalCodeCities`}>
                            <select multiple size={8}>
                                {postalCodeCities.data?.map((o) => {
                                    return (
                                        <option key={o.key} id={o.key.toString()} value={o.value} />
                                    )
                                })}
                            </select>
                        </datalist>
    
                        <PlattixInput
                            id={`${props.addressModel}_SearchStreet`}
                            name={`${props.addressModel}.Street`}
                            register={register(`${props.addressModel}.street`, {
                                required: props.isRequired,
                                onChange: (e) => getAddresses(e)
                            })}
                            label={t(props.altStreetTranslation ?? "street")}
                            list={`${props.addressModel}_Streets`}
                            disabled={!postalcodeCityId}
                            error={streets.isError ? t('Address.Error.FailedToLoad') : props.form.getFieldState(`${props.addressModel}.street`)?.error}
                            placeholder={!postalcodeCityId? t('Address.Street.ChooseCity') :  undefined}
                            required={props.isRequired}
                            loading={checkLoadingState(loading, `${props.addressModel}.Street`)}
                            loadingText={!postalcodeCityId ? t('Address.Select.City.First', {
                                city: t('Address.City.Description').toLowerCase()
                            }) : undefined}
                        />
    
                        <datalist id={`${props.addressModel}_Streets`}>
                            <select multiple size={8}>
                                {streets.data?.map((o) => {
                                    return (
                                        <option key={o.key} id={o.key.toString()} value={o.value}/>
                                    )
                                })}
                            </select>
                        </datalist>
    
                        <PlattixInput
                            id={`${props.addressModel}_SearchAddress`}
                            name={`${props.addressModel}.Address`}
                            register={register(`${props.addressModel}.address`, {
                                required: props.isRequired,
                                onChange: (e) => getAddress(e)
                            })}
                            label={t(props.altHousenumberTranslation ?? "housenumber")}
                            list={`${props.addressModel}_Addresses`}
                            disabled={!streetId}
                            error={addresses.isError ? t('Address.Error.FailedToLoad') : props.form.getFieldState(`${props.addressModel}.address`)?.error}
                            placeholder={!streetId ? t('Address.HouseNumber.ChooseStreet') : undefined}
                            required={props.isRequired}
                            loading={checkLoadingState(loading, `${props.addressModel}.Address`)}
                            loadingText={!street ? t('Address.Select.street.First', {
                                street: t('street').toLowerCase()
                            }) : undefined}
                        />
                        <datalist id={`${props.addressModel}_Addresses`}>
                            <select multiple size={8}>
                                {addresses.data?.map((o) => {
                                    return (
                                        <option key={o.key} id={o.key.toString()} value={o.value}/>
                                    )
                                })}
                            </select>
                        </datalist>
                    </>
                }
                
            </div>

        </>

    )
}

