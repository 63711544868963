import {ErrorType, HttpError} from "../CoreTypes";
import {ApiRootUrl} from "configuration/Configuration";
import {
    MutationKey,
    QueryKey,
    useMutation,
    UseMutationOptions,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryOptions,
    UseQueryResult
} from "@tanstack/react-query";
import {t} from "../i18n";
import {ClientSettings} from "PlattixUI/PlattixReactCore/types/PlattixConfiguration";
import {store} from "PlattixUI/PlattixReactCore/store";
import {logoutUser} from "PlattixUI/PlattixReactCore/UserSlice";
import {doUserLogout} from "PlattixUI/PlattixReactCore/api/AccountApi";
// import {QueryKey} from "react-query/types/core/types";
// import {UseQueryResult} from "react-query/types/react/types";

export type requestOptions = {
    abortSignal?: AbortSignal,
    /**
     * Send request to CORS server
     */
    remote?: boolean,
    /**
     * Throw exception on http error instead of returning HttpError
     */
    throwOnError?: boolean,
    disallow4xxError?: boolean,
    headers?: HeadersInit;
    stringifyBody?: boolean;
    toCamelCase?: boolean;
}

export async function doPut<T>(url: string, data?: any, options?: requestOptions): Promise<T | HttpError> {
    try {

        if (!options?.remote)
            url = getFullUrl(url);


        // const response = await axios.post(url, data, config);
        const response = await fetch(url, {
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(data),
            signal: options?.abortSignal
        })
        return await handleResponse(response, options);
    } catch (e: any) {
        if (options?.throwOnError) throw e;
        return handleError(e)
    }
}

export async function doPost<T>(url: string, data?: any, options?: requestOptions): Promise<T | HttpError> {
    try {

        if (!options?.remote) url = getFullUrl(url);
        const stringifyBody = options?.stringifyBody == null ? true : options.stringifyBody;


        // const response = await axios.post(url, data, config);
        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + await getAccessTokenAsync(),
                ...options?.headers,
            },
            credentials: 'include',
            body: stringifyBody ? JSON.stringify(data) : data,
            signal: options?.abortSignal
        })
        return await handleResponse(response, options);
    } catch (e: any) {
        if (options?.throwOnError) throw e;
        return handleError(e)
    }
}

export async function doPostFormData<T>(url: string, data?: FormData, options?: requestOptions): Promise<T | HttpError> {
    try {
        const response = await fetch(getFullUrl(url), {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: data,
            signal: options?.abortSignal
        })
        return await handleResponse(response, options);
    } catch (e: any) {
        return handleError(e)
    }
}

export type DoPostFormDataXhrOptions = {
    abortSignal?: AbortSignal,
    onUploadProgress?: (number) => void,
    onDownloadProgress?: (number) => void
}

export function doPostFormDataXhr<T>(url: string, data?: FormData, options?: DoPostFormDataXhrOptions
): Promise<T | HttpError> {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()

        function abort() {
            xhr.abort()
        }

        try {

            xhr.upload.addEventListener("progress", (event) => {
                if (event.lengthComputable) {
                    // console.log("upload progress:", event.loaded / event.total);
                    options?.onUploadProgress?.(event.loaded / event.total)
                }
            });
            xhr.addEventListener("progress", (event) => {
                if (event.lengthComputable) {
                    // console.log("download progress:", event.loaded / event.total);
                    // downloadProgress.value = event.loaded / event.total;
                    options?.onDownloadProgress?.(event.loaded / event.total)
                }
            });

            xhr.onload = () => {
                options?.abortSignal?.removeEventListener('abort', abort)

                const responseData = JSON.parse(xhr.responseText)
                if (xhr.status === 200) {
                    resolve(responseData as T)
                }

                resolve({
                    errorType: ErrorType.Server,
                    status: xhr.status,
                    statusText: xhr.statusText,
                    title: responseData?.title ?? GetMessageForStatusCode(xhr.status),
                    detail: responseData?.detail,
                    traceId: responseData?.traceId,
                    errors: responseData?.errors,
                } as HttpError)
            }

            xhr.onerror = () => {
                options?.abortSignal?.removeEventListener('abort', abort)
                resolve(createFailedToFetchHttpError());
            }
            xhr.onabort = () => {
                options?.abortSignal?.removeEventListener('abort', abort)
                resolve(RequestAbortedHttpError)
            }
            xhr.open('Post', getFullUrl(url))
            xhr.withCredentials = true
            xhr.send(data);

            options?.abortSignal?.addEventListener('abort', abort)

        } catch (e: any) {
            resolve(handleError(e))
            options?.abortSignal?.removeEventListener('abort', abort)
        }
    });
}


const getSearchParams = (params?: GetParams) => {
    let searchParams = new URLSearchParams();
    if (params) {
        Object.keys(params)
            .forEach(k => {
                const value = params[k]
                if (value === null || value === undefined || params[k] === 0) return;

                if (Array.isArray(value)) {
                    value.forEach(v => searchParams.append(k, v))
                } else {
                    searchParams.append(k, value.toString())
                }
            })
    }
    return searchParams
}

export function getFullUrl(url: string, searchParams?: URLSearchParams) {
    const params = searchParams?.toString();
    return ApiRootUrl + url + (params ? `?${params}` : '')
}

export type GetParams = { [key: string]: string | number | null | undefined | number[] | string[] }

export async function doGet<T>(url: string, params?: GetParams, options?: requestOptions): Promise<T | HttpError> {
    try {
        let searchParams = getSearchParams(params)

        if (!options?.remote) url = getFullUrl(url, searchParams)
        else
            url = url + (searchParams ? `?${searchParams?.toString()}` : '')

        const response: Response = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            // cache: 'no-cache',
            // headers: {
            //     'cache-control': 'no-cache',
            // },
            headers: {
                // 'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + await getAccessTokenAsync(),
                ...options?.headers,
            },
            credentials: 'include',
            signal: options?.abortSignal
        });

        return await handleResponse(response, options);
    } catch (e: any) {
        return handleError(e)
    }
}

export async function doDelete<T>(url: string, params?: GetParams, options?: requestOptions): Promise<T | HttpError> {
    try {
        let searchParams = getSearchParams(params)
        const response: Response = await fetch(getFullUrl(url, searchParams), {
            method: 'DELETE',
            mode: 'cors',
            // cache: 'no-cache',
            // headers: {
            //     'cache-control': 'no-cache',
            // },
            credentials: 'include',
            signal: options?.abortSignal
        });

        return await handleResponse(response, options);
    } catch (e: any) {
        return handleError(e)
    }
}

async function handleResponse<T>(response: Response, options: requestOptions | undefined) {
    let responseData;
    const contentType = response.headers.get("content-type");
    
    if (contentType) {
        if (contentType.indexOf("application/json") !== -1 || contentType.indexOf("application/problem+json") !== -1) {
            try {
                responseData = await response.json();
            } catch {
                // ignore
            }
        }
        // If HTML is returned, throw error
        // This means the endpoint was not found and the default page is returned
        if (contentType.indexOf("text/html") !== -1) {
            if (options?.throwOnError) throw new Error(GetMessageForStatusCode(404))
            return {
                errorType: ErrorType.Server,
                status: 404,
                statusText: GetMessageForStatusCode(response.status),
                title: GetMessageForStatusCode(response.status),
                detail: responseData?.detail,
                traceId: response.headers.get("ETag"),
                errors: {},
            } as HttpError
        }
        
    }

    if (!response.ok) {
        if (response.status === 401) {
            // 401 unauthorized -> logout user
            doUserLogout()
        }

        if (options?.throwOnError) throw new Error(responseData?.title ?? GetMessageForStatusCode(response.status))
        return {
            errorType: ErrorType.Server,
            status: response.status,
            statusText: response.statusText,
            title: responseData?.title ?? GetMessageForStatusCode(response.status),
            detail: responseData?.detail,
            traceId: responseData?.traceId,
            errors: responseData?.errors,
        } as HttpError
    }

    return responseData as T;
}

function handleError(e: any): HttpError {
    if (e instanceof DOMException) {
        if (e.name === 'AbortError') {
            console.log('Fetch aborted');
            return RequestAbortedHttpError;
        }
    } else if (e instanceof TypeError) {
        if (e.message === "Failed to fetch") {
            return createFailedToFetchHttpError()
        }
    }


    console.log(e)
    return RequestFailedHttpError;
}

/**
 * Wrapper around Api calls that will throw the HttpError instead of just returning it.
 * @param response the response of type T
 * @throws HttpError if the response is a HttpError
 */
export async function throwOnHttpError<T>(response: Promise<T | HttpError> | T | HttpError): Promise<T> {
    const r = await response;
    if (isHttpError(r)) throw r;
    return r;
}

/**
 * Wrapper around Api calls that will throw the HttpError when a non 4XX status code is returned.
 * @param response the response of type T
 * @throws HttpError if the response is a HttpError
 */
export async function throwOnNetworkError<T>(response: Promise<T | HttpError> | T | HttpError) {
    const r = await response;
    if (isHttpError(r)) {
        if (r.errorType !== ErrorType.Server) throw r;

        if (r.status >= 400 && r.status < 500) return r;
        throw r;
    }
    return r;
}

export function usePlattixQuery<TQueryFnData = unknown, TError extends HttpError = HttpError, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>
(
    queryKey: TQueryKey,
    url: string,
    queryParams?: GetParams,
    options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: () => undefined },
    queryOptions?: requestOptions
): UseQueryResult<TData, TError> {
    return useQuery<TQueryFnData, TError, TData, TQueryKey>(queryKey, () => throwOnHttpError<TQueryFnData>(doGet<TQueryFnData>(url, queryParams, queryOptions)), options)
}

export function usePlattixMutation<TQueryFnData = unknown, TData = TQueryFnData, TError = HttpError, TQueryKey extends QueryKey = QueryKey>(
    mutationKey: MutationKey,
    url: string,
    queryParams?: GetParams,
    options?: Omit<UseMutationOptions<TQueryFnData, TError, TData, TQueryKey>, "mutationKey" | "mutationFn">,
    queryOptions?: requestOptions
    )
    : UseMutationResult<TQueryFnData, TError, TData, TQueryKey> 
{
    const queryClient = useQueryClient();

    return useMutation<TQueryFnData, TError, TData, TQueryKey>(
        mutationKey,
        (newData: TData) => throwOnHttpError<TQueryFnData>(doPost<TQueryFnData>(url, newData, queryOptions))
        , {
            ...options,
            onSuccess: (data: TQueryFnData,variables,context) => {
                queryClient.setQueryData(mutationKey, (old:TQueryFnData|undefined) => data)
                options?.onSuccess?.(data, variables, context)
            },
        })
}

export function isHttpError(response: HttpError | any): response is HttpError {
    if (response === undefined) return false;
    return (<HttpError>response).status !== undefined;
}

const RequestAbortedHttpError: HttpError = {
    errorType: ErrorType.Aborted, detail: "", errors: {}, status: 0, statusText: "", title: "", traceId: ""
}

const createFailedToFetchHttpError = (): HttpError => ({
    errorType: ErrorType.Network,
    detail: "",
    errors: {},
    status: 0,
    statusText: "Could Not Connect To Server",
    title: t("Error.FailedToContactServer"),
    traceId: ""
})

const RequestFailedHttpError: HttpError = {
    errorType: ErrorType.Unknown, detail: "", errors: {}, status: 0, statusText: "", title: "", traceId: ""
}

export function GetMessageForStatusCode(statusCode: number) {
    switch (statusCode) {
        // case 'Bad Request':
        case 400:
            return t("Response.BadRequest");
        // case 'Unauthorized':
        case 401:
            return t("Response.Unauthorized");
        // case 'Payment Required':
        case 402:
            return t("Response.PaymentRequired");
        // case 'Forbidden':
        case 403:
            return t("Response.Forbidden");
        // case 'Not Found':
        case 404:
            return t("Response.NotFound");
        case 405:
            return t("Response.MethodNotAllowed");
        case 406:
            return t("Response.NotAcceptable");
        case 407:
            return t("Response.ProxyAuthenticationRequired");
        // case 'Request Timeout':
        case 408:
            return t("Response.RequestTimeout");
        // case 'Conflict':
        case 409:
            return t("Response.Conflict");
        // case 'Gone':
        case 410:
            return t("Response.Gone");
        case 411:
            return t("Response.LengthRequired");
        // case 'Precondition Failed':
        case 412:
            return t("Response.PreconditionFailed");
        case 413:
            return t("Response.PayloadToLarge");
        case 414:
            return t("Response.UriTooLong");
        case 415:
            return t("Response.UnsupportedMediaType");
        case 416:
            return t("Response.RangeNotSatisfiable");
        case 417:
            return t("Response.ExpectationFailed");
        // case "I'm a teapot":
        case 418:
            return t("Response.ImATeapot");
        // case 'Internal Server Error':
        case 500:
            return t("Response.ServerError");
        default:
            return t("Error.Occured")
    }
}

export function useClientSettings() {
    return useQuery(["PlattixConfig"],
        () => throwOnHttpError(doGet<ClientSettings>("/clientsettings.json", undefined, {
            remote: true,
            throwOnError: true
        })),
        {
            cacheTime: 24 * 3600 * 1000,
            staleTime: 3600 * 1000,
            refetchOnWindowFocus: false
        }
    );
}

export function useHostUrl(hostname: string) {
    const appSettings = useClientSettings();
    return appSettings?.data?.Platforms?.[hostname].url;
}

/**
 * Fill a FormData object with a JS object
 * @param formData: From data to fill
 * @param data: data to add to form data
 * @param parentKey: optional parent key name
 */
export function buildFormData(formData: FormData, data: any, parentKey?: string, omit?: string[]) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).filter(key => !(omit ?? []).includes(key)).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data === null ? '' : data;
        if (parentKey) formData.append(parentKey, value);
    }
}

/**
 * Dit is een object dat aan de options van de usePlattixQuery mee kan gegeven worden om ervoor te zorgen dat er geen refetch gedaan wordt als het de eerste keer faalt.
 **/
export const preventRefetchAfterError = {
    refetchInterval: (data, query) => query.state.error ? 0 : 1000*60*60,
    refetchOnMount: (query) => !!query.state.error,
    retryOnMount: false,
    refetchOnWindowFocus: false,
}

// export const <T>PlattixDataHasBeenLoaded: ((query: UseQueryResult<T, unknown>) => boolean) = (query) => {
export const PlattixDataHasBeenLoaded: (<TQueryFnData = unknown, TError = unknown>(query: UseQueryResult<TQueryFnData, TError>) => boolean) = (query) => {
    // if (isHttpError(query)) return true;
    return !!query.isFetched;
}