import React, {
    createRef,
    forwardRef,
    Fragment,
    MutableRefObject,
    PropsWithChildren,
    RefObject,
    SyntheticEvent,
    useEffect,
    useRef,
    useState
} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {sideBarHover, sideBarHoverOut} from '../../js/newLayout/modules';
import {filterProps} from '../../util/ElementProperties';
import {IconLookup, IconName, IconPrefix} from '@fortawesome/fontawesome-svg-core';
import Modal from "./Modal";
import {t} from "PlattixUI/PlattixReactCore/i18n";
import {
    faArrowsToEye,
    faCaretDown,
    faCaretLeft,
    faCaretRight,
    faEllipsisH,
    faQuestionCircle,
    faSquareMinus,
    faSquarePlus,
    faTimes
} from '@fortawesome/free-solid-svg-icons';
import {
    ClickAwayListener,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
    Popper,
    Tab,
    Tabs,
    Tooltip,
    useMediaQuery,
    Zoom
} from "@mui/material";
import {Link, useHistory} from "react-router-dom";
import {
    CardOverlay,
    PlattixSectionContainer,
    PlattixSectionHeader,
    PlattixSectionHeaderButtonsAlignment,
    PlattixSectionHeaderCollapsible,
    PlattixSectionHeaderHeadline,
    PlattixSectionHeaderText,
    PlattixSectionMenu,
    PlattixSectionMenuItem,
    PlattixSectionMenuItemText,
    PlattixSectionStylingProps,
    PlattixShowcaseCardDataListContainer,
    ShowcaseCard,
    ShowcaseCardBody,
    ShowcaseCardButtons,
    ShowCaseCardCollapsibleButton,
    ShowCaseCardCollapsibleButtonContainer,
    ShowcaseCardHeader,
    ShowcaseCardHeaderProps,
    ShowcaseCardProps
} from './ContentCardStyling';
import {AddIconButton, ButtonActionIcon, ButtonActionType, ConfirmButton, DeleteIconButton} from "./Buttons";
import {css, styled} from "goober";
import {RequirePermission} from "../PermissionComponents";
import {hasPermission, PermissionAction, PlattixPermissionType} from "../../PlattixReactCore/Permissions";
import {ResponsiveComponent} from "./Responsive";
import {IPermissioned} from "PlattixUI/types/AccountTypes";
import {PlattixLink} from "PlattixUI/core/components/util/PlattixLink";
import {PlattixImage, PlattixImageProps} from "PlattixUI/util/thumbor/PlattixImage";
import {TooltipProps} from "@mui/material/Tooltip/Tooltip";
import {deviceDimensions} from "PlattixUI/core/components/Responsive";
import {useAppSelector} from "PlattixUI/PlattixReactCore/hooks";
import {isInRoleSelector} from "PlattixUI/PlattixReactCore/UserSlice";
import {useImmer} from "use-immer";
import {emptyNumberValue} from "PlattixUI/util/StringUtil";
import {PopperProps as BasePopperProps} from "@mui/base/Popper/Popper.types";
import {ReactI18NextChild} from "react-i18next";

function filterContentCardProps(props: any) {
    return filterProps(props, ['Title', 'Link']);
}

export const emptyDataValue = emptyNumberValue;

export interface ContentCardContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    asLegend?: boolean;
    asLegendItems?: boolean;
    sticky?: boolean;
    minwidth?: string;
}

/**
 * Dit is de container dat ervoor zorgt dat alle cards juist wordt weergegeven.
 **/
export function PlattixCardContainer(props: React.PropsWithChildren<ContentCardContainerProps>) {
    return (
        <div {...filterProps(props, ['asLegend', 'asLegendItems', 'sticky'])}
             className={`row content-masonry ${props.className ?? ''} 
             ${!props.sticky ? 'content-legend-notSticky' : ''} 
             ${props.asLegend ? 'content-legend' : ''}
             ${props.asLegendItems ? 'content-legend-items' : ''} `}
            style={{
                gridTemplateColumns: !!props.minwidth ? `repeat(auto-fill, minmax(${props.minwidth}, 1fr))` : undefined,
            }}
        >
            {props.children}
        </div>
    );
}

PlattixCardContainer.defaultProps = {
    asLegend: false,
    sticky: true
}

export type CardOptions = {
    label: string,
    onClick?: React.MouseEventHandler<HTMLDivElement> | undefined,
    id?: string,
    permission?: PlattixPermissionType,
    link?: string,
    type?: ButtonActionType,
} & (
    {
        permission?: undefined
    } | {
        permission: PlattixPermissionType,
        permissionAction: PermissionAction,
        permissionEntity: IPermissioned | "Platform" | null
    }
)

export type CloseCardType = {
    onClick: React.MouseEventHandler<HTMLSpanElement> | undefined,
    permission?: PlattixPermissionType,
} & (
    {
        permission?: undefined
    } | {
        permission: PlattixPermissionType,
        permissionAction: PermissionAction,
        permissionEntity: IPermissioned | "Platform" | null
    }
)

// export type SpanOptions = '1/-1' | '2/-1';

export interface ContentCardProps extends React.HTMLAttributes<HTMLDivElement> {
    header: string,
    options?: CardOptions[],
    closeButton?: CloseCardType,
    collapsible?: boolean,
    collapsed?: boolean,
    info?: PlattixCardInfo,
    onInfoClick?: () => void,
    /// Show an overlay message over the whole card
    overlay?: React.ReactNode
    ContentCardBodyClassname?: string,
    customBodyContent?: boolean,

    /// Make card take up full width of container
    // fullWidth?: boolean, // Deprecated. Geef 'full' mee aan de width als je de volledige breedte wilt gebruiken
    width?: PlattixColumnWidthType,
    equalHeight?: boolean,
    
}

/**
 * Dit is de card die overal wordt gebruikt. Hierin wordt content ingestoken om weer te geven op het platform.
 **/
export function PlattixCard(props: PropsWithChildren<ContentCardProps>) {
    const collapsedState = useState<boolean>(!!props.collapsed ?? false);
    const [collapsed, setCollapsed] = collapsedState;
    const isInRole = useAppSelector(isInRoleSelector);
    
    useEffect(() => {
        if (props.collapsed !== undefined) {
            setCollapsed(props.collapsed)
        }
    }, [props.collapsed]);

    // const collapsed = props.collapsed ?? false;

    return (
        <div 
            {...filterProps(props, ['header', 'options', 'collapsible', 'info', 'overlay', 'ContentCardBodyClassname', 'customBodyContent', 'width', 'equalHeight', 'collapsed', 'onInfoClick'])} 
            className={`module-container ${props.className ?? ''} ${props.width ? PlattixColumnWidthTypeMap[props.width] : ''} ${props.equalHeight ? 'equalHeight' : ''}`}
        >
            <ContentCardHeader
                Title={props.header}
                Collapsible={props.collapsible}
                options={props.options}
                info={props.info}
                onInfoClick={props.onInfoClick}
                collapsedState={collapsedState}
                closeButton={props.closeButton}
            />
            
            {!collapsed && 
                <>
                    <ContentCardBody className={props.ContentCardBodyClassname ? props.ContentCardBodyClassname : 'module-block'}>
                    {props.customBodyContent ? props.children :
                        <ContentCardBodyContent>
                            {props.children}
                        </ContentCardBodyContent>
                    }
                    {(isInRole('Developer') && showDeveloperInfo) &&
                        <ContentCardBodyContent>
                            <DeveloperInfo
                                listItems={[]}
                            />
                        </ContentCardBodyContent>
                    }
                    {props.overlay &&
                        <CardOverlay>
                            {props.overlay}
                        </CardOverlay>
                    }

                    </ContentCardBody>
                </>
            }
        </div>
    )
}

export function ContentCard(props: React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>) {
    return (
        <div {...filterContentCardProps(props)} className={`module-container ${props.className ?? ''}`}>
            {props.children}
        </div>
    );
}

export type PlattixCardInfo = {
    header: string,
    body: string | JSX.Element
}

export interface ContentCardHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
    Title: string,
    Collapsible?: boolean,
    options?: CardOptions[],
    closeButton?: CloseCardType,
    OptionsState?: boolean,
    OptionsOnClick?: (e: any) => void,
    info?: PlattixCardInfo,
    onInfoClick? : () => void,
    collapsedState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

export function ContentCardHeader(props: React.PropsWithChildren<ContentCardHeaderProps>) {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [collapsed, setCollapsed] = props.collapsedState;
    
    const options = props.options?.filter(o =>
        (o.permission &&
            hasPermission(
                o.permissionEntity === "Platform"
                    ? null
                    : o.permissionEntity,
                o.permission,
                o.permissionAction
            )) || !o.permission) ?? [];

    const showOptions = options.length > 0;

    const open = !!anchorEl;

    const toggleOptions = (e) => {
        e.stopPropagation();
        setAnchorEl(anchorEl ? null : e.currentTarget);
    }

    const clickAwayHandler = () => {
        setAnchorEl(null);
    };
    
    const onInfoClick = () => {
        if (!props.onInfoClick) setModalIsOpen(true)
        else props.onInfoClick();
    }
    
    return (
        <>
            <div 
                className="module-header"
                style={{
                    borderBottom: collapsed ? 'none' : undefined,
                }}
            >
                {props.Collapsible &&
                    // <span className="module-colapse" onClick={(e) => moduleColapse(e.currentTarget)}>
                    <span className="module-colapse" onClick={(e) => setCollapsed(!collapsed)}>
                        <FontAwesomeIcon icon={!collapsed ? faCaretDown : faCaretRight} className='module-icon' />
                    </span>
                }
                <h5>{props.Title}</h5>
                {props.info &&
                    <div className="module-info">
                        <span 
                            className="module-info-button" 
                            onClick={onInfoClick} 
                            // title={t('ShowInfo')}
                        >
                            <FontAwesomeIcon icon={faQuestionCircle} className='module-icon'/>
                        </span>
                    </div>
                }
                {!!props.closeButton &&
                    <span className="module-options" onClick={props.closeButton.onClick}>
                        <FontAwesomeIcon icon={faTimes} className='module-icon'/>
                    </span>
                }
                {!props.closeButton && !!options?.length && showOptions &&
                    <span className="module-options" onClick={toggleOptions}>
                        <FontAwesomeIcon icon={faEllipsisH} className='module-icon'/>
                    </span>
                }
            </div>
            {props.info &&
                <Modal
                    show={modalIsOpen}
                    onClose={() => setModalIsOpen(false)}
                    title={props.info.header}
                    showConfirmButton={false}
                    // cancelButtonText={t('Close')}
                >
                    {props.info.body}
                </Modal>
            }
            {props.options &&
                <ClickAwayListener onClickAway={clickAwayHandler}>
                    <Popper
                        // id={props.opened ? 'simple-popper' : undefined}
                        open={open}
                        anchorEl={anchorEl}
                        placement="bottom-end"
                        disablePortal={false}
                        // onResize={() => console.log('onResize')}
                        // onResizeCapture={() => console.log('onResizeCapture')}
                        nonce={undefined}
                    >
                        <div {...filterProps(props, ['anchorEl', 'opened', 'clickedAway', 'Collapsible', 'Title', 'onInfoClick', 'collapsedState', 'closeButton', 'onResize', 'onResizeCapture'])} 
                             className={`module-options-dropdown ${props.className ?? ''} ${open && 'module-options-active'}`}>
                            <ul>
                                {options?.map(o => {
                                    if (o.permission) {
                                        return <RequirePermission
                                            key={o.label} permission={o.permission} action={o.permissionAction} entity={o.permissionEntity}>
                                            <ContentCardOption
                                                key={o.label}
                                                description={o.label}
                                                onClick={o.onClick}
                                                link={o.link}
                                                id={o.id}
                                                type={o.type}
                                            />
                                        </RequirePermission>
                                    }

                                    return <ContentCardOption
                                        key={o.label}
                                        description={o.label}
                                        onClick={o.onClick}
                                        link={o.link}
                                        id={o.id}
                                        type={o.type}
                                    />
                                })}
                            </ul>
                        </div>
                    </Popper>
                </ClickAwayListener>
            }
        </>
    );
}

export interface ContentCardBodyProps extends React.HTMLAttributes<HTMLDivElement> {
}

export function ContentCardBody(props: React.PropsWithChildren<ContentCardBodyProps>) {
    return (
        <div {...filterContentCardProps(props)} className={`module-content ${props.className ?? ''}`}>
            {props.children}
        </div>
    );
}

export interface ContentCardBodyContentProps extends React.HTMLAttributes<HTMLDivElement> {
}

export function ContentCardBodyContent(props: React.PropsWithChildren<ContentCardBodyContentProps>) {
    return (
        <div {...filterContentCardProps(props)} className={`tab-content ${props.className ?? ''}`}>
            {props.children}
        </div>
    );
}

export interface ContentCardBodyContentSidebarProps extends React.HTMLAttributes<HTMLDivElement> {
}

export function ContentCardBodyContentSidebar(props: React.PropsWithChildren<ContentCardBodyContentSidebarProps>) {
    return (
        <div {...filterContentCardProps(props)} className={`module-netwerk-sidebar ${props.className ?? ''}`}
             onMouseMove={(e) => sideBarHover(e.currentTarget)}
             onMouseLeave={(e) => sideBarHoverOut(e.currentTarget)}>
            <ul>
                {/*@{await Html.RenderPartialAsync("Partials/_ServicePointAsset_TabNavPills", Model); }*/}
                {props.children}
            </ul>
        </div>
    );
}

export interface ContentCardBodyContentSidebarItemProps extends React.HTMLAttributes<HTMLDivElement> {
    Title: string,
    TabName: string,
    Selected?: boolean,
    Icon: IconName | [IconPrefix, IconName] | IconLookup,
    Show?: boolean
}

export function ContentCardBodyContentSidebarItem(props: React.PropsWithChildren<ContentCardBodyContentSidebarItemProps>) {
    if (!props.Show) return <></>

    return (
        <li className="nav-item nav-netwerk-item">
            <div className={`nav-link active text-uppercase ${!!props.Selected ? 'active' : ''}`}
               id={`${props.TabName}-tab`}
               data-toggle="tab"
               // href="#"
               role="tab"
               aria-controls={props.TabName}
               aria-selected={props.Selected || false}
               // onClick={(e) => netwerkTab(e.currentTarget)}
               onClick={props.onClick}
            >
                <FontAwesomeIcon icon={props.Icon} className='module-netwerk-icon'/>
                <span id="module-netwerk-sidebar-text" className="module-netwerk-sidebar-text">{props.Title}</span>
            </div>
        </li>
    );
}

ContentCardBodyContentSidebarItem.defaultProps = {
    Show: true
}

export interface ContentCardBodyContentSidebarItemContentProps extends React.HTMLAttributes<HTMLDivElement> {
    TabName: string,
    SequenceNumber: number,
    Selected?: boolean,
    Show?: boolean
}

export function ContentCardBodyContentSidebarItemContent(props: React.PropsWithChildren<ContentCardBodyContentSidebarItemContentProps>) {
    if (!props.Show || !props.Selected) return <></>

    return (
        // <div className={`tab-pane fade ${!!props.Selected ? 'show active' : ''}`}
        <div className={`tab-pane fade show active`}
             id={`${props.TabName}_${props.SequenceNumber}`} role="tabpanel" aria-labelledby={`${props.TabName}-tab`}>
            {props.children}
        </div>
    );
}

ContentCardBodyContentSidebarItemContent.defaultProps = {
    Show: true
}

export type paddingTypes = 'top' | 'right' | 'bottom' | 'left' | 'all' | 'none' ;

export interface ContentCardButtonsProps extends React.HTMLAttributes<HTMLDivElement> {
    padding?: paddingTypes[];
}

export function ContentCardButtons(props: React.PropsWithChildren<ContentCardButtonsProps>) {
    return (
        <div 
            {...filterContentCardProps(props)} 
            className={`
                module-content-tab-btns 
                ${props.className ?? ''} 
                ${props.padding ? props.padding.toString().replace(',', ' ') : 'top'}
            `}
        >
            {props.children}
        </div>
    );
}

export interface ContentCardOptionProps extends React.HTMLAttributes<HTMLDivElement> {
    description: string,
    link?: string,
    type?: ButtonActionType;
}

export function ContentCardOption(props: React.PropsWithChildren<ContentCardOptionProps>) {
    return (
        <li>
            {props.link ?
                <Link {...filterContentCardProps(props)} to={props.link}>
                    <ContentCardOptionContainer>
                        <ButtonActionIcon type={props.type ?? 'link'} />
                        {props.description}
                    </ContentCardOptionContainer>
                </Link>
                :
                <span {...filterContentCardProps(props)}>
                    <ContentCardOptionContainer>
                        <ButtonActionIcon type={props.type} />
                        {props.description}
                    </ContentCardOptionContainer>
                </span>
            }
        </li>
    );
}

export type PlattixShowcaseCardBtnType = {
    title: string,
    onClick?: () => void,
    url?: string,
}

export type RegularImageType = {
    imgType?: 'url',
    img: string,
}

export type ElementImageType = {
    imgType?: 'element',
    img: JSX.Element,
}

export interface ThumborImageType extends PlattixImageProps {
    imgType: 'thumbor',
}

export type PlattixShowcaseCardProps = {
    title?: string;
    link?: string;
    onClick?: () => void;
    // img: string;
    imgFit?: ShowcaseCardHeaderProps['imgFit'];
    imgHeight?: string;
    // imgType?: 'thumbor' | 'url';
    fillHeight?: ShowcaseCardProps['fillHeight'];
    logo?: string;
    description?: string | JSX.Element;
    btn?: PlattixShowcaseCardBtnType[];
    className?: string;
    collapsibleChildren?: boolean;
    collapsed?: boolean;
} & (
    | RegularImageType
    | ThumborImageType
    | ElementImageType
)

export function PlattixShowcaseCard(props: PropsWithChildren<PlattixShowcaseCardProps>) {
    const [collapsed, setCollapsed] = useState<boolean>(!!props.collapsibleChildren && !!props.collapsed);
    const history = useHistory();

    const urlHandler = (e, url) => {
        console.log('url', url, e)
        e.stopPropagation();
        window.location.assign(url)
        // history.push(url);
    }
    
    const imgTypeMap = () => {
        if (props.imgType === 'thumbor') return (
            <PlattixImage
                width={props.width}
                height={props.height}
                fileKey={props.fileKey}
                fileSource={props.fileSource}
                server={props.server}
            />
        );
        
        if (props.imgType === 'element') return props.img;
        
        return (<img src={props.img as string} alt={props.title} />);
    }

    return (<>
        <ShowcaseCard className={`module-container ${props.className ?? ''}`} fillHeight={props.fillHeight}>
            <ShowcaseCardHeader
                imgFit={props.imgFit}
                imgHeight={props.imgHeight}
            >
                {props.logo &&
                    <img src={props.logo} alt={props.title} className={'logo'}/>
                }
                <PlattixLink link={props.link} onClick={props.onClick} >
                    {imgTypeMap()}
                </PlattixLink>
            </ShowcaseCardHeader>
            <ShowcaseCardBody>
                {props.title &&
                    <PlattixLink link={props.link} onClick={props.onClick}>
                        <h5>{props.title}</h5>
                    </PlattixLink>
                }

                {props.description && (typeof props.description === 'string') ? <p>{props.description}</p> : props.description}

                {!!props.collapsibleChildren &&
                    <ShowCaseCardCollapsibleButtonContainer onClick={() => setCollapsed(!collapsed)}>
                        <p>{collapsed ? t('Open') : t('Close')}</p>
                        <ShowCaseCardCollapsibleButton>
                            <FontAwesomeIcon icon={collapsed ? faCaretLeft : faCaretDown} />
                        </ShowCaseCardCollapsibleButton>
                    </ShowCaseCardCollapsibleButtonContainer>
                }
                
                {(!!props.children && !collapsed) &&
                    <>
                        {(props.title || props.description) &&
                            // <ShowcaseCardSpacer/>
                            <hr />
                        }
                        {props.children}
                    </>
                }
                
                {props.btn?.length &&
                    <ContentCardButtons className={ShowcaseCardButtons}>
                        {props.btn.map(btn => {
                            if (btn.onClick)
                                return <ConfirmButton key={btn.title} onClick={btn.onClick}>{btn.title}</ConfirmButton>
                            return <ConfirmButton link={btn.url} key={btn.title}>{btn.title}</ConfirmButton>
                        })}
                    </ContentCardButtons>
                }
            </ShowcaseCardBody>

        </ShowcaseCard>
    </>);
}

export interface PlattixShowcaseCardDataListItemList {
    // [key: string]: string | number | null;
    title: string;
    description?: string | number | null;
    id: string;
}

// export interface TotalOverviewTypeList {
//    
// }

export interface TotalOverviewType {
    title: string;
    description?: string | number | Date | null | TotalOverviewType[];
    id?: string;
    prefix?: string;
    suffix?: string;
    url?: string;
    urlOpenInNewTab?: boolean;
}

export interface TransactionTotalOverviewDetailProps {
    type?: string;
    data?: TotalOverviewType[];
}

export function TransactionTotalOverviewDetail(props: TransactionTotalOverviewDetailProps) {
    const [showDescriptionObject, setShowDescriptionObject] = useImmer<{[key: string]: boolean}>({});
    const type = props.type ?? 'default';
    const getId = (data) => data.id.toLowerCase().replace(' ', '-') ?? 'default';
    
    if (!props.data) return <></>;
    
    const isDescriptionList = (data: TotalOverviewType) => typeof data.description === 'object';
    
    const description = (data) => {
        const prefix = data.prefix ? `${data.prefix} ` : ``;
        const suffix = data.suffix ? ` ${data.suffix}` : ``;
        const descriptionValue = !!data.description ? `${prefix}${data.description}${suffix}` : emptyDataValue;

        if (!!data.url) return <a href={data.url} target={data.urlOpenInNewTab ? '_blank' : '_self'}>{descriptionValue}</a>;
        if (isDescriptionList(data)) return <FontAwesomeIcon icon={!!showDescriptionObject?.[getId(data)] ? faCaretDown : faCaretLeft} />;
        
        return descriptionValue;
    };
    
    const showDescriptionObjectHandler = (data) => {
        setShowDescriptionObject(draft => {
            draft[getId(data)] = !draft[getId(data)];
        });
    };
    
    return (
        <>
            {props.data.map((data, index) => {
                return (
                    <TransactionTotalOverviewDetailDescriptionContainer
                        key={`${type}_${data.title}`}
                        show={(isDescriptionList(data) && !!showDescriptionObject?.[getId(data)]) ? 'show' : undefined}
                        childrenamount={props.data?.length}
                    >
                        <dl 
                            onClick={isDescriptionList(data) ? (() => showDescriptionObjectHandler(data)) : undefined}
                            className={isDescriptionList(data) ? openableTransactionTotalOverviewDetailStyling : undefined}
                        >
                            <dt>{data.title}</dt>
                            <dd>{description(data)}</dd>
                        </dl>
                        {(isDescriptionList(data) && !!showDescriptionObject?.[getId(data)]) &&
                            <TransactionTotalOverviewDetailDescriptionValuesContainer>
                                {/*<hr/>*/}
                                {/*<TransactionTotalOverviewDetailDescriptionContainer>*/}
                                    <TransactionTotalOverviewDetail type={data.title} data={data.description as TotalOverviewType[]} />
                                {/*</TransactionTotalOverviewDetailDescriptionContainer>*/}
                            </TransactionTotalOverviewDetailDescriptionValuesContainer>
                        }
                    </TransactionTotalOverviewDetailDescriptionContainer>
                )
            })}
        </>
    );
}

export interface TransactionTotalOverviewDetailDescriptionContainer {
    childrenamount?: number;
    show?: string;
}

export const TransactionTotalOverviewDetailDescriptionContainer = styled('div', React.forwardRef)((props: TransactionTotalOverviewDetailDescriptionContainer) => {
    const childrenAmount = props.childrenamount ?? 0;
    
    const showStyle = !!props.show ? `
        border: 5px solid var(--backgroundColor2);
        border-radius: 10px;
    ` : '';

    const multipleChildrenStyling = (childrenAmount < 3) ? '' : `
        &:nth-child(2n+2) {
            background: var(--backgroundColor2);
        }
    `;

    return `
        width: 100%;
        border-radius: 7px;
        overflow: hidden;
        
        // ${multipleChildrenStyling}
        ${showStyle}
    `;
});

export const TransactionTotalOverviewDetailContainer = styled('div', React.forwardRef)(() => {
    return `
        display: flex;
        flex-flow: row wrap;
        gap: 5px;
        line-break: anywhere;
            
        & > *:nth-of-type(2n+1) {
            background: var(--backgroundColor2);
        }
        
        dl {
            display: flex;
            gap: 10px;
            padding: var(--padding4);
            border-radius: 10px;
            width: 100%;
        }
    `;
});

export const TransactionTotalOverviewDetailDescriptionValuesContainer = styled('div', React.forwardRef)(() => {
    return `
        padding: 10px;
    `;
});

export const openableTransactionTotalOverviewDetailStyling = css`
    user-select: none;
    cursor: pointer;
    background: var(--backgroundColor2);
    padding: 5px 10px;
`;

export interface PlattixShowcaseCardDataListItem {
     title: string;
     // list: PlattixShowcaseCardDataListItemList[];
     list: TotalOverviewType[];
     id: string;
}

export interface PlattixShowcaseCardDataListProps {
    listItems: PlattixShowcaseCardDataListItem[];
}

export function PlattixShowcaseCardDataList(props: PlattixShowcaseCardDataListProps) {
    return (
        <>
            {props.listItems.map((listItem, index) => {
                return (
                    <PlattixShowcaseCardDataListContainer key={listItem.id}>
                        <h5>{listItem.title}</h5>
                        {/*{listItem.list.map((item, i) => {*/}
                        {/*    // console.log(item)*/}
                        {/*    return (*/}
                        {/*        <dl key={item.id}>*/}
                        {/*            <dt>{item.title}</dt>*/}
                        {/*            <dd>{item.description}</dd>*/}
                        {/*        </dl>*/}
                        {/*    )*/}
                        {/*    return (*/}
                        {/*        <></>*/}
                        {/*    )*/}
                        {/*})}*/}

                        <TransactionTotalOverviewDetail
                            type={'costs'}
                            data={listItem.list}
                        />
                    </PlattixShowcaseCardDataListContainer>
                )
            })}
        </>
    );
}

export interface PlattixShowcaseCardGridProps {
    
}


// Todo Grid aanmaken
export function PlattixShowcaseCardGrid(props: PropsWithChildren<PlattixShowcaseCardGridProps>) {
    return (
        <PlattixShowcaseCardGridContainer>
            {props.children}
        </PlattixShowcaseCardGridContainer>
    );
}

export interface PlattixEmptyCardProps extends React.HTMLAttributes<HTMLDivElement> {
    // fullWidth?: boolean,
    width?: PlattixColumnWidthType,
}

export function PlattixEmptyCard(props: PropsWithChildren<PlattixEmptyCardProps>) {
    return (
        // <div {...filterProps(props, ['fullWidth'])} className={`module-container ${props.fullWidth ? 'content-column-1_-1' : ''}`}>
        <div {...filterProps(props, ['width'])} className={`${props.className} module-container ${props.width ? PlattixColumnWidthTypeMap[props.width] : ''}`}>
            {props.children}
        </div>
    )
}

export type PlattixColumnWidthType = 'full' | 'span1' | 'span2' | 'span3' | 'span4' | 'span5' | 'single' | 'double' | 'full-startSecondColumn';

export const PlattixColumnWidthTypeMap = {
    'full': 'content-column-1_-1',
    'span1': 'content-column-span1',
    'span2': 'content-column-span2',
    'span3': 'content-column-span3',
    'span4': 'content-column-span4',
    'span5': 'content-column-span5',
    'single': 'content-column-1_2',
    'double': 'content-column-1_3',
    'full-startSecondColumn': 'content-column-2_-1',
};

export type BackgroundColorType = 'green' | 'blue' | 'red' | 'yellow';

export enum BackgroundColorMap {
    green = 'var(--styleColor1)',
    blue = 'var(--styleColor2)',
    red = 'var(--buttonColorRed)',
    yellow = 'var(--styleColor3)',
}

export interface ReadMoreTextProps {
    // description: string,
    rows?: number;
}

export function ReadMoreText(props: PropsWithChildren<ReadMoreTextProps>) {
    return (
        <TextEllipsis {...props}>
            {props.children}
        </TextEllipsis>
    );
}

export const TextEllipsis = styled('p')((props: ReadMoreTextProps) => {
    return `
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: ${props.rows ?? 2};
               line-clamp: ${props.rows ?? 2};
        -webkit-box-orient: vertical;
    `;
});

export interface ColoredTextAreaProps {
    background?: BackgroundColorType,
    color?: string,
}

export const ColoredTextArea = styled('div')((props: ColoredTextAreaProps) => {
    const backgroundColor = props.background ? `background: ${BackgroundColorMap[props.background]};` : '';
    const textColor = props.color ? `color: ${props.color};` : '';
    
    return `
        width: 100%;
        padding: var(--padding1);
        text-align: center;
        border-radius: 10px;
        margin-bottom: var(--marginBottom3);
        display: flex;
        flex-flow: column nowrap;
        gap: 5px;
        
        * {
            margin: 0;
            padding: 0;
            width: 100%;
        }
        
        &, * {
            ${textColor}
        }
        
        ${backgroundColor}
    `;
});

export type PlattixCardTextTextType = {
    title?: string,
    description?: string | JSX.Element,
};

export interface PlattixCardTextContainerProps {
    direction?: 'row' | 'column',
    centered?: boolean | string,
}
export interface PlattixCardTextProps {
    text: PlattixCardTextTextType[] | string,
    textStyle?: PlattixCardTextContainerProps,
    hideLine?: boolean,
}

export function PlattixCardText(props: PlattixCardTextProps) {
    if (props.text === null || props.text === undefined || !props.text.length) return <></>;

    const textStyleProps = props.textStyle;

    if (typeof props.text === 'string') {
        return <PlattixCardTextContainer {...textStyleProps}>
            <PlattixCardTextItem>
                <p>{props.text}</p>
            </PlattixCardTextItem>
            {!props.hideLine && <hr/>}
        </PlattixCardTextContainer>
    }

    return (
        <PlattixCardTextContainer {...textStyleProps}>
            {
                props.text.map((text, index) => {
                    return (
                        <PlattixCardTextItem key={`PlattixCardTextItem_${index}`} {...textStyleProps}>
                            {text.title && <h5>{text.title}</h5>}
                            {text.description && <p>{text.description}</p>}
                        </PlattixCardTextItem>
                    );
                })
            }
            {!props.hideLine &&
                <hr/>
            }
        </PlattixCardTextContainer>
    )
}

export const PlattixCardTextContainer = styled('div')((props: PlattixCardTextContainerProps) => {
    return `
        width: 100%;
        display: flex;
        flex-flow: ${props.direction ? props.direction : 'column'} nowrap;
        gap: var(--padding2);
        margin-bottom: var(--padding2);
    `;
});

export const PlattixCardTextItem = styled('div')((props: PlattixCardTextContainerProps) => {
    return `
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        gap: 10px;
        justify-content: center;
        
        * {
            width: fit-content;
            text-align: justify;
            ${!!props.centered && 'text-align: center;'}
        }
    `;
});

export interface PlattixCardPageScrollerItemType {
    title: string,
    element: MutableRefObject<null>,
}

export interface PlattixCardPageScrollerProps {
    links: PlattixCardPageScrollerItemType[];
}

export function PlattixCardPageScroller(props: PlattixCardPageScrollerProps) {
    
    const executeScroll = (ref) => ref.current.scrollIntoView({ 
        behavior: 'smooth', 
        block: 'start' 
    });    

    return (
        <PlattixCardPageScrollerContainer>
            {props.links.map((link, index) => {
                return (
                    <PlattixCardPageScrollerItem key={`${link.title}_${index}`} onClick={() => executeScroll(link.element)}>
                        {link.title}
                    </PlattixCardPageScrollerItem>
                );
            })}
        </PlattixCardPageScrollerContainer>
    )
}

export const PlattixCardPageScrollerContainer = styled('div')(() => {
    return `
        width: fit-content;
        position: fixed;
        top: 10px;
        right: 20px;
        background: var(--backgroundColor1);
        box-shadow: var(--shadow2);
        padding: 10px;
        border-radius: 10px;
        display: flex;
        gap: 20px;
    `;
});

export const PlattixCardPageScrollerItem = styled('div')(() => {
    return `
        width: 100%;
        cursor: pointer;
        
        &:hover {
            font-weight: 700;
        }
    `;
});

export interface PlattixListDetailsType {
    title: string,
    description?: string | number | JSX.Element | null,
    id?: string,
}

export interface PlattixListProps {
    title?: string,
    description?: string,
    list: PlattixListDetailsType[],
}

export function PlattixList(props: PlattixListProps) {
    return (
        <PlattixListDetails>
            {(props.title || props.description) &&
                <>
                    <div className={'detailsHeader'}>
                        {props.title && <h5>{props.title}</h5>}
                        {props.description && <p>{props.description}</p>}
                    </div>

                    <hr />
                </>
            }
            <dl>
                {props.list.map((l, index) => {
                    return (
                        <div className={'row'} key={`${l.title}_${index}`}>
                            <dt>{l.title}</dt>
                            <dd>{!!l.description ? l.description : '--'}</dd>
                        </div>
                    )
                })}
            </dl>

        </PlattixListDetails>
    );
}

export const PlattixListDetails = styled('div')(() => {
    return `
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        flex-flow: column nowrap;
        gap: 10px;
        
        .detailsHeader {
            p {
                margin: 0;
            }
        }
        
        hr {
            margin: 0;
        }
        
        dl {
            width: 100%;
            margin: 0;
            padding: 0;
            display: flex;
            flex-flow: column nowrap;
            gap: 5px;
        
            .row {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 10px;
                padding: var(--padding4);
                border-radius: 10px;
                width: 100%;
            
                &:nth-of-type(2n+1) {
                    background: var(--backgroundColor2);
                }
            }
            
            dt, dd {
                width: 100%;
                margin: 0;
                padding: 0;
            }
            
            dt {
                font-weight: 700;
            }
            
            dd {
                text-align: right;
            }
        }
    `;
});

export interface GroupItem {
    id: string,
    title: string,
    description?: string,
    buttons?: JSX.Element,
    elements: JSX.Element,
    collapsible?: boolean,
    hidden?: boolean,
    rendered?: boolean,
}

interface PlattixSectionGroupProps {
    items: GroupItem[];
    anonymous?: boolean;
    disableNavigation?: boolean;
}

export function PlattixSections(props: PlattixSectionGroupProps) {
    const [focusedElement, setFocusedElement] = useState(null);
    let refs = useRef<RefObject<HTMLDivElement>[]>(props.items.map(() => createRef()));
    const focusModeState = useState<boolean>(false);
    const [focusMode, setFocusMode] = focusModeState;
    const [openAllSections, setOpenAllSections] = useState<boolean>(true);

    const handleComponentFocus = (ref) => {
        setFocusedElement(ref);
    }

    const executeScroll = (index) => {
        if (props.disableNavigation) return;
        refs.current[index].current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        });

        handleComponentFocus(refs.current[index])
    }
    
    return (
        <PlattixSectionContainer>
            {!props.disableNavigation &&
                <ResponsiveComponent devices={['desktop']}>
                    <PlattixSectionMenu anonymous={props.anonymous ? 'anonymous' : ''}>
                        {props.items.filter(it => it.hidden !== false).map((item, index) => {
                            return (
                                <PlattixSectionMenuItem
                                    key={`${item.id}_${index}`}
                                    onClick={() => executeScroll(index)}
                                    focused={refs.current[index] === focusedElement ? 'focused' : ''}
                                >
                                    <PlattixSectionMenuItemText>
                                        {item.title}
                                    </PlattixSectionMenuItemText>
                                </PlattixSectionMenuItem>
                            )
                        })
                        }
                        <div className={'alignRight'}></div>
                        <PlattixTooltip title={focusMode ? t('PlattixSections.FocusMode.Toggle.Off') : t('PlattixSections.FocusMode.Toggle.On')}>
                            <ViewModeOption
                                active={'true'}
                                onClick={() => setFocusMode(!focusMode)}
                            >
                                <FontAwesomeIcon icon={faArrowsToEye}/>
                            </ViewModeOption>
                        </PlattixTooltip>
                        <PlattixTooltip title={t('PlattixSections.OpenAll.Tooltip')}>
                            <ViewModeOption
                                active={'true'}
                                onClick={() => setOpenAllSections(!openAllSections)}
                            >
                                <FontAwesomeIcon icon={openAllSections ? faSquareMinus : faSquarePlus}/>
                            </ViewModeOption>
                        </PlattixTooltip>
                    </PlattixSectionMenu>
                </ResponsiveComponent>
            }
            
            {props.items.map((item, index) => {
                if (item.hidden && !item.rendered) return <></>;
                return (
                    <PlattixSection 
                        key={`${item.id}_${index}`} 
                        innerRef={refs.current[index]} 
                        item={item} 
                        onClick={() => handleComponentFocus(refs.current[index])} 
                        focused={!props.disableNavigation ? refs.current[index] === focusedElement : false}
                        hiddenButRendered={item.hidden && item.rendered}
                        focusModeState={focusModeState}
                        openAll={openAllSections}
                    />
                );
                })
            }
            
        </PlattixSectionContainer>
    );
}

interface PlattixSectionProps extends React.HTMLAttributes<HTMLDivElement> {
    innerRef: React.LegacyRef<HTMLDivElement> | undefined;
    item: GroupItem;
    focused: boolean;
    hiddenButRendered?: boolean;
    focusModeState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    openAll: boolean; 
}

export function PlattixSection(props: PlattixSectionProps) {
    const [collapsed, setCollapsed] = useState(!props.openAll);
    const [focusMode, setFocusMode] = props.focusModeState;

    useEffect(() => {
        return setCollapsed(!props.openAll);
    }, [props.openAll]);

    const handleCollapse = () => {
        if (!props.item.collapsible) return;
        setCollapsed(!collapsed);
    };
    
    return (
        <PlattixSectionStyling
            ref={props.innerRef}
            open={!collapsed ? 'open' : ''}
            focused={props.focused ? 'focused' : ''}
            onClick={props.onClick}
            hiddenbutrendered={props.hiddenButRendered ? 'true' : undefined}
            focusmode={focusMode ? 'focusmode': undefined}
        >
            <PlattixSectionHeader>
                {props.item.collapsible &&
                    <PlattixSectionHeaderCollapsible onClick={handleCollapse}>
                        <FontAwesomeIcon icon={collapsed ? faCaretRight : faCaretDown} className='module-icon' />
                    </PlattixSectionHeaderCollapsible>
                }
                <PlattixSectionHeaderHeadline>
                    <PlattixSectionHeaderText onClick={handleCollapse}>
                        <h5>{props.item.title}</h5>
                        { props.item.description && <p>{props.item.description}</p> }
                    </PlattixSectionHeaderText>
                    {props.item.buttons &&
                        <ContentCardButtons padding={['none']} className={PlattixSectionHeaderButtonsAlignment}>
                            {props.item.buttons}
                        </ContentCardButtons>
                    }
                </PlattixSectionHeaderHeadline>
            </PlattixSectionHeader>
            {!collapsed &&
                <>
                    <hr />
                    {props.item.elements}
                </>
            }
        </PlattixSectionStyling>
    );
}

export const PlattixSectionStyling = styled('div', forwardRef)((props: PlattixSectionStylingProps) => {
    
    const notFocusedStyling = (!props.focused && !!props.focusmode) ? `
        filter: blur(1px);
        opacity: 0.7;
    ` : '';
    
    return `
        width: 100%;
        grid-column: 1/-1;
        border: 5px solid ${!!props.focused ? 'var(--styleColor1)' : '#EEE'};
        border-radius: 20px;
        padding: 10px;
        display: ${!!props.hiddenbutrendered ? 'none' : 'flex'};
        flex-flow: column nowrap;
        gap: 15px;
        margin: ${!!props.open ? '15px 0' : '0'};
        
        ${notFocusedStyling}
    `;
});

export interface PlattixFormErrorProps {
    // title?: string | string[];
    // description? string;
}

export function PlattixFormError(props: PropsWithChildren<PlattixFormErrorProps>) {
    return (
        <PlattixFormErrorContainer>
            {/*{props.title &&*/}
            {/*    <p>props.title</p>*/}
            {/*}*/}
            {props.children}
        </PlattixFormErrorContainer>
    );
}

export const PlattixFormErrorContainer = styled('div')(() => {
    return `
        width: 100%;
        border-left: 5px solid var(--buttonColorRed);
        margin: var(--marginBottom3) 0;
        padding: var(--padding2);
        border-radius: 0 10px 10px 0;
        background: #f5f5f5;
        
        hr {
            margin: var(--padding2) 0 0 0;
        }
    `;
});

// export const PlattixFocusBackDropContainer = styled('div')(() => {
//     return `
//         background: #333;
//         opacity: 0.7;
//         position: fixed;
//         top: 0;
//         left: 0;
//         width: 100vw;
//         height: 100vh;
//         z-index: 200;
//     `;
// });
//
// export interface PlattixFocusBackDropProps {
//     title: string;
//     description: string;
//     target: JSX.Element;
//     show: boolean;
// }
//
// export function PlattixFocusBackDrop(props: PropsWithChildren<PlattixFocusBackDropProps>) {
//
//     if (!props.show) return <>{props.children}</>;
//    
//     return (
//         <PlattixFocusBackDropContainer>
//             {/*{props.children}*/}
//             {props.target}
//         </PlattixFocusBackDropContainer>
//     );
// }

export interface PlattixPopperProps extends BasePopperProps {
    clickAwayHandler: () => void;
    // arrow?: boolean;
}

export function PlattixPopper(props: PropsWithChildren<PlattixPopperProps>) {
    // const [anchorEl, setAnchorEl] = useState(props.anchorEl);

    // const open = !!anchorEl;

    // const toggleOptions = (e) => {
    //     e.stopPropagation();
    //     setAnchorEl(anchorEl ? null : e.currentTarget);
    // }
    
    // const clickAwayHandler = () => {
    //     if (!props.clickAwayHandler) return;
    //     setAnchorEl(null);
    // };
    
    return (
        <>
            <ClickAwayListener onClickAway={props.clickAwayHandler}>
                <Popper
                    open={props.open}
                    anchorEl={props.anchorEl}
                    placement={props.placement}
                    disablePortal={props.disablePortal}
                    modifiers={[
                        // {
                        //     name: 'arrow',
                        //     enabled: props.arrow,
                        //     options: {
                        //         // element: arrowRef,
                        //         element: faCaretUp,
                        //     },
                        // },
                    ]}
                    onResize={() => console.log('onResize')}
                    onResizeCapture={() => console.log('onResizeCapture')}
                    nonce={undefined}
                >
                    <ShowcaseCard className={'module-container'} fillHeight={true}>
                        <ShowcaseCardBody>
                            {props.children}
                        </ShowcaseCardBody>
                    </ShowcaseCard>
                </Popper>
            </ClickAwayListener>
            
            
        </>
    );
}

/**
 * Hook dat gebruikt kan worden om de focus op een element te leggen.
 * Gebruik door "const [inputRef, setInputFocus] = useFocus();" te definieren in de component.
 * Plaats een referentie op een element ("ref={inputRef}") en gebruik daarna de "setInputFocus" functie om de focus te leggen aan de gekoppelde referentie.
 * */
export const useFocus = () => {
    const htmlElRef = useRef<any | null>(null)
    const setFocus = () => {
        htmlElRef.current &&  htmlElRef.current?.focus();
    }

    return [ htmlElRef, setFocus ]
}

// export interface PlattixTabs {
//     title: string;
//     content: React.ReactNode;
//     type: 'content' | 'button';
// }

export type PlattixTabsContentType = {
    type: 'content';
    content: React.ReactNode;
};

export type PlattixTabsButtonType = {
    type: 'button';
    color?: string;
    clickEvent: () => void;
};

export type PlattixTabsType = {
    title: string;
    hide?: boolean;
} & (
    | PlattixTabsContentType
    | PlattixTabsButtonType
);

export interface PlattixTabsProps {
    tabs: PlattixTabsType[];
    tabindexState?: [number, React.Dispatch<React.SetStateAction<number>>];
}

export function PlattixTabs(props: PlattixTabsProps) {
    const tabIndexState = useState(0);
    const [tabIndex, setTabIndex] = props.tabindexState ?? tabIndexState;

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };
    
    return (
        <>
            <Tabs
                value={tabIndex}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                className={plattixTabPanel}
            >
                {props.tabs.map((tab, index) => {
                    const onClickHandler = () => {
                        if (tab.type !== 'button') return;
                        tab.clickEvent();
                    };
                    
                    if (!!tab.hide) return (
                        <Fragment key={`Tab_${tab.title}_${index}`}></Fragment>
                    );
                    
                    return (
                        <Tab 
                            key={`Tab_${tab.title}_${index}`}
                            label={tab.title} 
                            onClick={onClickHandler}
                            className={`${plattixTabStyling} ${tab.type === 'button' ? tab.color : ''}`}
                        />
                    )
                })}
            </Tabs>

            {props.tabs.map((tab, index) => {
                return (
                    <PlattixTabPanel
                        key={`Tabpanel_${tab.title}_${index}`}
                        value={tabIndex}
                        index={index}
                    >
                        {tab.type === 'content' ? tab.content : <></>}
                    </PlattixTabPanel>
                )
            })}
        </>
    );
}

export interface PlattixTabPanelProps {
    value: number;
    index: number;
}

export function PlattixTabPanel(props: PropsWithChildren<PlattixTabPanelProps>) {
    
    if (props.value !== props.index) return <></>;

    return (
        <>
            {props.children}
        </>
    );
}

export const plattixTabPanel = css`
    width: 100%;
    min-height: unset !important;
    height: fit-content;
    grid-column: 1/-1;
    
    .MuiTabs-flexContainer {
        gap: 10px;
    }
    
    .MuiTab-root {
        font-family: unset;
        font-size: unset;
        padding: 7px 15px;
        border-radius: 10px;
        min-height: unset;
        background: #f5f5f5;
        text-transform: none;
        letter-spacing: unset;
        min-width: 40px;
        
        &.Mui-selected {
            background: var(--styleColor1);
            color: #FFF;
        }
    }
    
    .MuiTabs-indicator {
        background-color: unset;
    }
    
    .MuiTabScrollButton-root {
        &.Mui-disabled {
            opacity: 0.3;
        }
    }
`;

export const plattixTabStyling = css`
    &.red {
        background: red;
    }
    
    &.green {
        background: green;
    }
`;

export const PlattixShowcaseCardGridContainer = styled('div', React.forwardRef)(() => {
    return `
        display: grid;
    `;
});

export interface PlattixTooltipProps extends TooltipProps {}

/* *
 * If the child is a custom React element, you need to make sure that it spreads its props to the underlying DOM element. You can do this by making use of React.forwardRef
 * */
export function PlattixTooltip(props: PropsWithChildren<PlattixTooltipProps>) {
    const desktop = useMediaQuery(deviceDimensions.desktop);
    
    if (!desktop)
        return props.children
    
    return (
        <ResponsiveComponent
            devices={['desktop']}
        >
            <Tooltip
                classes={{ 
                    tooltip: TooltipStyling
                }}
                arrow
                TransitionComponent={Zoom}
                followCursor={true}
                disableInteractive
                placement={'top'}
                // describeChild
                {...props}
            />
        </ResponsiveComponent>
    );
}

export const TooltipStyling = css`
    background-color: rgba(0, 0, 0, 0.85) !important;
    
    &, * {
        color: white;
    }
    
    .MuiTooltip-arrow {
        color: rgba(0, 0, 0, 0.7) !important;
    }
`;

export interface PlattixFullScreenCardProps {
    showState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

export function PlattixFullScreenCard(props: PropsWithChildren<PlattixFullScreenCardProps>) {
    const [show, setShow] = props.showState;
    const desktop = useMediaQuery(deviceDimensions.desktop);
    
    if (desktop) return (
        <>
            {props.children}
        </>
    );
    
    return (
        <>
            {show &&
                <PlattixFullScreenCardContainer>
                    <PlattixFullScreenCardHeader>
                        <FontAwesomeIcon icon={faTimes} onClick={() => setShow(false)} className={'closeBtn'} />
                    </PlattixFullScreenCardHeader>
                    {props.children}
                </PlattixFullScreenCardContainer>
            }
        </>
    );
}

export const PlattixFullScreenCardContainer = styled('div', React.forwardRef)(() => {
    return `
        width: 100vw;
        height: 100vh;
        background-color: rgba(255, 255, 255, 0.7);
        backdrop-filter: blur(10px);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
    `;
});

export const PlattixFullScreenCardHeader = styled('div', React.forwardRef)(() => {
    return `
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 25px 5vw;
        
        .closeBtn {
            font-size: 30px;
            margin-left: auto;
            padding-right: 10px;
        }
    `;
});

export interface PlattixInfoCardText {
    title?: string;
    description: string | JSX.Element;
    id: string | number;
    hide?: boolean;
}

export interface PlattixInfoCardProps {
    headline: string;
    width?: PlattixColumnWidthType;
    text?: PlattixInfoCardText[];
    buttons?: JSX.Element;
}

export function PlattixInfoCard(props: PlattixInfoCardProps) {
    return (
        <>
            <PlattixCard
                header={props.headline}
                collapsible={true}
                width={props.width ?? 'span2'}
            >
                {props.text?.map((text, index) => {
                    const description = (typeof text.description === 'string' ? <p>{text.description}</p> : text.description)
                    if (text.hide) return <Fragment key={`text_${text.id}`}></Fragment>;
                    return (
                        <ContentCardBodyContent key={`text_${text.id}`}>
                            {text.title &&
                                <h5>{text.title}</h5>
                            }
                            {description}
                        </ContentCardBodyContent>
                    )
                })}

                {props.buttons &&
                    <ContentCardButtons>
                        {props.buttons}
                    </ContentCardButtons>
                }
            </PlattixCard>
        </>
    );
}

export type PlattixFullImageCardProps = {
    title?: string;
} & (
    | RegularImageType
    | ThumborImageType
)

export function PlattixFullImageCard(props: PlattixFullImageCardProps) {
    return (
        <PlattixEmptyCard>
            <PlattixFullImageContainer>
                {props.imgType === 'thumbor' ?
                    <PlattixImage
                        width={props.width}
                        height={props.height}
                        fileKey={props.fileKey}
                        fileSource={props.fileSource}
                        server={props.server}
                    />
                    :
                    <img src={props.img} alt={props.title} />
                }
            </PlattixFullImageContainer>
        </PlattixEmptyCard>
    );
}

export const PlattixFullImageContainer = styled('div', React.forwardRef)(() => {
    return `
        width: 100%;
        overflow: hidden;
        border-radius: 10px;
        
        img {
            object-fit: contain;
            width: 100%;
        }
    `;
});

export interface DeveloperInfoProps extends PlattixShowcaseCardDataListProps {}

export interface DeveloperInfoState {}

function DevInfo(props: DeveloperInfoProps) {
    const isInRole = useAppSelector(isInRoleSelector);
    const [open, setOpen] = useState<boolean>(false);
    
    if (isInRole('Developer')) return (
        <PlattixCollapsibleContainer>
            <PlattixCollapsibleHeader onClick={() => setOpen(!open)}>
                <FontAwesomeIcon icon={open ? faCaretDown : faCaretRight} />
                <h5>{t('DeveloperInfo.Title')}</h5>
            </PlattixCollapsibleHeader>
            {open &&
                <PlattixCollapsibleBody>
                    <PlattixShowcaseCardDataList 
                        listItems={props.listItems}
                    />
                </PlattixCollapsibleBody>
            }
        </PlattixCollapsibleContainer>
    );
    
    return (<></>);
}

export const showDeveloperInfo = ((process.env.NODE_ENV !== 'production') && (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'));

export class DeveloperInfo extends React.Component<DeveloperInfoProps, DeveloperInfoState> {

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }
    
    render() {
        const reactInternals = this['_reactInternals'];
        const p = {
            ...this.props,
            listItems: [
                ...this.props.listItems,
                {
                    title: t('DeveloperInfo.Meta.Title'),
                    id: 'DeveloperInfo.Meta.Title',
                    list: [
                        {
                            title: t('DeveloperInfo.DebugSource.FilePath.Parent'),
                            id: 'DeveloperInfo.DebugSource.FilePath.Parent',
                            description: `${reactInternals?._debugSource?.fileName ?? '--'} : ${reactInternals?._debugSource?.lineNumber ?? '--'}`,
                        },
                        {
                            title: t('DeveloperInfo.DebugSource.FilePath.Grandparent'),
                            id: 'DeveloperInfo.DebugSource.FilePath.Grandparent',
                            description: `${reactInternals?._debugOwner?._debugSource?.fileName ?? '--'} : ${reactInternals?._debugOwner?._debugSource?.lineNumber ?? '--'}`,
                        },
                    ],
                },
            ]
        };
        
        // return (<></>);
        return (<DevInfo {...p} />);
    }
}

export interface PlattixCollapsibleContainerProps {
    padding?: paddingTypes[];
}

export const PlattixCollapsibleContainer = styled('div', React.forwardRef)((props: PlattixCollapsibleContainerProps) => {
    const baseSpacingValues = 'var(--padding2)';
    /* Aangepast naar 'margin' omdat 'padding' problemen geeft met de border. */
    const spacingType = `margin`;
    const spacingMap: Record<paddingTypes, string> = {
        top: `${spacingType}-top: ${baseSpacingValues};`,
        right: `${spacingType}-right: ${baseSpacingValues};`,
        bottom: `${spacingType}-bottom: ${baseSpacingValues};`,
        left: `${spacingType}-left: ${baseSpacingValues};`,
        all: `${spacingType}: ${baseSpacingValues};`,
        none: `${spacingType}: 0;`,
    };
    
    const spacing = (!props.padding?.length || props.padding?.includes('none')) ? '' : props.padding.map(p => spacingMap[p]).join(' ');
    
    return `
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        border: 5px solid var(--backgroundColor2);
        border-radius: 10px;
        
        ${spacing}
    `;
});

export const PlattixCollapsibleHeader = styled('div', React.forwardRef)(() => {
    return `
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        user-select: none;
        background: var(--backgroundColor2);
        padding: 2px 10px;
    `;
});

export const PlattixCollapsibleBody = styled('div', React.forwardRef)(() => {
    return `
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        gap: 10px;
        padding: 10px;
    `;
});

export interface PlattisCollapsibleProps {
    title?: string;
    open?: boolean;
    padding?: paddingTypes[];
}

export function PlattixCollapsible(props: PropsWithChildren<PlattisCollapsibleProps>) {
    const [open, setOpen] = useState<boolean>((props.open !== undefined) ? props.open : true);
    
    return (
        <PlattixCollapsibleContainer
            // padding={props.padding ? props.padding.toString().replace(',', ' ') : ''}
            padding={props.padding}
        >
            <PlattixCollapsibleHeader onClick={() => setOpen(!open)}>
                <FontAwesomeIcon icon={open ? faCaretDown : faCaretRight} />
                {!!props.title &&
                    <h5>{props.title}</h5>
                }
            </PlattixCollapsibleHeader>
            {open &&
                <PlattixCollapsibleBody>
                    {props.children}
                </PlattixCollapsibleBody>
            }
        </PlattixCollapsibleContainer>
    );
}

export interface PlattixMenuListItemItem {
    title: string;
    id: string | number; 
    onClick: () => void;
    active: boolean;
    addButton?: boolean;
    deleteButton?: boolean;
    onDeleteClick?: () => void;
}

export interface PlattixMenuListItem {
    title: string;
    id: string | number;
    listItems: PlattixMenuListItemItem[];
}

export interface PlattixMenuListProps {
    list: PlattixMenuListItem[];
}

export function PlattixMenuList(props: PlattixMenuListProps) {
    return (
        <List
            className={PlattixMenuListStyling}
        >
            {props.list.map((listItem, index) => {
                return (
                    <li key={listItem.title}>
                        <ul>
                            <ListSubheader>{listItem.title}</ListSubheader>
                            {listItem.listItems.map((liItem, i) => {
                                return (
                                    <ListItem
                                        key={liItem.id}
                                        onClick={liItem.onClick}
                                        className={liItem.active ? 'active' : undefined}
                                    >
                                        <ListItemText primary={liItem.title} />
                                        {!!liItem.addButton && <ListItemSecondaryAction>
                                            <AddIconButton onClick={liItem.onClick}></AddIconButton>
                                        </ListItemSecondaryAction>}
                                        {!!liItem.deleteButton && <ListItemSecondaryAction>
                                            <DeleteIconButton onClick={liItem.onDeleteClick}></DeleteIconButton>
                                        </ListItemSecondaryAction>}
                                    </ListItem>
                                    
                                )
                            })}
                        </ul>
                    </li>
                )
            })}
        </List>
    );
}

export const PlattixMenuListStyling = css`
    max-height: 300px;
    overflow: auto;
    width: 100%;
    padding-top: 0 !important;
    display: flex;
    flex-flow: column nowrap;
    gap: 25px;
    
    &, * {
        color: var(--textColorDark);
    }
    
    ul {
        &, 
        li,
        p,
        .MuiList-root,
        .MuiListSubheader-root,
        .MuiListItem-root,
        .MuiListItemText-root,
        .MuiTypography-root
        {
            padding: 0;
            margin: 0;
            color: var(--textColorDark);
            font-size: 1em;
            font-weight: 300;
        }
        
        .MuiListSubheader-root {
            font-size: 1em;
            font-weight: 700;
            line-height: 2.5em;
        }
        
        .MuiListItem-root {
            cursor: pointer;
            padding: 5px 15px;
            
            &.active {
                background: var(--backgroundColor2);
            }
        }
    }
`;

export interface ViewModeOptionsProps {
    active?: string;
}

export const ViewModeOption = styled('button', React.forwardRef)((props: ViewModeOptionsProps) => {
    return `
        padding: 0;
        margin: 0;
        border: none;
        border-radius: 0;
        opacity: ${props.active ? '1' : '0.3'};
        cursor: pointer;
    `;
});

export const ContentCardOptionContainer = styled('div', React.forwardRef)(() => {
    return `
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        gap: 10px;
    `;
});

export interface PlattixCardDescriptionProps {
    padding?: ('top' | 'right' | 'bottom' | 'left')[];
}

export const PlattixCardDescription = styled('div', React.forwardRef)((props: PlattixCardDescriptionProps) => {
    
    const paddingStyle = () => {
        if (!props.padding?.length) return '';
        let style = ``;
        props.padding.forEach(direction => style += `padding-${direction}: var(--padding2);`);
        return style;
    };
    
    return `
        width: fit-content;
        margin: 0 auto;
        
        display: flex;
        flex-flow: column nowrap;
        gap: 10px;
        
        ${paddingStyle()}
        
        &, p {
            white-space: pre-line;
        }
        
        h5 {
            text-align: left;
        }
    `;
});