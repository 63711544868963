import {t} from "PlattixUI/PlattixReactCore/i18n";
import htmlRaw from "PlattixUI/util/HtmlRaw";
import React, {PropsWithChildren, useEffect, useState} from "react";
import { ContentCardButtons } from "./ContentCard";
import {ButtonType, CancelButton, ConfirmButton} from "PlattixUI/core/components/Buttons";
import {PlattixSubmitButton} from "PlattixUI/core/components/form/Input";
import { styled } from "goober";
import {faArrowLeft, faArrowRight, faCheck, faXmark} from "@fortawesome/free-solid-svg-icons";

export interface PlattixMultiStepStepType {
    id: number | string;
    title: string;
    description?: string;
    content: JSX.Element;
    onNext?: (e?: any) => any;
    onPrevious?: (e?: any) => any;
    nextBtnName?: string;
    previousBtnName?: string;
    cancelBtnName?: string;
    nextBtnType?: ButtonType;
    previousBtnType?: ButtonType;
    cancelBtnType?: ButtonType;
    nextBtnLoading?: boolean;
    previousBtnLoading?: boolean;
    cancelBtnLoading?: boolean;
    nextBtnForm?: string;
}

export interface PlattixMultiStepProps {
    steps: PlattixMultiStepStepType[];
    loading?: boolean,
    onCancel?: () => void,
    onSubmission?: (e?: any) => any,
    submissionBtnType?: ButtonType;
    submissionBtnForm?: string;
    submissionBtnName?: string;
}

export function PlattixMultiStep(props: PlattixMultiStepProps) {
    const currentStepState = useState(1);
    const [currentStep, setCurrentStep] = currentStepState;
    
    const steps = props.steps;

    const countChildren = steps?.length ?? 0;
    
    const next = async () => {
        await steps[currentStep - 1]?.onNext?.();
        await setCurrentStep(currentStep + 1);
    }

    const previous = async () => {
        await steps[currentStep - 1]?.onPrevious?.();
        await setCurrentStep(currentStep - 1);
    }

    async function goToStep(step: number) {
        if (step <= 1) step = 1;
        if (step >= countChildren) step = countChildren;

        // if (step < currentStep) {
        //     setCurrentStep(step)
        //     return;
        // }

        setCurrentStep(step)
    }
    
    return (
        <>
            <MultiStepIndicator 
                currentStep={currentStep}
                steps={steps}
                onStepClick={goToStep}
            />
            
            {props.steps.map((step, index) => {
                return (
                    <PlattixMultiStepStep 
                        key={`PlattixMultiStepStep_${step.title}`} 
                        step={index + 1} 
                        title={step.title}
                        description={step.description}
                        maxSteps={countChildren}
                        currentStep={currentStep}
                        onNext={next}
                        onPrevious={previous}
                        onCancel={props.onCancel}
                        onSubmission={props.onSubmission}
                        loading={props.loading}
                        nextBtnName={step.nextBtnName}
                        previousBtnName={step.previousBtnName}
                        cancelBtnName={step.cancelBtnName}
                        nextBtnType={step.nextBtnType}
                        previousBtnType={step.previousBtnType}
                        cancelBtnType={step.cancelBtnType}
                        nextBtnLoading={step.nextBtnLoading}
                        previousBtnLoading={step.previousBtnLoading}
                        cancelBtnLoading={step.cancelBtnLoading}
                        nextBtnForm={step.nextBtnForm}
                        submissionBtnName={props.submissionBtnName}
                        submissionBtnType={props.submissionBtnType}
                        submissionBtnForm={props.submissionBtnForm}
                    >
                        {step.content}
                    </PlattixMultiStepStep>
                )
            })}
        </>
    );
}

export interface MultiStepIndicatorProps<TModel> {
    currentStep: number,
    steps: Array<Exclude<any, boolean | null | undefined>> | null | undefined,
    onStepClick: (number) => void
}

export function MultiStepIndicator<TModel>(props: MultiStepIndicatorProps<TModel>) {
    const stepProgressState = useState<number>(1);
    const [stepProgress, setStepProgress] = stepProgressState;
    
    useEffect(() => {
        if (props.currentStep > stepProgress) setStepProgress(props.currentStep);
    }, [props.currentStep]);
    
    function getClass(idx: number) {
        if (idx === props.currentStep) return 'active'
        if ((idx > stepProgress) && (idx > props.currentStep)) return 'disabled'
        return ''
    }
    
    function onStepClickHandler(idx: number) {
        if (props.currentStep > stepProgress) setStepProgress(props.currentStep);
        if (idx <= stepProgress) props.onStepClick(idx);
    }
    
    return (
        <StepsContainer>
            <ul>
                {props.steps?.map((step, idx) => (
                    <li 
                        key={idx + 1} 
                        className={getClass(idx + 1)}
                        onClick={() => onStepClickHandler(idx + 1)}
                    >
                        <div className={'step-container'}>
                            <span className="step-bullet"/>
                            <h4 className="step-name">{step.title}</h4>
                        </div>
                    </li>
                ))}
            </ul>
        </StepsContainer>
    );
}

export interface PlattixMultiStepStepProps {
    step: number;
    title?: string;
    description?: string;
    maxSteps?: number;
    currentStep: number;
    errors?: any[];

    onNext?: any;
    nextBtnType?: ButtonType;
    nextBtnName?: string;
    nextBtnLoading?: boolean;
    nextBtnForm?: string;

    onPrevious?: any;
    previousBtnType?: ButtonType;
    previousBtnName?: string;
    previousBtnLoading?: boolean;

    onCancel?: any;
    cancelBtnType?: ButtonType;
    cancelBtnName?: string;
    cancelBtnLoading?: boolean;

    onSubmission?: any;
    submissionBtnType?: ButtonType;
    submissionBtnName?: string;
    submissionBtnForm?: string;
    loading?: boolean;
}

export function PlattixMultiStepStep(props: PropsWithChildren<PlattixMultiStepStepProps>) {
    if (props.step !== props.currentStep) return (<></>);
    
    return (
        <>
            <h4 className="step-title">{props.title}</h4>

            {(!!props.description) &&
                <div className="step-description-container">
                    {props.description}
                </div>
            }

            {props.children}

            <ContentCardButtons>
                {(props.onCancel) &&
                    <CancelButton 
                        className={'next'} 
                        onClick={props.onCancel} 
                        type={props.cancelBtnType} 
                        loading={props.cancelBtnLoading}
                        icon={faXmark}
                    >
                        {props.cancelBtnName ?? t("Cancel")}
                    </CancelButton>
                }

                {((props.currentStep ?? -1) > 1 && (props.currentStep ?? -1) <= (props.maxSteps ?? 0)) &&
                    <ConfirmButton 
                        className={'next'} 
                        onClick={props.onPrevious} 
                        type={props.previousBtnType} 
                        loading={props.previousBtnLoading}
                        icon={faArrowLeft}
                    >
                        {props.previousBtnName ?? t("Previous")}
                    </ConfirmButton>
                }

                {(props.currentStep ?? -1) >= 1 && ((props.currentStep ?? -1) < (props.maxSteps ?? 0)) &&
                    <ConfirmButton 
                        className={'next'} 
                        onClick={props.onNext} 
                        loading={props.loading || props.nextBtnLoading} 
                        type={props.nextBtnType} 
                        form={props.nextBtnForm}
                        icon={faArrowRight}
                        // disabled={true}
                    >
                        {props.nextBtnName ?? t("Next")}
                    </ConfirmButton>
                }

                {(props.currentStep === (props.maxSteps ?? 0)) &&
                    <ConfirmButton 
                        onClick={props.onSubmission} 
                        loading={props.loading}
                        type={props.submissionBtnType}
                        form={props.submissionBtnForm}
                        icon={faCheck}
                    >
                        {props.submissionBtnName ?? t("Submit")}
                    </ConfirmButton>
                }
            </ContentCardButtons>
        </>
    );
}

export const StepsContainer = styled('div', React.forwardRef)(() => {
    return `
        padding: var(--padding2) !important;
        user-select: none;
        
        ul {
            list-style: none;
            display: inline-flex;
            margin: 0;
            padding: 15px 0 0 0;
            width: 100%;
            justify-content: space-around;
            
            li {
                width: 100%;
                padding: 0 10px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                position: relative;
                cursor: pointer;
                
                &::before {
                    content: '';
                    width: 100%;
                    height: 5px;
                    background: var(--styleColor3);
                    position: absolute;
                    top: 10px;
                    left: -50%;
                }
                
                &:first-child::before {
                    content: none;
                }
                
                &.disabled {
                    &::before {
                        content: '';
                        width: 100%;
                        height: 5px;
                        background: #EEE;
                        position: absolute;
                        top: 10px;
                        left: -50%;
                    }
                    
                    .step-container {
                        cursor: not-allowed;
                    }
                    
                    .step-bullet {
                        background: var(--backgroundColor3);
                    }
                }
                
                &.active {
                    .step-bullet {
                        background: var(--buttonColorGreen);
                    }
                }
                
                .step-container {
                    position: relative;
                    z-index: 1;
                }
                
                .step-name {
                    margin: 0;
                    padding: 0;
                    font-size: 1.1em;
                    text-align: center;
                    position: relative;
                    padding: 40px 0 0 0;
                }
                
                .step-bullet {
                    width: 25px;
                    height: 25px;
                    background: var(--styleColor3);
                    position: absolute;
                    left: 50%;
                    -webkit-transform: translate(-50%);
                    -ms-transform: translate(-50%);
                    transform: translate(-50%);
                    border-radius: var(--roundCorner2);
                    top: 0;
                }
            }
        }
    `;
});