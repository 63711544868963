
// export function filterProps(props: any, invalidHtmlInputAttrs: string[]){
export function filterProps<T>(props: T, invalidHtmlInputAttrs: string[]){
    // @ts-ignore
    const filteredProps: Exclude<T, keyof typeof invalidHtmlInputAttrs> = Object.fromEntries(
        Object.keys(props)
            .filter(k => ! invalidHtmlInputAttrs.includes(k) )
            .map((p) => [p, props[p.toString()]])
    );

    return filteredProps;
}