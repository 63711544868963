
export const authEndpoints = {
    logout: `/logout`,
    login: `/`,
    register: `/register`,
    passwordReset: `/password-reset`,
    forgotPassword: `/forgot-password`,
    twoFaReset: `/2fa-reset`,
};

export interface BaseAuthProps {
    returnUrl: string;
}

export const authApiEndpoints = {
    base: `/users`,
    get login() {
        return `${this.base}/login`
    },
};

export interface LogoutResultModel {
    postLogoutRedirectUri: string;
}

export const availableUrlParams = {
    returnUrl: 'ReturnUrl',
    logoutId: 'logoutId',
}

export interface LoginResponseModel {
    redirectUri: string;
}