import ReactGA from "react-ga4";

/* Dit zijn de platformen, kan gebruikt worden om als methode mee te geven aan events om erachter te komen op welk platform ze de actie hebben uitgevoerd. */
/* Deprecated; gebruik window.location.hostname */
// export const gaPlatforms = {
//     energytixCloud: 'energytix-cloud',
//     tactixCloud: 'tactix-cloud',
//     chargingCloud: 'charging-cloud',
// }

/* *
* Zie https://support.google.com/analytics/answer/9267735 voor recommended events. 
* 
* Zie ook: 
* - https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag
* */

const loginEvent = (method: string) => ReactGA.event('login',{
    method: method
});

const registerEvent = (method: string) => ReactGA.event('sign_up',{
    method: method
});

export const gaBaseEvents = {
    login: loginEvent,
    register: registerEvent,
}