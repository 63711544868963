import React, {useEffect, useState} from "react";
import {t} from "../../PlattixReactCore/i18n";
import htmlRaw from "../../util/HtmlRaw";
import {CancelButton, ConfirmButton} from "./Buttons";
import {ContentCardButtons} from "./ContentCard";
import {PlattixForm} from "PlattixUI/core/components/form/Form";
import {PlattixSubmitButton} from "PlattixUI/core/components/form/Input";
import {UseFormReturn} from "react-hook-form";
import {MultiStepIndicator} from "PlattixUI/core/components/MultiStep";

export interface PlattixMultiStepFormProps {
    onSubmission: any,
    onCancel?: any,
    loading?: boolean,
    form: UseFormReturn
}

export interface PlattixMultiStepFormStepProps {
    step: number,
    title?: string,
    description?: string,
    maxSteps?: number,
    currentStep?: number,
    onNext?: any,
    onPrevious?: any,
    onSubmission?: any,
    onCancel?: any,
    loading?: boolean,
    errors?: any[],
    validateOnNext?: () => Promise<boolean>
}

interface MultistepFormIndicatorProps<TModel> {
    currentStep: number,
    // maxStep: number,
    steps: Array<Exclude<any, boolean | null | undefined>> | null | undefined,
    onStepClick: (number) => void
}

function MultistepFormIndicator<TModel>(props: MultistepFormIndicatorProps<TModel>) {
    const stepProgressState = useState<number>(1);
    const [stepProgress, setStepProgress] = stepProgressState;

    useEffect(() => {
        if (props.currentStep > stepProgress) setStepProgress(props.currentStep);
    }, [props.currentStep]);
    
    function getClass(idx: number) {
        if (idx === props.currentStep) return 'active'
        // if (idx > props.maxStep) return 'disabled'
        if ((idx > stepProgress) && (idx > props.currentStep)) return 'disabled'
        return ''
    }

    function onStepClickHandler(idx: number) {
        if (props.currentStep > stepProgress) setStepProgress(props.currentStep);
        if (idx <= stepProgress) props.onStepClick(idx);
    }

    return (
        <div className={"module-content-steps"}>
            <ul className={'setup-panel'}>
                {props.steps?.map((step, idx) => (
                    <li 
                        key={idx + 1} 
                        className={getClass(idx + 1)}
                        // onClick={idx + 1 <= props.maxStep ? () => props.onStepClick(idx + 1) : undefined}
                        onClick={() => onStepClickHandler(idx + 1)}
                    >
                        <a>
                            <span className="step-bullet"/>
                            <h4 className="module-content-step">{t(step.title)}
                            </h4>
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export function PlattixMultiStepForm(props: React.PropsWithChildren<PlattixMultiStepFormProps>) {
    const currentStepState = useState(1);
    const [currentStep, setCurrentStep] = currentStepState;

    const steps: Array<Exclude<any, boolean | null | undefined>> | null | undefined = React.Children?.map(props.children,
        (x) => {
            if (!React.isValidElement(x)) return
            return x?.props
        });

    const countChildren = steps?.length ?? 0;


    const next = async () => {
        const stepValid = await props.form.trigger()
        if (stepValid) setCurrentStep(currentStep + 1);
    }

    const previous = () => {
        setCurrentStep(currentStep - 1);
    }

    async function goToStep(step: number) {
        if (step <= 1) step = 1;
        if (step >= countChildren) step = countChildren;
        setCurrentStep(step)
    }
    
    const onSubmitHandler = async (event) => {
        const isValid = await props.form.trigger();
        if (!isValid) return;
        await props.onSubmission?.(event);
    };

    const checkKeyDown = (e) => {
        if (e.key === 'Enter') e.preventDefault();
    };
    
    return (
        <>
            <MultiStepIndicator
                currentStep={currentStep}
                steps={steps}
                onStepClick={goToStep}
            />
            
            <PlattixForm 
                onSubmit={onSubmitHandler}
                onKeyDown={checkKeyDown}
            >
                {React.Children.map(props.children, child => {
                    if (React.isValidElement(child))
                        return React.cloneElement(child, {
                            maxSteps: countChildren,
                            currentStep: currentStep,
                            onCancel: props.onCancel,
                            onNext: next,
                            onPrevious: previous,
                            onSubmission: props.onSubmission,
                            loading: props.loading || props.form.formState.isValidating,
                        })
                    })
                }
            </PlattixForm>
        </>
    )
}

export function PlattixMultiStepFormStep(props: React.PropsWithChildren<PlattixMultiStepFormStepProps>) {
    const onNext = () => {
        if (!props.validateOnNext) {
            props.onNext();
        } else {
            props.validateOnNext().then(res => {
                if (res) props.onNext();
                return;
            })
        }

    }
    if (props.step === props.currentStep) {
        
        return (
            <>
                <h4 className="step-title">{props.title ? props.title : ""}</h4>

                {(props.description) &&
                    <div className="step-description-container">
                        {htmlRaw(props.description ? props.description : "")}
                    </div>
                }

                {
                    props.children
                }
                 
                <ContentCardButtons>
                    {(props.onCancel) &&
                        <CancelButton className={'next'} onClick={props.onCancel}>
                            {t("Cancel")}
                        </CancelButton>
                    }

                    {((props.currentStep ?? -1) > 1 && (props.currentStep ?? -1) <= (props.maxSteps ?? 0)) &&
                        <ConfirmButton className={'next'} onClick={props.onPrevious}>
                            {t("Previous")}
                        </ConfirmButton>
                    }

                    {(props.currentStep ?? -1) >= 1 && ((props.currentStep ?? -1) < (props.maxSteps ?? 0)) &&
                        <ConfirmButton className={'next'} onClick={onNext} loading={props.loading}>
                            {t("Next")}
                        </ConfirmButton>
                    }

                    {(props.currentStep === (props.maxSteps ?? 0)) &&
                        // <ConfirmButton onClick={props.onSubmission} loading={props.loading}>
                        //     {t("Submit")}
                        // </ConfirmButton>
                        <PlattixSubmitButton name={t("Submit")}  loading={props.loading}/>
                    }
                    
                </ContentCardButtons>
            </>
        )
    }
    return (
        <>
        </>
    )
}
