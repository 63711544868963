import React from 'react';
import {useTranslation} from 'PlattixUI/PlattixReactCore/i18n';
import {ResetPasswordForm} from "../../forms/account/ResetPasswordForm";
import {UserState} from "../../../PlattixReactCore/UserSlice";
import {LoginOptions} from "PlattixUI/core/pages/Account/LoginOptions";

export interface ResetPasswordPageProps {
    userState?: UserState;
    hideTitle?: boolean;
    returnUrl?: string | null;
}

export function ResetPasswordPage(params: ResetPasswordPageProps) {
    const {t} = useTranslation(['translation']);
    return (
        <div className="login-form">
            {!params.hideTitle &&
                <h5 className="login-title">{t('ForgotH4')}</h5>
            }
            <ResetPasswordForm userState={params.userState}/>

            <LoginOptions
                hide={{
                    forgotPassword: true,
                    TwoFactorAuth: true,
                    TwoFactorRecoveryAuth: true,
                }}
                returnUrl={params.returnUrl}
            />
        </div>
    );
}