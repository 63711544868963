import React, {useEffect, useState} from 'react';

import {CookieBannerComponent} from './Partials/CookieBanner';
import {LanguageMenuComponent} from './Partials/LanguageMenu';

import {applicationSelector, optionsColapse} from '../js/Components/General/Menu/Navigation';
import {useMediaQuery} from '@mui/material';
import {deviceDimensions, ResponsiveComponent, ResponsiveSwipeDrawer} from "./components/Responsive";
import {Link} from "react-router-dom";
import {OptionsMenu, SidebarItem} from "./Sidebar";
import {t} from "../PlattixReactCore/i18n";
import {
    CheckLoginForIdentityServer,
    CheckLoginForIdentityServerApp
} from "PlattixUI/core/pages/Account/CheckLoginComponent";

// import Cookies from "universal-cookie";

const needsConfirmations = {
    cookies: true,
    language: false,
    help: false,
    changelog: false,
    inbox: false
}

interface SidebarLoginComponentProps {
    showBackground?: boolean;
    anonymous?: boolean;
    identityServer?: CheckLoginForIdentityServer;
}

export function SidebarLoginComponent(props: SidebarLoginComponentProps) {
    const cookieAcceptedString: string = 'cookies-accepted';
    const isApp: boolean = !!props.identityServer?.app;
    
    const [openDrawer, setOpenDrawer] = useState({
        cookies: localStorage.getItem(cookieAcceptedString) === 'true' ? false : true,
        language: false,
        help: false,
        profile: false,
    });

    const mobile = useMediaQuery(deviceDimensions.mobile);
    const tablet = useMediaQuery(deviceDimensions.tablet);
    const desktop = useMediaQuery(deviceDimensions.desktop);

    const toggleDrawer = (anchor: string, open: boolean) => {
        setOpenDrawer({ ...openDrawer, [anchor]: !openDrawer[anchor] });
    };

    const initalOpenSidebarItem = localStorage.getItem(cookieAcceptedString) === 'true' ? null : 'cookies'

    const currentOpenItem = useState<string | null>(initalOpenSidebarItem);
    const [theCurrentOpenItem, setTheCurrentOpenItem] = currentOpenItem;

    const processOutOfBodyClick = () => {
        if (theCurrentOpenItem) {
            if(!needsConfirmations[theCurrentOpenItem]) setTheCurrentOpenItem(null);
        }
    }

    useEffect(() => {
        if (localStorage.getItem(cookieAcceptedString) === 'true') setOpenDrawer({ ...openDrawer, ['cookies']: false });
    }, [localStorage.getItem(cookieAcceptedString)]);

    useEffect(() => {
        document.body.addEventListener('click', processOutOfBodyClick);

        return function cleanup() {
            document.body.removeEventListener("click", processOutOfBodyClick);
        };
    })
    
    return (
        <section className={`sidebar ${props.showBackground ? 'show-background' : ''} ${props.anonymous ? 'anonymous' : ''}`}>

            <SidebarItem 
                name={'Profile'} 
                icon={'user'} 
                explanation={t('Profile')} 
                circle={true}
                onClick={() => toggleDrawer('profile', true)}
                hideOptions={(mobile || tablet)}
                show={currentOpenItem}
            >
                <ResponsiveComponent devices={['desktop']}>
                    <Link className="option" to="/login">{t('login')}</Link>
                </ResponsiveComponent>

                <ResponsiveSwipeDrawer
                    devices={['mobile', 'tablet']}
                    title={t('Profile')}
                    open={openDrawer['profile']}
                    onClose={() => toggleDrawer('profile', false)}
                    onOpen={() => toggleDrawer('profile', true)}
                >
                    <Link className="option" to="/login">{t('login')}</Link>
                </ResponsiveSwipeDrawer>
            </SidebarItem>

            {!isApp &&
                <SidebarItem
                    name={'cookies'}
                    icon={'cookie-bite'}
                    explanation={t('Cookies')}
                    circle={true}
                    needsConfirmation={true}
                    onClick={() => toggleDrawer('cookies', true)}
                    hideOptions={(mobile || tablet)}
                    show={currentOpenItem}
                >
                    <ResponsiveComponent devices={['desktop']}>
                        <CookieBannerComponent show={currentOpenItem}/>
                    </ResponsiveComponent>

                    <ResponsiveSwipeDrawer
                        devices={['mobile', 'tablet']}
                        title={t('Cookies.WeUseCookies')}
                        open={openDrawer['cookies']}
                        onClose={() => toggleDrawer('cookies', false)}
                        onOpen={() => toggleDrawer('cookies', true)}
                    >
                        <CookieBannerComponent show={currentOpenItem}/>
                    </ResponsiveSwipeDrawer>
                </SidebarItem>
            }

            <SidebarItem 
                name={'language'} 
                icon={'globe-europe'} 
                explanation={t('Language')} 
                circle={true} 
                onClick={() => toggleDrawer('language', true)}
                hideOptions={(mobile || tablet)}
                show={currentOpenItem}
            >
                <ResponsiveComponent devices={['desktop']}>
                    <LanguageMenuComponent/>
                </ResponsiveComponent>

                <ResponsiveSwipeDrawer
                    devices={['mobile', 'tablet']}
                    title={t('Language')}
                    open={openDrawer['language']}
                    onClose={() => toggleDrawer('language', false)}
                    onOpen={() => toggleDrawer('language', true)}
                >
                    <LanguageMenuComponent/>
                </ResponsiveSwipeDrawer>
            </SidebarItem>

            <SidebarItem 
                name={'help'} 
                icon={'question'} 
                explanation={t('Plattix.HelpCenter')} circle={true} 
                onClick={() => toggleDrawer('help', true)}
                hideOptions={(mobile || tablet)}
                show={currentOpenItem}
            >
                <ResponsiveComponent devices={['desktop']}>
                    {/*<p className="option" target="_blank">{t('Plattix.HelpCenter')}</p>*/}
                    <Link className="option" to="/Legal/CompanyDetails" target="_blank">{t('Plattix.CompanyDetails')}</Link>
                    <Link className="option" to="/Legal/TermsAndConditions" target="_blank">{t('Plattix.TermsAndConditions')}</Link>
                    <Link className="option" to="/Legal/PrivacyPolicy" target="_blank">{t('Plattix.PrivacyPolicy')}</Link>
                    <Link className="option" to="/Legal/CookiePolicy" target="_blank">{t('Plattix.CookiePolicy')}</Link>
                    <Link className="option" to="/Legal/ReturnPolicy" target="_blank">{t('Plattix.ReturnPolicy')}</Link>
                </ResponsiveComponent>

                <ResponsiveSwipeDrawer 
                    devices={['mobile', 'tablet']}
                    title={t('Plattix.HelpCenter')}
                    open={openDrawer['help']}
                    onClose={() => toggleDrawer('help', false)}
                    onOpen={() => toggleDrawer('help', true)}
                >
                    {/*<Link className="option" to="/Legal/CompanyDetails">{t('Plattix.CompanyDetails')}</Link>*/}
                    <Link className="option" to="/Legal/TermsAndConditions">{t('Plattix.TermsAndConditions')}</Link>
                    <Link className="option" to="/Legal/PrivacyPolicy">{t('Plattix.PrivacyPolicy')}</Link>
                    <Link className="option" to="/Legal/CookiePolicy">{t('Plattix.CookiePolicy')}</Link>
                </ResponsiveSwipeDrawer>
            </SidebarItem>

        </section>
    );
}

