import {ResetPasswordPage} from "PlattixUI/core/pages/Account/ResetPassword";
import {useMemo} from "react";
import {availableUrlParams} from "pages/Auth/Auth";

export interface ResetPasswordProps {
    
}

export function ResetPassword(props: ResetPasswordProps) {

    const urlParams = useMemo(() => new URLSearchParams(window.location.search), [window.location.search]);
    const returnUrl = urlParams.get(availableUrlParams.returnUrl);
    
    return (
        <>
            <ResetPasswordPage returnUrl={returnUrl} />
        </>
    );
}