import { Reset2FAPage } from "PlattixUI/core/pages/Account/Reset2FA";
import {useMemo} from "react";
import {availableUrlParams} from "pages/Auth/Auth";

export interface Reset2FAProps {}

export function Reset2FA(props: Reset2FAProps) {
    const urlParams = useMemo(() => new URLSearchParams(window.location.search), [window.location.search]);
    const returnUrl = urlParams.get(availableUrlParams.returnUrl);
    
    return (
        <>
            <Reset2FAPage returnUrl={returnUrl} />
        </>
    );
}