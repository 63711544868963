import {Action, ThunkAction} from '@reduxjs/toolkit';
import {connectRouter, routerMiddleware} from 'connected-react-router'
import {applyMiddleware, combineReducers, compose, createStore} from 'redux'
import userReducer, {logoutUser} from './UserSlice'
import {reducers} from 'configuration/reducers';
import thunk from "redux-thunk";
import {createBrowserHistory} from "history";

export const history = createBrowserHistory()

const appReducer = combineReducers({
    router: connectRouter(history),
    user: userReducer,
    ...reducers
})

const rootReducer = (state:any, action:any) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === 'users/logout/fulfilled') {
        console.log('State cleared')
        state = {  }
    }  
    return appReducer(state, action);
};

export default function configureStore(initialState?: any) {
    return createStore(
        rootReducer,
        initialState,
        compose(
            applyMiddleware(
                routerMiddleware(history)
            ),
            applyMiddleware(thunk),
            // applyMiddleware(authInterceptor)
        ),
    )
}

// export const authInterceptor = ({ dispatch }) => next => action => {
//     console.log("dsfsdfsdafs", action)
//
//     if (action.status === 401) {
//         Promise.resolve(dispatch(refreshToken()).then(() => {
//                 console.log("dsfsdfsdafs", action)
//                 return next(action)
//             }
//             , (error) => {
//                 console.log(error);
//                 dispatch(logoutUser());
//             }
//         ));
//     } else {
//         return next(action);
//     }
// };


export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;

export const store = configureStore();
