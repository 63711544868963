import {ForgotPasswordPage} from "PlattixUI/core/pages/Account/ForgotPasswordPage";
import {useMemo} from "react";
import {availableUrlParams} from "pages/Auth/Auth";

export interface ForgotPasswordProps {
    
}

export function ForgotPassword(props: ForgotPasswordProps) {

    const urlParams = useMemo(() => new URLSearchParams(window.location.search), [window.location.search]);
    const returnUrl = urlParams.get(availableUrlParams.returnUrl);
    
    return (
        <>
            <ForgotPasswordPage returnUrl={returnUrl} />
        </>
    );
}