import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {store, history} from 'PlattixUI/PlattixReactCore/store';
import {Provider} from 'react-redux';
import * as serviceWorker from './serviceWorker';
import {ConnectedRouter} from 'connected-react-router';
import {I18nextProvider} from 'react-i18next';
import i18n from 'PlattixUI/PlattixReactCore/i18n';

ReactDOM.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <Suspense fallback="loading">
                <Provider store={store}>
                    <ConnectedRouter history={history}>
                        <App/>
                    </ConnectedRouter>
                </Provider>
            </Suspense>
        </I18nextProvider>
    </React.StrictMode>,
    document.getElementById('mount')    
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
