import React, {PropsWithChildren, useEffect, useMemo, useState} from "react";
import i18n from "PlattixUI/PlattixReactCore/i18n";
import {Landingpage} from "pages/Landingpage/Landingpage";
import {authEndpoints, availableUrlParams} from "pages/Auth/Auth";
import {Login} from "pages/Auth/Login";
import {Redirect, Route, Switch, useHistory} from "react-router-dom";
import {ContentLoginContainer, ContentLoginContainerProps} from "PlattixUI/core/ContentLoginContainer";
import { Logout } from "./Logout";
import {RegisterPage} from "PlattixUI/core/pages/Account/Register";
import {Register} from "pages/Auth/Register";
import {Reset2FA} from "pages/Auth/Reset2FA";
import {ForgotPassword} from "pages/Auth/ForgotPassword";
import {ResetPassword} from "pages/Auth/ResetPassword";

export interface CheckIdentityRoutersProps extends ContentLoginContainerProps {

}

export function CheckIdentityRouters(props: PropsWithChildren<CheckIdentityRoutersProps>) {
    const [transLoaded, setTransLoaded] = useState(false);
    
    const urlParams = useMemo(() => new URLSearchParams(window.location.search), [window.location.search]);
    const returnUrl = urlParams.get(availableUrlParams.returnUrl);
    const logoutId = urlParams.get('logoutId');
    const returnUrlString = !!returnUrl ? `?${availableUrlParams.returnUrl}=${returnUrl}` : ``;

    const isApp: boolean = !!props.identityServer?.app;
    
    i18n.on('initialized', function (lng: string) {
        setTransLoaded(true);
    });
    
    if (!returnUrl && !logoutId) return (
        <Landingpage identityServer={props.identityServer} />
    );

    return (
        <>
            <ContentLoginContainer identityServer={props.identityServer}>
                <Switch>
                    <Route path={authEndpoints.logout} component={Logout}/>
                    <Route path={authEndpoints.passwordReset} component={ResetPassword}/>
                    <Route path={authEndpoints.forgotPassword} component={ForgotPassword}/>
                    <Route path={authEndpoints.twoFaReset} component={Reset2FA}/>
                    <Route path={authEndpoints.register} component={Register}/>
                    <Route path={authEndpoints.login} component={Login}/>
                    <Redirect to={`${authEndpoints.login}${returnUrlString}`}/>
                </Switch>
            </ContentLoginContainer>
        </>
    );
}