import * as React from 'react';
import {SubmitHandler, UnpackNestedValue, useForm} from "react-hook-form";
import {loginUser, UserLoginModel, UserState} from "PlattixUI/PlattixReactCore/UserSlice";
import {useAppDispatch} from "PlattixUI/PlattixReactCore/hooks";
import {t} from 'PlattixUI/PlattixReactCore/i18n';
import {PlattixCheckbox, PlattixInput, PlattixSubmitButton} from "../../components/form/Input";
import {PlattixForm} from "../../components/form/Form";
import {ResendVerificationMail} from "../../pages/Account/ResendVerificationMail";
import {ContentCardButtons, PlattixFormError} from 'PlattixUI/core/components/ContentCard';
import {LoginOptions, LoginOptionsProps} from "PlattixUI/core/pages/Account/LoginOptions";
import {useEffect, useState} from 'react';
import {gaBaseEvents} from "PlattixUI/core/GoogleAnalytics";
import { useHistory } from 'react-router-dom';
import {DefaultValues} from "react-hook-form/dist/types/form";

export interface LoginFormProps {
    userState?: UserState;
    returnUrl?: string;
    defaultValues?: DefaultValues<UserLoginModel>;
    loginOptions?: LoginOptionsProps;
}

export function LoginForm(props: LoginFormProps) {
    const dispatch = useAppDispatch();
    const userState = props.userState;
    const history = useHistory();
    
    const onSubmit: SubmitHandler<UserLoginModel> = (data) => {
        dispatch(loginUser(data));
        if (!!props.returnUrl) history.push(props.returnUrl);
    }
    
    return (
        <LoginFormContent 
            onSubmit={onSubmit} 
            userState={userState} 
            returnUrl={props.returnUrl} 
            defaultValues={props.defaultValues}
            loginOptions={props.loginOptions}
        />
    );
}

export interface LoginFormContentProps {
    userState?: UserState;
    onSubmit?: (data: UnpackNestedValue<UserLoginModel>) => Promise<void>;
    returnUrl?: string | null;
    needs2FA?: boolean;
    emailNotConfirmed?: boolean;
    globalError?: [string];
    defaultValues?: DefaultValues<UserLoginModel>;
    loginOptions?: LoginOptionsProps;
}

export function LoginFormContent(props: LoginFormContentProps) {
    const [loading, setLoading] = useState<boolean>(false);
    const [loginWithRecoveryCode, setLoginWithRecoveryCode] = useState<boolean>(false);
    const isLoading = loading || props.userState?.userStatus.isLoading;
    
    const userLoginForm = useForm<UserLoginModel>({
        defaultValues: {
            ...props.defaultValues,
            has2FA: !!props.needs2FA || !!props.userState?.userStatus.error?.errors?.["needs2fa"]
        }
    });

    const email = userLoginForm.watch('email');

    const onSubmit: SubmitHandler<UserLoginModel> = async (data) => {
        setLoading(true);
        await props.onSubmit?.(data);
        gaBaseEvents.login(window.location.hostname);
        setLoading(false);
    }

    function resend() {
        ResendVerificationMail({email: email})
    }
    
    function showLoginWithRecoveryCodeHandler(){
        userLoginForm.setValue("token", '');
        userLoginForm.setValue('recoveryCode', '');
        setLoginWithRecoveryCode(true);
    }
    
    const needs2FA = !!props.needs2FA || !!props.userState?.userStatus.error?.errors?.["needs2fa"];
    const emailNotConfirmed = !!props.userState?.userStatus.error?.errors?.["emailNotConfirmed"];
    const errors = userLoginForm.formState.errors;
    
    return (
        <>
            <PlattixForm onSubmit={userLoginForm.handleSubmit(onSubmit)} autoComplete={needs2FA ? "nope" : "on"}>
                <PlattixInput
                    disabled={isLoading}
                    type={"email"}
                    name={"Email"}
                    register={userLoginForm.register("email", {required: true})}
                    label={t("Email")}
                    placeholder={t("EmailPH")}
                    autoComplete={needs2FA ? "nope" : "on"}
                    error={errors.email || props.userState?.userStatus.error}
                />
                <PlattixInput
                    disabled={isLoading}
                    type={"password"}
                    name={"password"}
                    register={userLoginForm.register("password", {required: true})}
                    label={t("Password")}
                    placeholder={t("Password")}
                    autoComplete={needs2FA ? "nope" : "on"}
                    error={errors.password || props.userState?.userStatus.error}
                />

                {needs2FA &&
                    <>
                        <input type="hidden" {...userLoginForm.register("has2FA")} />
                        {loginWithRecoveryCode ?
                            <>
                                <PlattixInput
                                    disabled={isLoading}
                                    name="recoveryCode"
                                    description={t('2FA.RecoveryCode.LoginDescription')}
                                    register={userLoginForm.register("recoveryCode", {required: true})}
                                    label={t("2FA.RecoveryCode")}
                                    placeholder={t("2FA.RecoveryCode")}
                                    error={errors.recoveryCode || props.userState?.userStatus.error}
                                />
                            </>
                            :
                            <>
                                <PlattixInput
                                    name="token"
                                    label={t('2FA.Token')}
                                    register={userLoginForm.register("token", {required: true})}
                                    placeholder={t("Token")}
                                    error={errors.token || props.userState?.userStatus.error}
                                    disabled={isLoading}
                                />
                                <PlattixCheckbox
                                    name={"rememberMe30Days"}
                                    label={t("RememberMe30Days")}
                                    placeholder={t("RememberMe30Days")}
                                    register={userLoginForm.register("rememberMe30Days", {required: false})}
                                    error={errors.rememberMe30Days || props.userState?.userStatus.error}
                                />
                            </>
                        }
                    </>
                }

                <ContentCardButtons>
                    <PlattixSubmitButton 
                        loading={isLoading} 
                        disabled={isLoading} 
                        name={"Login"}
                    />
                </ContentCardButtons>

                {(!needs2FA && (props.userState && props?.userState?.userStatus.error)) &&
                    <PlattixFormError>
                        {!emailNotConfirmed && 
                            <p className="login-error">{props.globalError || props?.userState?.userStatus.error.errors?.["_global"] || props?.userState?.userStatus.error.title}</p>
                        }
                        
                        {emailNotConfirmed && 
                            <ResendVerificationMail email={email} />
                        }
                    </PlattixFormError>
                }

            </PlattixForm>

            <LoginOptions
                hide={{
                    login: true,
                    TwoFactorAuth: true,
                    TwoFactorRecoveryAuth: !needs2FA
                }}
                returnUrl={props.returnUrl}
                onClickUseRecoveryCode={showLoginWithRecoveryCodeHandler}
                {...props.loginOptions}
            />
        </>
    );
}