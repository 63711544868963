import {CheckLoginForIdentityServer} from "PlattixUI/core/pages/Account/CheckLoginComponent";
import React, {PropsWithChildren, useState} from "react";
import {useTheme} from "@mui/material/styles";
import {useMediaQuery} from "@mui/material";
import {ResponsiveComponent, ResponsiveSwipeDrawer} from "PlattixUI/core/components/Responsive";
import {PlattixImage} from "PlattixUI/util/thumbor/PlattixImage";
import {PlattixConfiguration} from "configuration/Configuration";
import {t} from "PlattixUI/PlattixReactCore/i18n";
import {SidebarLoginComponent} from "PlattixUI/core/SidebarLogin";
import {styled} from "goober";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faSplotch } from "@fortawesome/free-solid-svg-icons";


export interface ContentLoginContainerProps {
    noBackground?: boolean
    identityServer?: CheckLoginForIdentityServer;
    highlightedContent?: JSX.Element;
}

export function ContentLoginContainer(props: PropsWithChildren<ContentLoginContainerProps>) {
    const isApp: boolean = !!props.identityServer?.app;

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));
    const tablet = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const desktop = useMediaQuery(theme.breakpoints.up('lg'));
    
    const viewModeMap = () => {
        if (mobile) return 'mobile';
        if (mobile) return 'tablet';
        return 'desktop';
    };

    return (
        <>
            {!props.noBackground &&
                <>
                    {isApp ? <>
                        <ResponsiveComponent devices={['tablet', 'desktop']}>
                            <div className="background">
                                <PlattixImage
                                    width={window.innerWidth ?? 500}
                                    height={window.innerHeight ?? 500}
                                    alt={'Background'}
                                    fileSource={props.identityServer?.app?.backgroundImage}
                                />
                            </div>
                        </ResponsiveComponent>
                    </> : <>
                        <ResponsiveComponent devices={['tablet', 'desktop']}>
                            <div className="background">
                                <img src={PlattixConfiguration.loginScreen.background} alt="Background"/>
                            </div>
                        </ResponsiveComponent>

                        {/*<ResponsiveComponent devices={['desktop']}>*/}
                            {!!props.highlightedContent &&
                                <>
                                    <HighlightedContentContainer
                                        viewmode={viewModeMap()}
                                    >
                                        {props.highlightedContent}
                                    </HighlightedContentContainer>
                                </>
                            }
                        {/*</ResponsiveComponent>*/}
                        
                    </>
                    }
                </>
            }

            <section className="content-container-login" id="content-container">
                <div className="login-logo">
                    <a href="/">
                        <PlattixImage
                            width={0}
                            height={50}
                            fileSource={props.identityServer?.app?.logo ?? PlattixConfiguration.mainLogo}
                            alt={`Logo ${PlattixConfiguration.platformName}`}
                        />
                    </a>
                </div>
                <>
                    {props.children}
                </>
                {(!!props.identityServer?.app?.sponsors?.length && isApp) &&
                    <>
                        <ResponsiveComponent devices={['mobile', 'tablet']}>
                            <hr/>
                        </ResponsiveComponent>
                        <div className="sponsors">
                            <div className="sponsor">
                                <p>{t('Plattix.Sponsor.WithSupportFrom')}</p>

                                {props.identityServer?.app?.sponsors?.map(s =>
                                    <PlattixImage
                                        width={0}
                                        height={100}
                                        alt={`Logo ${s.name}`}
                                        fileSource={s.logo}
                                    />
                                )}
                            </div>
                        </div>
                    </>
                }

                {(!!PlattixConfiguration.loginScreen.sponsors?.length && !isApp) &&
                    <>
                        <ResponsiveComponent devices={['mobile', 'tablet']}>
                            <hr/>
                        </ResponsiveComponent>
                        <div className="sponsors">
                            <div className="sponsor">
                                <p>{t('Plattix.Sponsor.WithSupportFrom')}</p>
                                {PlattixConfiguration.loginScreen.sponsors?.map(s =>
                                    <img key={s.name} src={s.logo} alt={`Logo ${s.name}`}/>
                                )}
                            </div>
                        </div>
                    </>
                }

                <ResponsiveComponent devices={['mobile', 'tablet']}>
                    <hr/>
                    <SidebarLoginComponent
                        identityServer={props.identityServer}
                    />
                </ResponsiveComponent>

            </section>
        </>
    );
}

interface HighlightedContentContainerProps {
    viewmode?: 'desktop' | 'tablet' | 'mobile';
}

const HighlightedContentContainer = styled('div', React.forwardRef)((props: HighlightedContentContainerProps) => {
    const viewModeMap = () => {
        const baseStyle = `
            max-width: calc(100% - 50px - 500px);
            position: absolute;
            height: 100%;
            width: 100%;
            left: 50px;
            padding: 50px;
            padding-right: 200px;
        `;
        
        if (!props.viewmode) return baseStyle;
        
        const sharedMobileTabletStyle = `
            position: fixed;
            z-index: 101;
        `;
        
        if (props.viewmode === 'tablet') return `
            ${sharedMobileTabletStyle}
        `;
        if (props.viewmode === 'mobile') return `
            ${sharedMobileTabletStyle}
        `;
        
        return baseStyle;
    };
    
    return `
        z-index: 1;
        overflow-y: auto;
        
        ${viewModeMap()}
    `;
});