import React, {PropsWithChildren, Fragment, useMemo, useEffect, useState, isValidElement} from 'react';
import {Breadcrumbs, useMediaQuery} from '@mui/material';
import {Link, useHistory} from "react-router-dom";
import {css, styled} from "goober";
import {t} from "../PlattixReactCore/i18n";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {HttpError} from "PlattixUI/PlattixReactCore/CoreTypes";
import {filterProps} from "PlattixUI/util/ElementProperties";
import {LoadingScreen} from "PlattixUI/core/components/Loader";
import {Actions, ActionsLeft, ActionsRight, ErrorMessage} from "PlattixUI/core/components/ActionBar";
import {PlattixTabs, PlattixTabsProps, PlattixTabsType} from "PlattixUI/core/components/ContentCard";
import {ButtonProps, CancelButton, ConfirmButton, DeleteButton, EditButton} from "PlattixUI/core/components/Buttons";
import {PlattixSubmitButton, PlattixSubmitButtonProps} from "PlattixUI/core/components/form/Input";
import {deviceDimensions} from "PlattixUI/core/components/Responsive";

export type BreadcrumbsType = {
    title: string,
    url: string,
}

export interface PageHeaderProps {
    title?: string,
    subheader?: string,
    description?: string
    breadcrumbs?: BreadcrumbsType[],
    backButton?: boolean,
    backButtonLocation?: string,
}
export function PageHeader(props: PageHeaderProps) {
    const history = useHistory();
    
    
    const showBackButton = !!props.backButton;
    
    const goBackHandler = () => {
        if (props.backButtonLocation) history.push(props.backButtonLocation)
        else history.goBack();
    };
    
    return (
            <div className="info">
                {props.title &&
                    <h1 className={`${props.breadcrumbs ? TitleWithBreadcrumbs : ''}`}>{props.title}</h1>
                }
                {props.subheader &&
                    <h5 className={`${props.breadcrumbs ? TitleWithBreadcrumbs : ''}`}>{props.subheader}</h5>
                }
                {(props.breadcrumbs || props.backButton) &&
                    <Breadcrumbs aria-label="breadcrumb" className={BreadcrumbStyling}>

                        {props.backButton &&
                            <BackButton onClick={goBackHandler}>
                                <FontAwesomeIcon icon={faArrowLeft}/>
                                {t('Back')}
                            </BackButton>
                        }
                        
                        { props.breadcrumbs &&
                            props.breadcrumbs.map((bc, index) => {
                                return <Link to={bc.url} key={`bc_${bc.title}_${index}`}>{bc.title}</Link>
                            })
                        }
                    </Breadcrumbs>
                }
                {props.description && <p>{props.description}</p>}
                
                {/*<ResponsiveComponent devices={['mobile', 'tablet']}>*/}
                {/*    <hr />*/}
                {/*</ResponsiveComponent>*/}
            </div>
    );
}

const BreadcrumbStyling = css`
    font-size: unset !important;
    margin: 0 0 15px 0 !important;
    
    a {
        color: var(--textColorDark);
        transition: 0.1s ease-in-out;
        
        &:hover {
            color: var(--styleColor1);
            text-decoration: underline;
        }
    }
`;

const TitleWithBreadcrumbs = css`
    margin: 0 0 5px 0;
`;

export const BackButton = styled('p')(() => {
    return `
        padding: 2px 7px;
        background: var(--buttonColorGrey);
        border-radius: 10px;
        color: #fff;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
        width: fit-content;
    `;
});

export type BasePageButtonTypes = 'confirm' | 'delete' | 'cancel' | 'edit';
export interface ButtonPageProps {
    name?: string;
}

export type BasePageButtons = Partial<Record<BasePageButtonTypes | string, ButtonProps & ButtonPageProps>> & {
    submit?: Omit<PlattixSubmitButtonProps, 'name'> & {
        name?: string;
    }
}

export type OtherButtons = Record<string, JSX.Element>;

export type PageTab = PlattixTabsType & {
    buttons?: PageButtonsProps;
    showGeneralPageButtons?: boolean;
    showGeneralPageChildren?: boolean;
}

interface PageProps extends PageHeaderProps {
    loading?: boolean;
    loadingMessage?: string;
    error?: HttpError | null;
    notFound?: boolean | string;
    tabs?: PageTab[];
    buttons?: PageButtonsProps;
    beforeChildren?: React.ReactNode;
    afterChildren?: React.ReactNode;
    pageTabIndexState?: [number, React.Dispatch<React.SetStateAction<number>>];
}

export function Page(props: PropsWithChildren<PageProps>){
    const pageTabIndexState = useState<number>(0);
    const [pageTabIndex, setPageTabIndex] = props.pageTabIndexState ?? pageTabIndexState;
    
    const desktop = useMediaQuery(deviceDimensions.desktop);
    
    /* De algemene buttons van de pagina. */
    const BasePageButtons = () => (<PageButtons {...props.buttons} />);
    /* Check if there are buttons defined. */
    const basePageButtonsAreDefined = !!Object.keys(props.buttons?.base ?? {}).length || !!Object.keys(props.buttons?.other ?? {}).length;
    
    /* Geformatteerde tabs. */
    const pageTabs = useMemo(() => {
        if (!props.tabs?.length) return [];
        
        const formattedTabs = props.tabs.map(tab => {
            /* Check if there are buttons defined. */
            const tabButtonsAreDefined = !!Object.keys(tab.buttons?.base ?? {}).length || !!Object.keys(tab.buttons?.other ?? {}).length;
            const content = (tab.type === 'content') ? (
                <PageTabButtonsContainer showbuttons={((basePageButtonsAreDefined && !!tab.showGeneralPageButtons) || tabButtonsAreDefined) ? 'true' : undefined}>
                    {!!tab.showGeneralPageButtons ?
                        <BasePageButtons/> : <PageButtons {...tab.buttons} />
                    }
                </PageTabButtonsContainer>
            ) : undefined;
            
            return {
                ...tab,
                content: content,
            }
        });

        return formattedTabs;
    }, [props.tabs, props.buttons]);
    
    /* De component die de styling conditioneel gaat renderen. */
    const pageHeader = () => {
        const component = (
            <>
                <PageHeader 
                    {...filterProps(props, ["loading", "error", "loadingMessage"])} 
                    title={props.title}
                />

                {!!props.tabs?.length && 
                    <PlattixTabs
                        tabs={pageTabs}
                        tabindexState={props.pageTabIndexState ?? pageTabIndexState}
                    />
                }

                {!props.tabs?.length &&
                    <BasePageButtons />
                }
            </>
        );
        
        if (desktop) return (<PageHeaderContainer>{component}</PageHeaderContainer>);
        return component;
    };
    
    /* Conditionele rendering van de children. */
    const children = useMemo(() => {
        if (!props.tabs?.length) return props.children;
        const currentTab = props.tabs[pageTabIndex];

        if ((currentTab.type === 'content') && !currentTab.showGeneralPageChildren) return (
            <>
                {!!props.beforeChildren && props.beforeChildren}
                {currentTab.content}
                {!!props.afterChildren && props.afterChildren}
            </>
        );

        return props.children;
    }, [props.children, props.tabs, pageTabIndex]);
    
    return (
        <>
            {pageHeader()}
            
            {!!props.notFound && <p>{typeof props.notFound === "string" ? props.notFound: t('NotFound')}</p>}
            {props.loading && <LoadingScreen message={props.loadingMessage} />}
            {!props.loading && props.error && <HttpErrorMessage error={props.error}/>}
            
            {!props.notFound && !props.loading && !props.error && children}
        </>
    )
}

interface HttpErrorMessageProps {
    error: HttpError
}
export function HttpErrorMessage(props: HttpErrorMessageProps){
    return <ErrorMessage>
        <h1>{props.error.title}</h1>
        <p>{props.error.detail}</p>
    </ErrorMessage>
}

export interface PageButtonsProps {
    base?: BasePageButtons;
    other?: OtherButtons;
}

function PageButtons(props: PageButtonsProps) {

    /* Positie waarin de knoppen gesorteerd worden in de UI. */
    const baseButtonHierarchy = ['delete', 'cancel', 'edit', 'confirm', 'submit'];
    /* De basisknoppen, gesorteerd volgens de baseButtonHierarchy */
    const baseButtons: (keyof BasePageButtons)[] = Object.keys(props?.base ?? {}).sort((a, b) => baseButtonHierarchy.indexOf(a) - baseButtonHierarchy.indexOf(b));
    /* Zijn er basisknoppen gedefiniëerd? */
    const baseButtonsAreDefined = !!baseButtons.length;
    /* De andere knoppen. */
    const otherButtons = Object.keys(props?.other ?? {})
    /* Zijn er andere knoppen gedefiniëerd? */
    const otherButtonsAreDefined = !!otherButtons.length;
    /* Zijn er knoppen gedefiniëerd? */
    const showPageButtons = (baseButtonsAreDefined || otherButtonsAreDefined);
    
    /* Map van de te-renderen knoppen en haar elementen. */
    const baseButtonMap = (type: keyof BasePageButtons) => {
        if (!type) return <></>;
        if (!baseButtonsAreDefined) return <></>;
        if (!props?.base?.[type]) return <></>;
        if (type === 'confirm') return (<ConfirmButton {...props.base[type]} />);
        if (type === 'delete') return (<DeleteButton {...props.base[type]} />);
        if (type === 'cancel') return (<CancelButton {...props.base[type]} />);
        if (type === 'edit') return (<EditButton {...props.base[type]} />);
        if (type === 'submit') return (<PlattixSubmitButton name={t('Save')} {...props.base[type]} />);
        if (isValidElement(props.base[type])) return props.base[type];
        return (<ConfirmButton {...props.base[type]} />);
    }
    
    if (!showPageButtons) return (<></>);
    
    return (
        <Actions>
            {otherButtonsAreDefined &&
                <ActionsLeft>
                    {otherButtons.map((ob) => {
                        return (
                            <Fragment key={ob}>
                                {props?.other?.[ob]}
                            </Fragment>
                        )
                    })}
                </ActionsLeft>
            }
            
            {baseButtonsAreDefined &&
                <ActionsRight>
                    {baseButtons?.map((bb) => {
                        return (
                            <Fragment key={bb}>
                                {baseButtonMap(bb) as JSX.Element}
                            </Fragment>
                        )
                    })}
                </ActionsRight>
            }
        </Actions>
    );
}

export const PageHeaderContainer = styled('div', React.forwardRef)(() => {
    const desktop = useMediaQuery(deviceDimensions.desktop);
    const parentPadding = `10px`;
    if (!desktop) return '';
    return `
        position: sticky;
        top: 0;
        z-index: 1000;
        background-color: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(10px);
        margin-left: -${parentPadding};
        margin-right: -${parentPadding};
        padding-left: ${parentPadding};
        padding-right: ${parentPadding};
    `;
});

export interface PageTabButtonsContainerProps {
    showbuttons?: string;
}

export const PageTabButtonsContainer = styled('div', React.forwardRef)((props: PageTabButtonsContainerProps) => {
    return `
        ${!props.showbuttons ? '' : 'margin-top: 10px;'}
        ${!!props.showbuttons ? '' : 'margin-bottom: 25px;'}
    `;
});