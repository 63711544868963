import React, {useEffect, useState} from 'react';
import {useTranslation} from '../../../PlattixReactCore/i18n';
import {SubmitHandler, UnpackNestedValue, useForm} from "react-hook-form";
import {loginUser, UserLoginModel, UserRegisterModel} from "../../../PlattixReactCore/UserSlice";
import {isHttpError} from "../../../PlattixReactCore/api/Api";
import {PlattixInput, PlattixSubmitButton, PlattixValidatedInput} from "PlattixUI/core/components/form/Input";
import {ContentCardButtons, PlattixFormError} from "PlattixUI/core/components/ContentCard";
import {Password, Required, SameAs} from "PlattixUI/core/forms/FormValidators";
import {doRegister} from "PlattixUI/PlattixReactCore/api/AccountApi";
import {ResendVerificationMail} from "./ResendVerificationMail";
import {HttpError} from "PlattixUI/PlattixReactCore/CoreTypes";
import {PasswordStrengthIndicator} from "PlattixUI/core/components/form/Validation";
import {PlattixForm} from "PlattixUI/core/components/form/Form";
import {LoginOptions, LoginOptionsProps} from "PlattixUI/core/pages/Account/LoginOptions";
import {gaBaseEvents} from "PlattixUI/core/GoogleAnalytics";
import {DefaultValues} from "react-hook-form/dist/types/form";
import {useAppDispatch} from "PlattixUI/PlattixReactCore/hooks";

export interface RegisterPageProps {
    onSubmit?: (data: UnpackNestedValue<UserRegisterModel>) => Promise<void>;
    returnUrl?: string | null;
    loginOptions?: LoginOptionsProps;
    defaultValues?: DefaultValues<UserRegisterModel>;
}

export function RegisterPage(props: RegisterPageProps) {
    const {t} = useTranslation();

    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState<HttpError|null>(null);
    const [modelErrors, setModelErrors] = useState<{[key: string]: [string]}>();
    const [success, setSuccess] = useState(false);
    
    const dispatch = useAppDispatch();
    
    function getParams(searchParam?: string) {
        const query = window.location.search.substring(1);
        const queryStrings = query.split('&');
        let queryParams = {};
        
        for (const qs of queryStrings) {
            const queryPair = qs.split('=');
            queryParams = {
                ...queryParams,
                [queryPair[0]]: queryPair[1]
            };
        }
        
        if (!searchParam) return queryParams;
        return queryParams[searchParam];
    }
    
    const form = useForm<UserRegisterModel>({
        mode: 'onBlur',
        defaultValues: {
            ...props.defaultValues,
        },
    });
    const {register, handleSubmit, formState: {errors}, watch } = form;
    
    const onSubmit: SubmitHandler<UserRegisterModel> = async (data) => {
        setLoading(true);
        
        if (!!props.onSubmit) {
            await props.onSubmit(data);
            return setLoading(false);
        }

        const response = await doRegister(data);

        if (isHttpError(response)) {
            setError(response);
            setModelErrors(response.errors)
            setLoading(false);
        } else {
            setSuccess(true);
            if (!!data.invitationCode) {
                dispatch(loginUser({
                    email: data.email,
                    password: data.password,
                    remainLoggedIn: false,
                    has2FA: false,
                    token: '',
                    rememberMe30Days: false,
                    recoveryCode: '',
                }));
            }
            gaBaseEvents.register(window.location.hostname);
            setLoading(false);
        }
    }
     
    if (success && !props.defaultValues?.invitationCode) {
        return (
            <div>
                <h5>{t('RegistrationSuccessful')}</h5>
                <p>{t('RegistrationSuccessfulMessage')}</p>
                <PlattixFormError>
                    <ResendVerificationMail email={watch('email')}/>
                </PlattixFormError>
                
                <LoginOptions
                    hide={{
                        register: true,
                        TwoFactorAuth: true,
                        TwoFactorRecoveryAuth: true,
                    }}
                    returnUrl={props.returnUrl}
                    {...props.loginOptions}
                />
            </div>
        )
    }

    return (
        <div className="login-form">
            <h5 className="login-title">{t('RegisterH4')}</h5>
            <PlattixForm onSubmit={handleSubmit(onSubmit)}>
                <PlattixInput
                    disabled={isLoading}
                    register={register("firstName", { required: true })}
                    label={t('FirstName')}
                    error={errors.firstName || error}
                    placeholder={t('FirstNamePH')}
                />
                <PlattixInput
                    disabled={isLoading}
                    register={register("lastName", {required: true})}
                    label={t('LastName')}
                    error={errors.lastName || error}
                    placeholder={t('LastNamePH')}
                />
                <PlattixInput
                    disabled={isLoading}
                    type={"email"}
                    register={register("email", {required: true})}
                    label={t('Email')}
                    error={errors.email || error}
                    placeholder={t('EmailPH')}
                    readOnly={!!props.defaultValues?.invitationCode}
                />
                <PlattixValidatedInput<UserRegisterModel>
                    disabled={isLoading}
                    type={"password"}
                    label={t("Password")}
                    name={"password"}
                    placeholder={t("PasswordPH")}
                    formHook={form}
                    validation={[Password(), Required()]}
                    // error={errors.password || error}
                />

                <PasswordStrengthIndicator password={form.watch("password")} />

                <PlattixValidatedInput<UserRegisterModel>
                    disabled={isLoading}
                    type={"password"}
                    label={t("ConfirmPassword")}
                    placeholder={t("ConfirmPasswordPH")}
                    name={"confirmPassword"}
                    formHook={form}
                    validation={[Required(), SameAs<UserRegisterModel>("password", t("Password"))]}
                />

                <ContentCardButtons>
                    {/*{!isLoading && <Link to={"/"}><CancelButton/></Link>}*/}
                    {/*{isLoading && <Spinner/>}*/}
                    <PlattixSubmitButton loading={isLoading} disabled={isLoading} name={t('Register')}/>
                </ContentCardButtons>

                {
                    error && !error?.errors?.["_global"] && (
                        <PlattixFormError>
                            <p className="login-error">{error.title}</p>
                            <p className="login-error">{error.detail}</p>
                        </PlattixFormError>
                    )
                }

                {
                    error?.errors?.["_global"] &&
                    <PlattixFormError>
                        <p className="login-error">{error.errors?.["_global"]}</p>
                    </PlattixFormError>
                }
                
            </PlattixForm>

            <LoginOptions
                hide={{
                    register: true,
                    TwoFactorAuth: true,
                    TwoFactorRecoveryAuth: true,
                }}
                returnUrl={props.returnUrl}
                {...props.loginOptions}
            />
        </div>
    );
}