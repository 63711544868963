import {doGet, doPost, isHttpError} from "PlattixUI/PlattixReactCore/api/Api";
import {
    GridColumnConfig,
    GridConfig,
    ServerGridConfig,
    ServerSideDataModel,
    ServersideDataProps
} from "PlattixUI/core/grid/types/GridTypes";
import {GridColDef} from "@mui/x-data-grid-premium";
import {GetCellRenderer} from "./CellRenderers";
import {GridFilterOperator} from "@mui/x-data-grid/models/gridFilterOperator";
import {GridFilterItem} from "@mui/x-data-grid/models/gridFilterItem";
import {GridCellParams, GridValueGetterParams} from "@mui/x-data-grid/models/params/gridCellParams";
import {DataGridFilterInput} from "./DataGridFilterInput";
import {GridFilterConfig, ServerGridFilterConfig} from "PlattixUI/core/grid/types/GridFilterTypes";
import {GridProps} from "PlattixUI/core/grid/PlattixDataGrid";

function GetValueGetter(col: GridColumnConfig): undefined | ((params: GridValueGetterParams) => any) {
    switch (col.dataType) {
        case "date":
        case "dateTime":
            return (params: GridValueGetterParams) => params.value ? new Date(params.value) : params.value;
        case "number":
            return (params: GridValueGetterParams) => params.value !== null ? Number(params.value) : params.value
    }
}

export async function fetchGridFilterOperatorsConfig(): Promise<GridFilterConfig> {
    const data = await doGet<ServerGridFilterConfig>('/ServerSideDataTable/Filters')
    
    if (isHttpError(data)) {
        throw new Error(data.title)
    }

    const operators: { [key: string]: GridFilterOperator[] } = {}

    data.dataTypes.forEach(dt => {
        operators[dt] = data.operators.filter(op => op.dataTypes.includes(dt)).map(op => {

            const operator: GridFilterOperator = {
                label: op.name,
                value: `${op.id}`,
                getApplyFilterFn: (filterItem: GridFilterItem, _: GridColDef) => {
                    if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                        return null;
                    }

                    return (_: GridCellParams): boolean => {
                        return true;
                        // return Number(params.value) >= Number(filterItem.value);
                    };
                },
                InputComponent: DataGridFilterInput,
                InputComponentProps: {
                    "type": dt,
                    "operator": op
                }
            }
            return operator
        });
    })


    return {
        dataTypes: data.dataTypes,
        operators: operators
    };
}

export async function fetchGridConfig(gridCode: string, filterConfig?: GridFilterConfig): Promise<GridConfig> {
    const data = await doGet<ServerGridConfig>('/ServerSideDataTable/Config/' + gridCode)
    if (isHttpError(data)) {
        throw new Error(data.title)
    }
    if (!filterConfig) {
        throw new Error('no filter config')
    }
    
    return {
        ...data,
        columns: data.columns
            .filter(col => col.visible)
            .map(col => {
            const def: GridColDef = {
                field: col.columnName,
                headerName: col.header,
                sortable: col.orderable,
                // hide: !col.visible,
                hideable: col.visible,
                type: col.dataType,
                flex: !col.width ? 1 : undefined,
                width: col.width,
                filterable: col.searchable,
                renderCell: GetCellRenderer(col),
                valueGetter: GetValueGetter(col),

                filterOperators: filterConfig.operators[col.dataType]
            }
            return def;
        })
    }
}


export async function fetchServersideData(
    props: ServersideDataProps,
    gridProps: GridProps
): Promise<ServerSideDataModel> {

    const data = await doPost<ServerSideDataModel>('/ServerSideDataTable/GetServersideData', props)
    if (isHttpError(data)) {
        throw new Error(data.title)
    }
    
    if (gridProps.rowReordering){
        data.data = [...data.data].sort((a,b) => String(a[gridProps.reorderColumn ?? 'id']).localeCompare(String(b[gridProps.reorderColumn ?? 'id'])))
        // data.data.forEach(row => {
        //     row.__reorder__ = row[gridProps.reorderColumn ?? 'id']
        // })
    }

    return data;
}