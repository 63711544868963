import {LicenseInfo} from "@mui/x-data-grid-premium";



export function setLicenseKeys() {
    // License key MUI
    LicenseInfo.setLicenseKey(
        // '3660b31bf571bd3cac78fb547d6e068bT1JERVI6NDAxMDEsRVhQSVJZPTE2Nzk0NzkwMTkwMDAsS0VZVkVSU0lPTj0x', // Dit is de licentie voor de de Datagrid-Pro V5.17.16
        '8162bd8a5bcfd638c24bbfb59689e5d1Tz03NjAzOCxFPTE3MjgxMjcyMjkwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
    );
}