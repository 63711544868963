import {Slugs} from "../../configuration/Configuration";

export const DeveloperRouteMap = {
    Grid: {
        base: `${Slugs.Developer}/Grid`,
        Overview: () => `${DeveloperRouteMap.Grid.base}`,
        Detail: () => `${DeveloperRouteMap.Grid.base}/:gridId`,
    },
    Emails: {
        base: `${Slugs.Developer}/Emails`,
        Overview: () => `${DeveloperRouteMap.Emails.base}`,
        Detail: () => `${DeveloperRouteMap.Emails.base}/:emailTemplate`,
        PageBuilder: () => `${DeveloperRouteMap.Emails.base}/PageBuilder`,
    },
    Images: {
        base: `${Slugs.Developer}/Images`,
        Overview: () => `${DeveloperRouteMap.Images.base}`,
        Detail: () => `${DeveloperRouteMap.Images.base}/:fileId`,
    },
    Test: {
        base: `${Slugs.Developer}`,
        TestPage: () => `${DeveloperRouteMap.Test.base}/TestPage`,
    },
}

export const DefaultAdminRouteMap = {
    base: `${Slugs.Admin}`,
    UsersOverview: () => `${DefaultAdminRouteMap.base}/Users`,
    UserDetails: () => `${DefaultAdminRouteMap.base}/Users/:UserId`,
    AccountContractOverview: () => `${DefaultAdminRouteMap.base}/Contract/AccountContractOverview`
};

export const ProfileRouteMap = {
    base: ``,
    Detail: () => `${ProfileRouteMap.base}/Profile`,
    ConfirmChangedEmail: () => `${ProfileRouteMap.base}/Profile/email/confirm`
}