import {
    GridApi,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridExportMenuItemProps,
    gridFilteredSortedRowIdsSelector,
    GridFilterModel,
    GridFilterPanel,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExportContainer,
    gridVisibleColumnFieldsSelector,
    useGridApiContext,
    useGridRootProps
} from "@mui/x-data-grid-premium";
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
    ButtonProps,
    ClickAwayListener,
    Collapse,
    Fade,
    IconButton,
    InputAdornment,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    TextField
} from "@mui/material";
import {t, useTranslation} from "../../PlattixReactCore/i18n";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter, faSearch} from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import {useState} from "react";
import {SavedFilter} from "PlattixUI/core/grid/types/GridFilterTypes";
import {DataGridToolButton} from "./gridStyling/GridStyling";
import {PlattixAutoFormModal, PlattixSubmitField} from "PlattixUI/core/components/form/Form";
import {Required} from "../../core/forms/FormValidators";
import {GridConfigurationModel} from "PlattixUI/core/grid/types/GridTypes";
import {ContentCardButtons} from "../../core/components/ContentCard";
import {ConfirmButton} from "../../core/components/Buttons";

interface SearchToolbarProps {
    disableFilter: boolean,
    disableLoadConfig: boolean,
    showRefreshButton: boolean,
    onRefresh: () => void,
    enableDataExport: boolean,
    quickSearch: QuickSearchToolbarProps,
    advancedSearch: AdvancedSearchToolbarProps,
    useQuickSearch: boolean,
    setUseQuickSearch: (value: (((prevState: boolean) => boolean) | boolean)) => void,
    applyFilter: () => void,
    saveFilter: () => void,
    config: GridConfigurationToolbarProps;
}

interface GridConfigurationToolbarProps {
    onSaveState: (model: SaveGridConfigurationModel) => void,
    configurations: GridConfigurationModel[];
    onDeleteConfig: (id: number) => void;
    onLoadConfig: (id: number) => void;
    onLoadState: () => void,
}

interface AdvancedSearchToolbarProps {
    filter: GridFilterModel,
    savedFilters: SavedFilter[],
    setAdvancedFilter: React.Dispatch<React.SetStateAction<GridFilterModel>>
}


interface QuickSearchToolbarProps {
    hasQuickSearch: boolean,
    hasRegexQuickSearch: boolean,
    hasCaseSensitiveQuickSearch: boolean,

    clearSearch: () => void,
    onChange: () => void,
    value: string,
    regex: boolean,
    toggleRegex: () => void,
    caseSensitive: boolean,
    toggleCaseSensitive: () => void,

    advancedSearch: GridFilterModel
    
    focusQuickSearch?: boolean,
}

export function SearchToolbar(props: SearchToolbarProps) {
    const {t} = useTranslation()
    const [selectedSavedFilter, setSelectedSavedFilter] = useState<number>(-1);
    
    // const savedFilterOptions: SavedFilter[] = [
    //     {
    //         id: -1,
    //         default: false,
    //         saveResults: false,
    //         criterium: {logic: 'or', criteria: []},
    //         name: t('Opgeslagen filters')
    //     },
    //     ...(props.advancedSearch.savedFilters ?? [])
    // ]

    return <>
        <GridToolbarContainer>
            {props.showRefreshButton && !!props.onRefresh && <GridToolbarRefreshButton onRefresh={() => props.onRefresh()}/>}
            <GridToolbarColumnsButton/>
            <GridToolbarDensitySelector/>
            
            {!props.disableFilter && 
                <>
                    <button onClick={() => props.setUseQuickSearch(!props.useQuickSearch)} className={DataGridToolButton}>
                        <span className={'MuiButton-startIcon MuiButton-iconSizeSmall'}>
                            <FontAwesomeIcon icon={faFilter} className={'MuiSvgIcon-root MuiSvgIcon-fontSizeMedium'}/>
                        </span>
                        {t('Filter')}
                    </button>
                    {/*{ props.useQuickSearch && <QuickSearch {...props.quickSearch}/> }*/}
                </>
            }
            {!props.disableLoadConfig &&  <GridToolbarLoadConfigurationButton configurations={props.config.configurations} onDeleteConfig={props.config.onDeleteConfig}/>}
            {!props.disableLoadConfig && <GridToolbarSaveConfigurationButton onSaveState={props.config.onSaveState}/>}


            {props.enableDataExport &&
                <CustomExportButton />
            }

            {(!props.disableFilter && props.useQuickSearch) &&
                <QuickSearch {...props.quickSearch}/>
            }

        </GridToolbarContainer>
        <Collapse in={!props.useQuickSearch}>
            <GridFilterPanel
                filterFormProps={{
                    // Customize inputs by passing props
                    logicOperatorInputProps: {
                        size: 'small',
                    },
                    columnInputProps: {
                        size: 'small',
                        sx: {mt: 'auto'},
                    },
                    operatorInputProps: {
                        size: 'small',
                        sx: {mt: 'auto'},
                    },
                    deleteIconProps: {
                        sx: {
                            '& .MuiSvgIcon-root': {color: '#d32f2f'},
                        },
                    },
                }}
                    
            />
            <ContentCardButtons padding={['top', 'bottom']}>
                <ConfirmButton onClick={props.applyFilter}>
                    {t("ApplyFilter")}
                </ConfirmButton>
            </ContentCardButtons>
            
            {/*<AdvancedSearch {...props.advancedSearch}/>*/}
        </Collapse>
    </>

}


export interface GridToolbarRefreshButtonProps {
    onRefresh?: () => void;
}

export function GridToolbarRefreshButton(props: GridToolbarRefreshButtonProps) {
    const rootProps = useGridRootProps();
    return (
        <>
            <Button
                size="small"
                // endIcon={<AddIcon />}
                startIcon={<RefreshIcon />}
                onClick={props.onRefresh}
                {...rootProps.slotProps?.baseButton}
            />
        </>
    );
}

export interface GridToolbarSaveConfigurationButtonProps {
    onSaveState: (model: SaveGridConfigurationModel) => void,

}

export function GridToolbarSaveConfigurationButton(props: GridToolbarSaveConfigurationButtonProps) {
    const rootProps = useGridRootProps();
    const apiRef = useGridApiContext();
    const [loading, setLoading] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    
    const fields : PlattixSubmitField<SaveGridConfigurationModel>[] = [
        {
            name: "label",
            label: t("description"),
            validation: [Required()]
        }
    ]
    
    const onSubmit = (model: SaveGridConfigurationModel) => {
        setLoading(true)
        props.onSaveState({label: model.label, state:JSON.stringify(apiRef.current.exportState())})
        setLoading(false)
        setShowModal(false)
    }
    
    return (
        <>
            {/*<AddIconButton>Staat opslaan</AddIconButton>*/}
            <Button
                size="small"
                // endIcon={<AddIcon />}
                startIcon={<AddIcon />}
                onClick={() => {
                    setShowModal(true);
                }}
                {...rootProps.slotProps?.baseButton}
            >
                {t("Grid.SaveConfig")}
            </Button>
            <PlattixAutoFormModal<SaveGridConfigurationModel>
                defaultValue={{label: ""}}
                show={showModal}
                onClose={() => setShowModal(false)} 
                fields={fields}
                title={t("Grid.SaveConfig.Modal")}
                onSubmit={onSubmit}
                closeOnOutsideClick={true}
                loading={loading}
            />
        </>
    );
}

export interface SaveGridConfigurationModel {
    label: string;
    state: string;
}

export interface GridToolbarLoadConfigurationButtonProps {
    configurations: GridConfigurationModel[];
    onDeleteConfig: (id: number) => void;
}

export function GridToolbarLoadConfigurationButton(props: GridToolbarLoadConfigurationButtonProps) {
    const rootProps = useGridRootProps();
    const apiRef = useGridApiContext();
    
    const [showPopper, setShowPopper] = useState<boolean>(false);
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
    

    const handleDeleteView = React.useCallback((viewId: number) => {
        props.onDeleteConfig(viewId);
    }, [props.configurations, props.onDeleteConfig]);

    const handleSetActiveView = (viewId: number) => {
        const config = props.configurations.find(c => c.gridConfigurationId === viewId);
        if (!config) return;
        const state = JSON.parse(config.gridState)
        
        apiRef.current.restoreState(state);
        handleClosePopper()
        // dispatch({ type: 'setActiveView', id: viewId });
    };

    const handlePopperAnchorClick = (event: React.MouseEvent) => {
        setShowPopper(!showPopper)
        setMenuAnchor(event.currentTarget as HTMLElement)
        event.stopPropagation();
    };

    const handleClosePopper = () => {
        setShowPopper(false)
    };

    const popperId = 'transition-popper';


    return (
        <>
            <Button
                aria-describedby={popperId}
                type="button"
                size="small"
                id="custom-view-button"
                aria-controls={showPopper ? 'custom-view-menu' : undefined}
                aria-expanded={showPopper ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handlePopperAnchorClick}
            >
                {`${t('Grid.Views')} (${Object.keys(props.configurations).length})`}
            </Button>
            <ClickAwayListener onClickAway={handleClosePopper}>
                <Popper
                    id={popperId}
                    open={showPopper}
                    anchorEl={menuAnchor}
                    role={undefined}
                    transition
                    placement="bottom-start"
                    sx={{ zIndex: 'modal' }}
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper>
                                <MenuList
                                    autoFocusItem={showPopper}
                                    id="custom-view-menu"
                                    aria-labelledby="custom-view-button"
                                    // onKeyDown={handleListKeyDown}
                                >
                                    {props.configurations.map((conf) => (
                                        <ViewListItem
                                            key={conf.gridConfigurationId}
                                            view={conf}
                                            viewId={conf.gridConfigurationId}
                                            selected={false}
                                            onDelete={handleDeleteView}
                                            onSelect={handleSetActiveView}
                                        />
                                    ))}
                                </MenuList>
                            </Paper>
                        </Fade>
                    )}
                </Popper>
            </ClickAwayListener>
        </>
    );
}


function ViewListItem(props: {
    view: GridConfigurationModel;
    viewId: number;
    selected: boolean;
    onDelete: (viewId: number) => void;
    onSelect: (viewId: number) => void;
}) {
    const { view, viewId, selected, onDelete, onSelect, ...other } = props;

    return (
        <MenuItem selected={selected} onClick={() => onSelect(viewId)} {...other}>
            {view.label}
            <IconButton
                edge="end"
                aria-label="delete"
                size="small"
                onClick={(event) => {
                    event.stopPropagation();
                    onDelete(viewId);
                }}
            >
                <DeleteIcon />
            </IconButton>
        </MenuItem>
    );
}

function JsonExportMenuItem(props: GridExportMenuItemProps<{}>) {
    const apiRef = useGridApiContext();

    const { hideMenu } = props;

    const getJson = (apiRef: React.MutableRefObject<GridApi>) => {
        // Select rows and columns
        const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
        const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

        // Format the data. Here we only keep the value
        const data = filteredSortedRowIds.map((id) => {
            const row: Record<string, any> = {};
            visibleColumnsField.forEach((field) => {
                row[field] = apiRef.current.getCellParams(id, field).value;
            });
            return row;
        });

        // Stringify with some indentation
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#parameters
        return JSON.stringify(data, null, 2);
    };

    const exportBlob = (blob: Blob, filename: string) => {
        // Save the blob in a json file
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();

        setTimeout(() => {
            URL.revokeObjectURL(url);
        });
    };

    return (
        <MenuItem
            onClick={() => {
                const jsonString = getJson(apiRef);
                const blob = new Blob([jsonString], {
                    type: 'text/json',
                });
                exportBlob(blob, 'export.json');

                // Hide the export menu after the export
                hideMenu?.();
            }}
        >
            Export JSON
        </MenuItem>
    );
}

function CustomExportButton(props: ButtonProps) {
    const csvOptions: GridCsvExportOptions = {
        delimiter: ';',
        utf8WithBom: true,
        fileName: 'export',
    };
    
    return (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
            <JsonExportMenuItem />
        </GridToolbarExportContainer>
    );
}

function QuickSearch(props: QuickSearchToolbarProps) {
    const {t} = useTranslation();
    if (!props.hasQuickSearch)
        return <></>

    return (
        <div className="module-content-search" style={{maxWidth: '200px', marginRight: '5px'}}>

            <TextField
                variant="standard"
                value={props.value}
                onChange={props.onChange}
                placeholder={t('QuickSearch')}
                style={{boxShadow: "none"}}
                autoFocus={props.focusQuickSearch}
                InputProps={{
                    startAdornment: <FontAwesomeIcon icon={faSearch}/>,
                    endAdornment: (
                        <InputAdornment position="end">
                            {
                                props.hasRegexQuickSearch && (
                                    <IconButton
                                        aria-label="toggle regex"
                                        onClick={props.toggleRegex}
                                        edge="end"
                                        style={{color: props.regex ? 'black' : 'lightgrey'}}
                                    >
                                        .*
                                    </IconButton>
                                )}
                            {
                                props.hasCaseSensitiveQuickSearch && (
                                    <IconButton
                                        aria-label="toggle case sensitive"
                                        onClick={props.toggleCaseSensitive}
                                        edge="end"
                                        style={{color: props.caseSensitive ? 'black' : 'lightgrey'}}
                                    >
                                        <abbr title={t('Grid.Filter.CaseSensitive')}>aA</abbr>
                                    </IconButton>
                                )
                            }
                        </InputAdornment>
                    ),
                }}
                sx={{
                    width: {
                        xs: 1,
                        sm: 'auto',
                    },
                    m: (theme) => theme.spacing(1, 0.5, 1.5),
                    '& .MuiSvgIcon-root': {
                        mr: 0.5,
                    },
                    '& .MuiInput-underline:before': {
                        borderBottom: 1,
                        borderColor: 'divider',
                    },
                }}
            />
        </div>
    );
}

