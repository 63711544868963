import {t} from "PlattixUI/PlattixReactCore/i18n";
import {Link} from "react-router-dom";
import * as React from "react";
import {useEffect, useMemo} from "react";
import { PlattixLink } from "PlattixUI/core/components/util/PlattixLink";

export type LoginOptionTypes = 'login' | 'register' | 'forgotPassword' | 'TwoFactorAuth' | 'TwoFactorRecoveryAuth';
export type LoginOptionOnClickType = Partial<Record<LoginOptionTypes, undefined | (() => void)>>;
export interface LoginOptionsProps {
    hide?: Partial<Record<LoginOptionTypes, boolean | undefined>>;
    returnUrl?: string | null;
    onClickUseRecoveryCode?: () => void;
    onClick?: LoginOptionOnClickType;
}

export function LoginOptions(props: LoginOptionsProps) {
    const urlParams = useMemo(() => new URLSearchParams(window.location.search), [window.location.search]);
    const returnUrl = props.returnUrl ? urlParams.get(props.returnUrl) : undefined;
    const returnUrlString = !!returnUrl ? `?${props.returnUrl}=${returnUrl}` : ``;
    
    return (
        <div className="login-content-group-container-bottom">
            
            {!props.hide?.login &&
                <div className="login-content-group">
                    <p>{t('LoginOptions.Login.Title')}</p>
                    <PlattixLink
                        link={!!props.onClick?.['login'] ? undefined : `/login${returnUrlString}`}
                        onClick={!props.onClick?.['login'] ? undefined : props.onClick?.['login']}
                    >
                        {t('LoginOptions.Login.Description')}
                    </PlattixLink>
                </div>
            }
            
            {!props.hide?.forgotPassword &&
                <div className="login-content-group">
                    <p>{t('ForgotPassword?')}</p>
                    <PlattixLink 
                        link={!!props.onClick?.['forgotPassword'] ? undefined : `/forgot-password${returnUrlString}`}
                        onClick={!props.onClick?.['forgotPassword'] ? undefined : props.onClick?.['forgotPassword']}
                    >
                        {t('Reset')}
                    </PlattixLink>
                </div>
            }

            {!props.hide?.TwoFactorAuth &&
                <div className="login-content-group">
                    <p>{t('Forgot2FA')}</p>
                    <PlattixLink
                        link={!!props.onClick?.['TwoFactorAuth'] ? undefined : `/2fa-reset${returnUrlString}`}
                        onClick={!props.onClick?.['TwoFactorAuth'] ? undefined : props.onClick?.['TwoFactorAuth']}
                    >
                        {t('Reset2FA')}
                    </PlattixLink>
                </div>
            }

            {!props.hide?.register &&
                <div className="login-content-group">
                    <p>{t('NoAccount')}</p>
                    <PlattixLink 
                        link={!!props.onClick?.['register'] ? undefined : `/register${returnUrlString}`}
                        onClick={!props.onClick?.['register'] ? undefined : props.onClick?.['register']}
                    >
                        {t('Register')}
                    </PlattixLink>
                </div>
            }

            {!props.hide?.TwoFactorRecoveryAuth  &&
                <div className="login-content-group">
                    <p>{t('2FA.NoAuthenticator')}</p>
                    <PlattixLink 
                        onClick={props?.onClickUseRecoveryCode}
                    >
                        {t('2FA.LoginWithRecoveryCode')}
                    </PlattixLink>
                </div>
            }
        </div>
    );
}
