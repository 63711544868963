import {
    DataGridPremium,
    DataGridPremiumProps,
    deDE,
    enUS,
    frFR,
    GridActionsCellItem,
    GridApi,
    GridCallbackDetails,
    GridDensity,
    GridFilterModel,
    GridRowClassNameParams,
    GridRowOrderChangeParams,
    GridRowParams,
    GridRowSelectionModel,
    GridSlotsComponentsProps,
    GridSortModel,
    MuiEvent,
    nlNL
} from '@mui/x-data-grid-premium';
import {useQuery, useQueryClient} from '@tanstack/react-query'
import * as React from "react";
import {PropsWithChildren, useEffect, useState} from "react";
import {
    GridConfig,
    GridConfigurationModel,
    ServerSideDataModel,
    ServersideDataProps
} from "PlattixUI/core/grid/types/GridTypes";
import {fetchGridConfig, fetchGridFilterOperatorsConfig, fetchServersideData} from './gridApi';
import {useAppSelector} from "PlattixUI/PlattixReactCore/hooks";
import {userLangCodeSelector} from "PlattixUI/PlattixReactCore/UserSlice";
import {GridRowOptions} from "PlattixUI/core/grid/gridStyling/GridOptions";
import {GetRowOptions} from "PlattixUI/core/grid/CellRenderers";
import {GridActionsColDef, GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import useDebounce from "PlattixUI/util/useDebounce";
import {SaveGridConfigurationModel, SearchToolbar} from "PlattixUI/core/grid/SearchToolbar";
import {GridFilterConfig, SavedFilter} from "PlattixUI/core/grid/types/GridFilterTypes";
import {dataGridStyling} from "./gridStyling/GridStyling";
import {QueryApiCallWithConfirmProps} from "PlattixUI/PlattixReactCore/api/ApiWithSwal";
import {history} from 'PlattixUI/PlattixReactCore/store'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {Popover, Typography,} from "@mui/material";
import {doDelete, doPost, isHttpError} from "PlattixUI/PlattixReactCore/api/Api";
import {toast} from "react-toastify";
import {t} from "PlattixUI/PlattixReactCore/i18n";
import {GridCellParams} from "@mui/x-data-grid/models/params/gridCellParams";
import {GridEventListener} from "@mui/x-data-grid/models/events";
import {DeveloperInfo} from "PlattixUI/core/components/ContentCard";
import {generatePath, LinkProps} from 'react-router-dom';
import {DeveloperRouteMap} from "../DefaultMenu/RouteMap";
import {GridLogicOperator} from "@mui/x-data-grid/models/gridFilterItem";
import {styled} from 'goober';
import {GridInputRowSelectionModel} from "@mui/x-data-grid/models/gridRowSelectionModel";
import {GridPaginationModel} from "@mui/x-data-grid/models/gridPaginationProps";

export type GridProps = {
    gridCode: string,
    parameters?: { [key: string]: string }
    rowOptions?: GridRowOptions,
    
    /**
     * Doorgeven van Mui Data Grid Selection Model
     */
    selectionModel?: GridInputRowSelectionModel,
    /**
     * Doorgeven van Mui onSelectionChanged
     */
    onSelectionChanged?: (selectionModel: GridRowSelectionModel, details: GridCallbackDetails) => void,
    /**
     * Mag meer dan 1 rij geselecteerd wroden
     */
    allowMultiSelect?: boolean,
    /**
     * Moet er een kolom met checkboxes toegegoegd worden
     */
    showCheckbox?: boolean,

    /**
     * Focus de quick search bij het renderen van de grid
     */
    focusQuickSearch?: boolean,

    /**
     * Set the default page size
     */
    defaultPageSize?: number,

    /**
     * Set the default grid density
     */
    defaultDensity?: GridDensity,

    /**
     * Include the language code as a parameter on SS Request
     */
    includeLanguageCode?: boolean,
    /**
     * Show a simple delete button that will do a DELETE on the url returned by the {@link deleteUrlGenerator}
     */
    showDeleteButton?: boolean,
    /**
     * Should the delete button be shown as an option in the dropdown
     * instead of an always visible trashcan icon (default)
     */
    showDeleteInDropdown?: boolean,
    /**
     * Function that will generate the DELETE route given the row data
     * @param row: Row data
     */
    deleteUrlGenerator?: (row: GridRowParams) => string,
    /**
     * Provide a custom confirmation message
     */
    deleteTitle?: string,
    /**
     * Set more options to the Delete Swal. {@link QueryApiCallWithConfirmProps}
     */
    deleteWithConfirmProps?: QueryApiCallWithConfirmProps<GridRowParams>

    /**
     * Show a simple edit button
     */
    showEditButton?: true,

    /**
     * Show a simple edit button
     */
    editButtonIsLink?: (row: GridRowParams) => LinkProps,
    /**
     * Should the edit button be shown as an option in the dropdown
     * instead of an always visible trashcan icon (default)
     */
    showEditInDropdown?: boolean,
    /**
     * If the function returns a string, the user will be redirected to the URL represented by the string.
     * If provided function returns nothing, the function will be called on the button click
     * @param row: Grid Row data
     */
    onEdit?:
        | ((row: GridRowParams) => string)
        | ((row: GridRowParams) => void)
    /**
     * Should the {@link onEdit} event be fired when a row is double clicked
     * Default: false
     */
    editOnRowDoubleClick?: boolean,

    /**
     * Show a simple download button
     */
    showDownloadButton?: true,
    
    getDetailPanelContent?: (params: GridRowParams) => React.ReactNode,
    getDetailPanelHeight?: (params: GridRowParams) => number | 'auto',

    /**
     * Hide the toolbar
     */
    hideToolbar?: boolean,

    /**
     * Callback fired when a double click event comes from a row container element.
     * @param {GridRowParams} params With all properties from [[RowParams]].
     * @param {MuiEvent<React.MouseEvent>} event The event object.
     * @param {GridCallbackDetails} details Additional details for this callback.
     */
    onRowDoubleClick?: GridEventListener<'rowDoubleClick'>,

    /**
     * Run a function when a row is double clicked
     */
    onCellDoubleClick?: (params: GridCellParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => void,
    componentsProps?:  GridSlotsComponentsProps,

    apiRef?: React.MutableRefObject<GridApi> | undefined,

    onDataFetchInit?: (data?: ServerSideDataModel) => void,

    /**
     * If `true`, the selection model will retain selected rows that do not exist.
     * Useful when using server side pagination and row selections need to be retained
     * when changing pages.
     * @default false
     */
    keepNonExistentRowsSelected? : boolean,

    /**
     * Select the pageSize dynamically using the component UI.
     * @default [25, 50, 100]
     */
    rowsPerPageOptions?: number[],
    
    /**
     * Determines if a row can be selected.
     * @param {GridRowParams} params With all properties from [[GridRowParams]].
     * @returns {boolean} A boolean indicating if the cell is selectable.
     */
    isRowSelectable?: (params: GridRowParams) => boolean,
    /**
     * Function that applies CSS classes dynamically on rows.
     * @param {GridRowClassNameParams} params With all properties from [[GridRowClassNameParams]].
     * @returns {string} The CSS class to apply to the row.
     */
    getRowClassName?: (params: GridRowClassNameParams) => string,
    /**
     * Disable the load & save configurations buttons, filterin must be enabled as well
     */
    disableLoadConfig?: boolean,
    /**
     * Hide the refresh button
     */
    hideRefreshButton?: boolean
} & (
    {
        showDeleteButton: true,
        showDeleteInDropdown?: boolean,
        deleteUrlGenerator: (row: GridRowParams) => string,
        deleteTitle?: string,
        deleteWithConfirmProps?: QueryApiCallWithConfirmProps<GridRowParams>
    } | { showDeleteButton?: false }
    ) & (
    {
        showEditButton: true,
        showEditInDropdown?: boolean,
        onEdit?:
            | ((row: GridRowParams) => string)
            | ((row: GridRowParams) => void)
        editOnRowDoubleClick?: boolean
    } | { showEditButton?: false }
    ) & (
    {
        showDownloadButton: true,
        /**
         * Should the download button be shown as an option in the dropdown
         * instead of an always visible icon (default)
         */
        showDownloadInDropdown?: boolean,
        /**
         * Function that will generate the DOWNLOAD route given the row data
         * @param row: Row data
         */
        downloadUrlGenerator?: (row: GridRowParams) => string,
        /**
         * Function that will generate the filename for the download given the row data
         * @param row: Row data
         */
        downloadFilenameGenerator?: (row: GridRowParams) => string,
        /*
        * Function which is executed just before calling the API.
        * This function can be used as a pre hook to do any tasks which needs to be done before the API call is made.
        * @example: Disable button / change button state to loading.
        * */
        onDownloadStart?: () => void,

        /*
        * Function which is executed after making the API call.
        * This function can be used as a post hook to do any tasks which needs to be done after the API call is made.
        * @example: Enable button / change button state to primary.
        * */
        onDownloadComplete?: () => void,

        /*
        * Function to be called when API has resulted in failure.
        * Error handling can be done over here.
        * @example: Show an alert to the user saying something has went wrong and the file is not downloaded.
        * */
        onDownloadError?: (error) => void
    } | { showDownloadButton?: false }
    ) & (
    {
        /**
         * Allow the rows to be reordered
         *
         * Enabling this, will disable filters and pagination
         */
        rowReordering: true,

        /**
         * Column that should be used for the row ordering
         */
        reorderColumn: string,

        /**
         * Url naarwaar een post gedaan moet worden om de nieuwe volgorde op te slaan
         */
        rowReorderUrl?: string,

        /**
         * Callback die uitgevoerd wordt na een reorder
         * @param params
         * @param event
         * @param details
         */
        onRowOrderChange?: (params: GridRowOrderChangeParams, event: MuiEvent<{}>, details: GridCallbackDetails) => void,

        onRowDragStart?: () => void,
        onRowDragOver?: () => void,
        onRowDragEnd?: () => void,
    } | { rowReordering?: false }
    )

export function PlattixDataGrid(props: GridProps) {

    // region States en hooks

    /* Ophalen van de taal van de gebruiker. */
    const userLang = useAppSelector(userLangCodeSelector);

    /* States voor de huidige paginering. */
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({page: 0, pageSize: props.defaultPageSize ?? 20})
    // const [page, setPage] = useState(0);
    // const [pageSize, setPageSize] = useState(props.defaultPageSize ?? 20);
    const [loading, setLoading] = useState<boolean>(false);

    /* State voor de sortering van een column. */
    const [gridSortModel, setGridSortModel] = useState<GridSortModel>([]);

    /* States voor het zoeken en de filtering. */
    const [useQuickSearch, setUseQuickSearch] = useState(true);
    const [advancedFilter, setAdvancedFilter] = useState<GridFilterModel | undefined>(undefined);
    const [advancedFilterJson, setAdvancedFilterJson] = useState("");
    const [quickSearchText, setQuickSearchText] = React.useState('');
    const [quickSearchRegex, setQuickSearchRegex] = React.useState(false);
    const [quickSearchCaseSensitive, setQuickSearchCaseSensitive] = React.useState(false);
    const [onDataFetchedInit, setOnDataFetchedInit] = React.useState(false);
    const [savedGridConfigs, setSavedGridConfigs] = useState<GridConfigurationModel[]>([]);

    /* Opvangen van zoeken en filtering. */
    const debouncedQuickSearchText = useDebounce(quickSearchText, 250);
    const debouncedAdvancedFilterJson = useDebounce(advancedFilterJson, 250);

    /* State voor de gridId. */
    const [gridId, setGridId] = useState<number | null>(null);

    // endregion

    // region Queries

    /* Hook om te communiceren met useQuery. */
    const queryClient = useQueryClient();

    /* Functie met doGet request voor het ophalen van de ingestelde configuratie van het grid, op basis van de gridcode. */
    async function getGridConfig() {
        /* Ophalen van de data. */
        const response = await fetchGridConfig(props.gridCode, filterConfig.data)
        /* Instellen van de actions-column wanneer er kolommen zijn ingesteld en er buttons getoond mogen worden. */
        if (!!response.columns?.length && (props.rowOptions?.length || props.showEditButton || props.showDeleteButton || props.showDownloadButton)) {
            const actionColumn: GridActionsColDef = {
                field: 'actions',
                type: "actions",
                width: 100,
                getActions: (cell) => GetRowOptions(props, cell, reloadData),
            }

            response.columns.push(actionColumn)
        }

        /* Instellen van de minWidth voor elke column. */
        response.columns.forEach((col) => {
            col.minWidth = 100;
        });

        /* Als de rowReordering ingesteld staat, de column sortering en filtering uitschakelen. De rowReordering zorgt ervoor dat de rows verplaatst kunnen worden van volgorde. */
        if (props.rowReordering) {
            response.columns.forEach((col) => {
                col.sortable = false;
                col.filterable = false;
            });
        }
        /* Instellen van de gridId. */
        setGridId(response.gridId);
        setSavedGridConfigs(response.configurations)
        // if (!!response.savedFilters.length) {
        //     const newFilter = formatSavedFilter(response.savedFilters[0]);
        //     setAdvancedFilter(newFilter)
        //     setAdvancedFilterJson(JSON.stringify(newFilter))
        //     setUseQuickSearch(false);
        // }
        // else {
        //     setAdvancedFilter({items: [], logicOperator: GridLogicOperator.And})
        // }

        return response;
    }

    const formatSavedFilter = (filter : SavedFilter) => {
        const filterModel : GridFilterModel = {
            logicOperator: GridLogicOperator.And,
            items: filter.criterium.criteria.map(item => {
                return {
                    id: `${item.columnName}${item.condition.toString()}${item.value.length.toString()}`,
                    field: item.columnName,
                    operator: item.condition.toString(),
                    value: item.value
                };
            })
        }
        return filterModel;
    }

    /* Configuratie ophalen voor de filtering. */
    const filterConfig = useQuery<GridFilterConfig>(
        ['gridFilterConfig', userLang],
        fetchGridFilterOperatorsConfig,
        {
            // staleTime
            initialData: undefined,
            refetchOnWindowFocus: false,
            staleTime: 30 * 60 * 1000,
            cacheTime: 300 * 60 * 1000,
        }
    );


    /* Ophalen van de configuratie van de grid. */
    const configQuery = useQuery<GridConfig>(
        ['gridConfig', props.gridCode, filterConfig.data?.operators],
        getGridConfig,
        {
            refetchOnMount: 'always',
            // enabled: !!filterConfig.data?.operators?.length
            refetchOnWindowFocus: false,
            enabled: !!filterConfig.data?.dataTypes?.length,
            staleTime: 300_000,
            cacheTime: 30 * 60 * 1000
        }
    );


    /* Instellen van de parameters om de data voor in de grid te kunnen ophalen. */
    function getServersideDataParameters(): ServersideDataProps {
        const params: ServersideDataProps = {
            gridCode: props.gridCode,
            page: paginationModel.page,
            pageSize: paginationModel.pageSize,
            sort: gridSortModel,
            parameters: props.parameters ?? {},

            useQuickSearch: useQuickSearch,
            quickSearch: {
                regex: quickSearchRegex,
                caseSensitive: quickSearchCaseSensitive,
                value: debouncedQuickSearchText
            },
            advancedSearch: {
                logic: advancedFilter?.logicOperator ?? "or",
                criteria: advancedFilter?.items.map(item => {
                    // criteria: formatFilterValueHandler(advancedFilter).items?.map(item => {
                    // criteria: formattedAdvancedFilter.items?.map(item => {
                    return {
                        value: Array.isArray(item.value) ? item.value : [item.value],
                        columnName: item.field,
                        condition: Number(item.operator)
                    };
                }) ?? []
            },
        }
        if (props.includeLanguageCode) {
            params.parameters['paramlanguagecode'] = userLang
        }

        return params
    }

    /* Ophalen van de data om weer te geven in de datagrid. */
    const dataQuery = useQuery<ServerSideDataModel>(
        [
            'gridData',
            props.gridCode,
            //page, // Dit geeft problemen met de paginering, workaround rond geschreven in een useEffect
            // pageSize,
            gridSortModel,
            userLang,
            props.parameters,
            debouncedQuickSearchText,
            quickSearchRegex,
            quickSearchCaseSensitive,
            debouncedAdvancedFilterJson,
        ],
        () => fetchServersideData(getServersideDataParameters(), props),
        {
            keepPreviousData: true,
            initialData: {
                data: [],
                recordsFiltered: paginationModel.pageSize,
                recordsTotal: paginationModel.pageSize
            },
            enabled: !!configQuery?.data?.columns?.length && !!gridId,
            // enabled: false,
            // staleTime: 5_000,
            cacheTime: 30_000,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                /* if (!onDataFetchedInit) {*/
                props.onDataFetchInit?.(data);
                setOnDataFetchedInit(true);
                /*}*/
            }
        });
    // endregion

    // region Handlers

    /* Functie om de grid data te herladen. */
    function reloadData() {
        queryClient.invalidateQueries(['gridData', props.gridCode])
    }

    /* Functie dat de dubbelklik op een row afhandelt */
    function handleDoubleClick(row: GridRowParams) {
        if (props.showEditButton && props.editOnRowDoubleClick) {
            const response = props.onEdit?.(row);
            if (response) history.push(response)
        }
    }

    /* Functie dat de volgorde van de rows afhandelt, wanneer deze van volgorde veranderen. */
    async function handleRowOrderChange(params: GridRowOrderChangeParams, event: MuiEvent<{}>, details: GridCallbackDetails) {
        setLoading(true);
        if (props.rowReordering) {
            if (params.oldIndex === params.targetIndex) return;
            props.onRowOrderChange?.(params, event, details)

            if (props.rowReorderUrl) {
                const response = await doPost(props.rowReorderUrl, {id: params.row.id, newIndex: params.targetIndex, oldIndex: params.oldIndex})
                if (isHttpError(response)){
                    toast.error(t('ChangeOrder.Failed'))
                    // reset order to before change
                    console.log(`handleRowOrderChange response error`, response);
                    reloadData()
                } else {
                    toast.success(t('ChangeOrder.Success'))
                    console.log(`handleRowOrderChange response success`, response);
                }
            }
        }
        setLoading(false);
    }

    /* Nakijken of het pagineren ingesteld staat. */
    const allowPagination = !props.rowReordering && !configQuery.data?.disablePagination;
    /* Nakijken of het filteren disabled moet worden. */
    const disableFilter = props.rowReordering || configQuery.data?.disableFilter;
    const disableLoadConfig = !!props.disableLoadConfig || !!disableFilter;
    /* Nakijken of geëxporteerd mag worden. */
    const enableDataExport = configQuery.data?.enableDataExport;
    /* Nakijken of checkboxes getoond mogen worden. */
    const showCheckboxes = props.showCheckbox || (configQuery.data?.enableCheckbox ?? false);

    /* Info over de grid dat weergegeven moet worden in de Developer Info. */
    const developerInfoListItems = [
        {
            title: t('GridInfo.Title'),
            id: 'GridInfo.Title',
            list: [
                {
                    title: 'gridCode',
                    description: props.gridCode,
                    id: 'gridCode',
                    url: !gridId ? undefined : generatePath(DeveloperRouteMap.Grid.Detail(), {gridId: gridId}),
                    urlOpenInNewTab: true,
                }
            ]
        }
    ];

    const deleteFilter = async () => {
        const config = configQuery.data;
        if (!config) return;
        const response = await doDelete("/ServerSideDataTable/DeleteFilter",{gridCode: props.gridCode})
        if (isHttpError(response)){
        } else {
        }
    }

    const saveFilter  = async () => {
        // const config = configQuery.data;
        // if (!config) return;
        // const savedFilter : SavedFilter = {
        //     default: true,
        //     id: -1,
        //     criterium: {
        //         logic: advancedFilter?.logicOperator ?? "or",
        //         criteria: advancedFilter?.items.map(item => {
        //             return {
        //                 value: Array.isArray(item.value) ? item.value : [item.value],
        //                 columnName: item.field,
        //                 condition: Number(item.operator)
        //             };
        //         }) ?? []
        //     },
        //     name: `Grid${props.gridCode}`,
        //     saveResults: true,
        //     gridCode: props.gridCode
        // }
        // const response = await doPost("/ServerSideDataTable/SaveFilter", savedFilter)
        // if (isHttpError(response)){
        //     // reset order to before change
        // } else {
        // }
    }

    const applyFilter = () => {
        setAdvancedFilterJson(JSON.stringify(advancedFilter))
        // saveFilter();
    }

    const setFilterEmpty = () => {
        setAdvancedFilterJson("");
    }
    

    const handlePaginationModelChange = (model, details) => {
        setPaginationModel(model);
    }
    
    useEffect(() => {
        if (!configQuery.data) return;
        dataQuery.refetch()
    }, [paginationModel, configQuery.data])

    /* Handler om de value van de filters to formatteren. */
    function formatFilterValueHandler(filter) {
        if (!filter) return;
        // console.log(`filter`, filter);

        /* Copy maken van filter. */
        let formattedFilter = filter;

        /* Filter-items doorlopen. */
        for (let item of formattedFilter.items) {
            /* Huidige kolomconfiguratie ophalen. */
            const currentColumn = configQuery?.data?.columns?.find(column => column.field === item.field);
            /* Type van de kolom ophalen. */
            const columnType = currentColumn?.type;
            /* Wanneer het kolomtype gelijk is aan "date", de value aanpassen om misverstanden met het dateformat te vermijden. */
            if ((columnType === 'date') && !!item.value) item.value = new Date(item.value).toISOString();
            // if ((columnType === 'date') && !!item.value) item.value = new Date(item.value).toLocaleDateString();
        }
        // console.log(`formattedFilter`, formattedFilter);

        /* Opslaan van de gewijzigde values. */
        // setAdvancedFilter(formattedFilter)
        // setAdvancedFilterJson(JSON.stringify(formattedFilter))

        /* Returnen van formatted filter. */
        return formattedFilter;
    };

    // const formattedAdvancedFilter = useMemo(() => formatFilterValueHandler(advancedFilter), [advancedFilter]);
    //
    // useEffect(() => {
    //     console.log(`formattedAdvancedFilter:`, formattedAdvancedFilter);
    // }, [formattedAdvancedFilter]);

    // endregion
    
    const deleteState = async (gridConfigId: number) => {
        const response = await doDelete(`/ServerSideDataTable/${props.gridCode}/Configuration`, {gridConfigurationId: gridConfigId})
        if (!isHttpError(response)) {
            toast.success(t("deleted"))
            console.log(savedGridConfigs, savedGridConfigs.filter(c => c.gridConfigurationId !== gridConfigId))
            setSavedGridConfigs(savedGridConfigs.filter(c => c.gridConfigurationId !== gridConfigId))
            return;
        }
        toast.error(t("error"))
    }

    const saveState = async (model : SaveGridConfigurationModel) => {
        if (!props.gridCode) return;
        const postModel: GridConfigurationModel = {
            gridCode: props.gridCode,
            gridConfigurationId: -1,
            label: model.label,
            gridState: model.state
        }
        const response = await doPost<GridConfigurationModel>(`/ServerSideDataTable/${props.gridCode}/Configuration`, postModel)
        if (!isHttpError(response)) {
            toast.success(t("saved"))
            setSavedGridConfigs([...savedGridConfigs, response])
            return;
        }
        toast.error(t("error"))
    }
    
    
    return <>
        <DeveloperInfo
            listItems={developerInfoListItems}
        />

        <DataGridPremium
            // error={configQuery.error} Error got removed in V6 TODO
            onRowSelectionModelChange={props.onSelectionChanged}
            rowSelectionModel={props.selectionModel}
            checkboxSelectionVisibleOnly={!configQuery.data?.disablePagination ?? false}
            disableMultipleRowSelection={(props.allowMultiSelect !== undefined) ? !props.allowMultiSelect : false}
            checkboxSelection={showCheckboxes}
            pagination={allowPagination}
            // page={page}
            // pageSize={pageSize}
            pageSizeOptions={props.rowsPerPageOptions ?? defaultRowsPerpageOptions}
            // onPageChange={(page) => pageHandler(page)}
            // onPageChange={(page) => setPage(page)}
            // onPageSizeChange={pageSize => setPageSize(pageSize)}
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationModelChange}
            paginationMode={"server"}
            rowCount={dataQuery.data?.recordsFiltered ?? 0}

            keepNonExistentRowsSelected={props.keepNonExistentRowsSelected}

            disableColumnFilter={disableFilter}
            filterMode={"server"}
            filterModel={advancedFilter}
            onFilterModelChange={(filter) => {
                if (!configQuery.data) return;
                if (filter.items.length === 0) {
                    setUseQuickSearch(true);
                    setAdvancedFilterJson("")
                    // deleteFilter();
                }
                else {
                    setUseQuickSearch(false);
                }
                // setAdvancedFilterJson(JSON.stringify(filter))
                setAdvancedFilter(filter);

                // formatFilterValueHandler(filter);
            }}
            getDetailPanelContent={props.getDetailPanelContent}
            getDetailPanelHeight={props.getDetailPanelHeight}

            density={props.defaultDensity ?? "compact"}

            rowReordering={props.rowReordering}
            onRowOrderChange={handleRowOrderChange}

            initialState={{
                pinnedColumns: {
                    right: ['actions'],
                },
            }}

            sortingMode={"server"}
            sortModel={gridSortModel}
            onSortModelChange={(model) => {
                if (JSON.stringify(model) !== JSON.stringify(gridSortModel)) setGridSortModel(model)
            }}

            loading={configQuery.isLoading || dataQuery.isLoading || dataQuery.isFetching || loading}

            rows={dataQuery.data?.data ?? []}
            columns={configQuery.data?.columns ?? []}
            isRowSelectable={(params) => (props.isRowSelectable?.(params) ?? true)}
            onRowDoubleClick={props.onRowDoubleClick ?? handleDoubleClick}

            getRowClassName={props.getRowClassName}
            
            onCellDoubleClick={props.onCellDoubleClick}
            slots={props.hideToolbar ? {} : {toolbar: SearchToolbar}}
            slotProps={{
                toolbar: {
                    meta: {
                        columns: configQuery.data?.columns ?? []
                    },
                    disableFilter: disableFilter,
                    disableLoadConfig: disableLoadConfig,
                    applyFilter: applyFilter,
                    saveFilter: saveFilter,
                    enableDataExport: enableDataExport,
                    useQuickSearch: useQuickSearch,
                    setUseQuickSearch: setUseQuickSearch,
                    showRefreshButton: true,
                    onRefresh: () => reloadData(),
                    config: {
                        configurations: savedGridConfigs,
                        onSaveState: saveState,
                        onDeleteConfig: deleteState
                    },
                    quickSearch: {
                        hasQuickSearch: configQuery.data?.hasQuickSearch ?? false,
                        hasRegexQuickSearch: configQuery.data?.hasRegexQuickSearch ?? false,
                        hasCaseSensitiveQuickSearch: configQuery.data?.hasCaseSensitiveQuickSearch ?? false,

                        value: quickSearchText,
                        onChange: (event) => setQuickSearchText(event.target.value),
                        clearSearch: () => setQuickSearchText(''),
                        regex: quickSearchRegex,
                        toggleRegex: () => setQuickSearchRegex(!quickSearchRegex),
                        caseSensitive: quickSearchCaseSensitive,
                        toggleCaseSensitive: () => setQuickSearchCaseSensitive(!quickSearchCaseSensitive),
                        focusQuickSearch: props.focusQuickSearch,
                    },
                    advancedSearch: {
                        filter: advancedFilter,
                        savedFilters: configQuery.data?.savedFilters,
                        setAdvancedFilter: setAdvancedFilter
                    },
                },
                ...props.componentsProps
            }}
            // components={props.hideToolbar ? {} : {Toolbar: SearchToolbar}}
            // componentsProps={{
            //     toolbar: {
            //         meta: {
            //             columns: configQuery.data?.columns ?? []
            //         },
            //         disableFilter: disableFilter,
            //         applyFilter: applyFilter,
            //         saveFilter: saveFilter,
            //         enableDataExport: enableDataExport,
            //         useQuickSearch: useQuickSearch,
            //         setUseQuickSearch: setUseQuickSearch,
            //         quickSearch: {
            //             hasQuickSearch: configQuery.data?.hasQuickSearch ?? false,
            //             hasRegexQuickSearch: configQuery.data?.hasRegexQuickSearch ?? false,
            //             hasCaseSensitiveQuickSearch: configQuery.data?.hasCaseSensitiveQuickSearch ?? false,
            //
            //             value: quickSearchText,
            //             onChange: (event) => setQuickSearchText(event.target.value),
            //             clearSearch: () => setQuickSearchText(''),
            //             regex: quickSearchRegex,
            //             toggleRegex: () => setQuickSearchRegex(!quickSearchRegex),
            //             caseSensitive: quickSearchCaseSensitive,
            //             toggleCaseSensitive: () => setQuickSearchCaseSensitive(!quickSearchCaseSensitive),
            //
            //             focusQuickSearch: props.focusQuickSearch,
            //         },
            //         advancedSearch: {
            //             filter: advancedFilter,
            //             savedFilters: configQuery.data?.savedFilters,
            //             setAdvancedFilter: setAdvancedFilter
            //         },
            //     },
            //     ...props.componentsProps
            // }}

            apiRef={props.apiRef}

            autoHeight={true}

            localeText={getGridLocaleText(userLang)}
            sx={dataGridStyling}
        />
    </>;
}

/**
 * Non-Serverside Data Grid with Plattix styling and translated messages
 * @param props
 */
export function PlattixGrid<TModel>(props: DataGridPremiumProps<TModel> & React.RefAttributes<HTMLDivElement>) {
    const userLang = useAppSelector(userLangCodeSelector)
    const sx = {...(props.sx ?? {}), ...dataGridStyling}
    return (
        <DataGridPremium<TModel>
            {...props}

            pageSizeOptions={props.pageSizeOptions ?? defaultRowsPerpageOptions}
            autoHeight={props.autoHeight ?? true}
            density={props.density ?? 'compact'}
            localeText={props.localeText ?? getGridLocaleText(userLang)}
            sx={sx}
        />
    );
}

export interface DataGridProgressBarProps {
    /* This number needs to be a percentage value. */
    value: number | null;
}

const DataGridProgressBar = React.memo(function ProgressBar(props: DataGridProgressBarProps) {
    const value = ~~(props.value ?? 0);
    const getStatus = () => {
        if (props.value === null) return 'default';
        if (value < 30) return 'low';
        if (value >= 30 && value <= 70) return 'medium';
        if (value > 70) return 'high';
        return 'default';
    };

    return (
        <DataGridProgressBarContainer>
            <DataGridProgressBarValue>{`${props.value === null ? '--' : value.toLocaleString()}%`}</DataGridProgressBarValue>
            <DataGridProgressBarBar 
                status={getStatus()}
                style={{ maxWidth: `${value}%` }} 
            />
        </DataGridProgressBarContainer>
    );
});

export function renderProgress(params: GridCellParams) {
    return <DataGridProgressBar value={params.value as (number | null)} />;
}

export const DataGridProgressBarContainer = styled('div', React.forwardRef)(() => {
    return `
        border: 1px solid #555;
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 50%;
        border-radius: 10px;
    `;
});

export const DataGridProgressBarValue = styled('div', React.forwardRef)(() => {
    return `
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    `;
});

export const DataGridDetailContainer = styled('div')(() => {
    return `
        width: 100%;
        padding: 15px;
        margin-top: 15px;
        border: 5px solid var(--styleColor1);
        border-radius: 10px;
        display: flex;
        flex-flow: column nowrap;
        gap: 10px;
        
        .MuiCollapse-root {
            border: none !important;
        }
    `;
});

export interface DataGridProgressBarBarProps {
    status: 'low' | 'medium' | 'high' | 'default';
}

export const DataGridProgressBarBar = styled('div', React.forwardRef)((props: DataGridProgressBarBarProps) => {
    const statusMap = () => {
        if (props.status === 'low') return `background-color: #CC3B33CC;`;
        if (props.status === 'medium') return `background-color: #FFBE0BCC;`;
        if (props.status === 'high') return `background-color: #15C39ACC;`;
        return `background-color: #EEEEEE;`;
    };

    return `
        height: 100%;
        ${statusMap()}
    `;
});

/* Standaard paginering opties. */
const defaultRowsPerpageOptions = [10, 20, 50, 100, 1000];

/* Standaard waarden die in een column moet zitten om te voldoen aan de base values. */
export type BaseColumnValuesTypes = 'default' | 'actions';
export const baseColumnValues: Record<BaseColumnValuesTypes, Partial<GridColDef>> = {
    default: {
        flex: 1,
    },
    actions: {
        minWidth: 100,
        width: 100,
    }
};

/**
 * Get the data grid translations for the language
 * @param language
 */
function getGridLocaleText(language: string) {
    let localization = enUS;
    switch (language.toLowerCase()) {
        case "nl":
            localization = nlNL;
            break;
        case "fr":
            localization = frFR;
            break;
        case "de":
            localization = deDE;
            break;
    }

    return localization.components.MuiDataGrid.defaultProps.localeText
}


type PlattixGridActionsCellItemProps2 = {
    label: string;
    onClick?: () => void;
    icon: IconProp;
    showInMenu?: boolean
}

export function PlattixGridActionsCellItem(props: PlattixGridActionsCellItemProps2) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    if (props.showInMenu) return <GridActionsCellItem
        label={props.label}
        showInMenu={true}
        icon={<FontAwesomeIcon icon={props.icon}/>}
        onClick={props.onClick}
    />

    return (
        <GridActionsCellItem
            showInMenu={false}
            label={props.label}
            onClick={props.onClick}
            icon={<>
                <span
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                >
                    <FontAwesomeIcon icon={props.icon}/>
                </span>
                <Popover
                    id="mouse-over-popover"
                    sx={{
                        pointerEvents: 'none',
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <Typography sx={{p: 1}}>{props.label}</Typography>
                </Popover>
            </>}
        />
    )
}

export function useGridReloader(gridCode: string) {
    const queryClient = useQueryClient();

    return {
        reloadGrid: () => queryClient.invalidateQueries(['gridData', gridCode])
    }
}

export interface PlattixDataGridFormFieldsetProps {
    title: string;
}

export function PlattixDataGridFormFieldset(props: PropsWithChildren<PlattixDataGridFormFieldsetProps>) {
    return (
        <PlattixDataGridFormFieldsetContainer>
            <legend>
                <h5>{props.title ?? ''}</h5>
            </legend>

            {props.children}
        </PlattixDataGridFormFieldsetContainer>
    );
}

export const PlattixDataGridFormFieldsetContainer = styled('fieldset', React.forwardRef)(() => {
    return `        
        border-left: var(--borderRight1);
        padding-left: 10px;
        
        legend {
            margin-bottom: 10px;
        }
    `;
});

export const NoRowsOverlayContainer = styled('div', React.forwardRef)(() => {
    return `
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        gap: 10px;
    `;
});