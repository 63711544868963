import React, {PropsWithChildren, useEffect} from "react";
import ReactDOM from "react-dom";
import {styled} from "goober";
import {CancelButton, ConfirmButton} from "./Buttons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {t, useTranslation} from "PlattixUI/PlattixReactCore/i18n";
import {ContentCardButtons} from "PlattixUI/core/components/ContentCard";
import {useMediaQuery} from "@mui/material";
import {deviceDimensions} from "PlattixUI/core/components/Responsive";

const modalMountPoint =
    document.getElementById("modalRoot")

//region styles
const ModalTitle = styled('h5')`
  margin: 0;
  flex-grow: 1;
  text-align: center;
`
const defaultBorderRadius = "10px";

export const ModalContent = styled('div')(() => {
    const mobile = useMediaQuery(deviceDimensions.mobile);
    const tablet = useMediaQuery(deviceDimensions.tablet);
    const desktop = useMediaQuery(deviceDimensions.desktop);
    
    return `
        width: fit-content;
        min-width: 70vw;
        max-width: ${desktop ? '90vw' : '95vw'};
        ${desktop ? '' : 'width: 95vw;'}
        height: fit-content;
        max-height: 80vh;
        background-color: #fff;
        transition: all 0.3s ease-in-out;
        border-radius: ${defaultBorderRadius};
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: auto;
    `;
});

const ModalBody = styled('div')`
  padding: 15px;
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
`

const ModalHeader = styled('div')`
  padding: 15px 30px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #eee;
  position: relative;
`

export interface ModalCLoseButtonParams {
    loadingstate?: 'loading';
}

export const ModalCLoseButton = styled('div', React.forwardRef)((params: ModalCLoseButtonParams) => {
    return `
        width: fit-content;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translate(0, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        
        ${(params.loadingstate === 'loading') ? `cursor: not-allowed;` : `cursor: pointer;`}
        
        svg {
            height: 1.4em;
        }
    `;
});

const ModalFooter = styled('div')`
  border-top: 1px solid #eee;
`

const ModalDiv = styled('div')`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
    height: 100%;
    
    @media only screen and (min-width: 1151px) {
        @supports (-webkit-touch-callout: none) {
            height: -webkit-fill-available;
        }
    }
    
    &.enter-done {
        opacity: 1;
        pointer-events: visible;
    }
    
    &.exit {
        opacity: 0;
    }
    
    &.enter-done ${ModalContent} {
        transform: translateY(0);
    }
    
    &.exit ${ModalContent}{
        transform: translateY(-200px);
    }
`

// export const ModalDiv = styled('div')(() => {
//     const mobile = useMediaQuery(deviceDimensions.mobile);
//     const tablet = useMediaQuery(deviceDimensions.tablet);
//     const desktop = useMediaQuery(deviceDimensions.desktop);
//
//     return `
//         position: fixed;
//         left: 0;
//         top: 0;
//         right: 0;
//         bottom: 0;
//         background-color: rgba(0, 0, 0, 0.5);
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         z-index: 10;
//        
//         ${desktop ? '' : `
//             @supports (-webkit-touch-callout: none) {
//                 height: -webkit-fill-available;
//             }
//         `}
//        
//         &.enter-done {
//             opacity: 1;
//             pointer-events: visible;
//         }
//        
//         &.exit {
//             opacity: 0;
//         }
//        
//         &.enter-done ${ModalContent} {
//             transform: translateY(0);
//         }
//        
//         &.exit ${ModalContent}{
//             transform: translateY(-200px);
//         }
//     `;
// });

//endregion

export interface ModalBaseProps {
    show: boolean,
    onClose: () => void,
    closeOnOutsideClick?: boolean,
}

export interface ModalProps extends ModalBaseProps {
    title?: string,

    showConfirmButton?: boolean,
    confirmButtonText?: string,
    onConfirm?: () => void,

    showCancelButton?: boolean,
    cancelButtonText?: string,
    onCancel?: () => void,
    closeOnCancel?: boolean,

    loading?: boolean,
    closeOnOutsideClick?: boolean,

    /**
     * Option to make tall modals scroll
     */
    scrollable?: boolean,

    customButton?: JSX.Element | JSX.Element[]
}

export function InfoModal(props: PropsWithChildren<ModalProps>) {
    const {t} = useTranslation();
    return <Modal {...props} cancelButtonText={props.cancelButtonText ?? t('Close')}/>
}

InfoModal.defaultProps = {
    showConfirmButton: false,
}

const Modal = (props: PropsWithChildren<ModalProps>) => {
    const closeOnEscapeKeyDown = e => {
        if (!!props.loading) return;
        if ((e.charCode || e.keyCode) === 27) {
            props.onClose();
        }
    };

    useEffect(() => {
        document.body.addEventListener("keydown", closeOnEscapeKeyDown);
        return function cleanup() {
            document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
        };
    }, []);

    if (!modalMountPoint) {
        console.error("No Modal mount point provided");
        return <></>
    }

    function onCancel() {
        if (props.onCancel) props.onCancel();
        if (props.closeOnCancel) close();
    }

    function close() {
        if (!!props.loading) return;

        props.onClose()
    }

    const confirmButtonText = props.confirmButtonText ?? t('Ok');
    const cancelButtonText = props.cancelButtonText ?? t('Cancel');

    if (!props.show) return <></>
    
    return (
        <BaseModal 
            show={props.show} 
            onClose={props.onClose} 
            loading={props.loading} 
            closeOnOutsideClick={props.closeOnOutsideClick}
        >
            <ModalContent onClick={e => e.stopPropagation()}>
                {props.title &&
                    <ModalHeader>
                        <ModalTitle>{props.title}</ModalTitle>
                        <ModalCLoseButton onClick={close}>
                            <FontAwesomeIcon icon={"times"}/>
                        </ModalCLoseButton>
                    </ModalHeader>
                }
                
                <ModalBody style={{overflowY: props.scrollable ? 'scroll' : 'visible'}}>{props.children}</ModalBody>
                
                {(props.showCancelButton || !!props.customButton || props.showConfirmButton) &&
                    <ModalFooter>
                        <ContentCardButtons padding={['all']}>
                            {props.showCancelButton &&
                                <CancelButton onClick={onCancel}>{cancelButtonText}</CancelButton>}
                            {!!props.customButton && props.customButton}
                            {props.showConfirmButton &&
                                <ConfirmButton loading={props.loading} onClick={props.onConfirm}>{confirmButtonText}</ConfirmButton>}
                        </ContentCardButtons>
                    </ModalFooter>
                }
            </ModalContent>
        </BaseModal>
    )
};

Modal.defaultProps = {
    closeOnOutsideClick: false,
    showConfirmButton: true,
    showCancelButton: true,
    closeOnCancel: true
}


interface BaseModalProps extends ModalBaseProps {
    loading?: boolean
}
export const BaseModal = (props: PropsWithChildren<BaseModalProps>) => {
    const closeOnEscapeKeyDown = e => {
        if (!!props.loading) return;
        if ((e.charCode || e.keyCode) === 27) {
            props.onClose();
        }
    };

    useEffect(() => {
        document.body.addEventListener("keydown", closeOnEscapeKeyDown);
        return function cleanup() {
            document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
        };
    }, []);

    if (!modalMountPoint) {
        console.error("No Modal mount point provided");
        return <></>
    }

    function close() {
        if (!!props.loading) return;

        props.onClose()
    }
    
    if (!props.show) return <></>

    return ReactDOM.createPortal(
        <ModalDiv onClick={(!!props.closeOnOutsideClick ? close : undefined)}>
            <div onClick={e => e.stopPropagation()}>
                {props.children}
            </div>
        </ModalDiv>
        , modalMountPoint
    );
};

export default Modal;