import {FieldError, UseFormReturn} from "react-hook-form";
import {HttpError} from "PlattixUI/PlattixReactCore/CoreTypes";
import {isHttpError} from "PlattixUI/PlattixReactCore/api/Api";
import {t} from "PlattixUI/PlattixReactCore/i18n";
import React from 'react';

export function isFieldError(error: FieldError | any): error is FieldError {
    return (error as FieldError).type !== undefined && (error as FieldError).type !== undefined;
}

export function getErrorMessage(fieldName: string, error: React.ReactNode | FieldError | string | string[] | HttpError | null | undefined ): React.ReactNode | null {
    if (!error) return null;

    if (isHttpError(error)) {
        return getErrorMessage(fieldName, error.errors?.[fieldName]);
    }

    if (Array.isArray(error)) {
        return error.join(", ")
    }

    if (typeof error === 'string'){
        return error;
    }
    
    if (React.isValidElement(error)) {
        return error
    }
    
    if (isFieldError(error)) {
        switch (error.type) {
            case "required":
                return t('Validation.Error.Required');
            default:
                return error.type
        }
    }
    
    return error;
    
    // if (error.message) return error.message;

    // return 'error';

}

/**
 * This function checks if there are unsaved changes in the current session.
 * If there are unsaved changes, it adds an event listener that prompts the user
 * before leaving the page. It returns a function that removes this event listener.
 * If there are no unsaved changes, it returns an empty function.
 *
 * @param {boolean} isDirty - A flag indicating if there are unsaved changes.
 * @return {Function} A function that either removes the event listener or does nothing.
 */
export function checkForUnsavedChanges(isDirty: boolean) {
    /* Tot er een fix is voor het resetten van de dirtyFields na het submitten van een form, wordt deze functie uitgeschakeld. */
    return () => {};
    const handler = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        event.returnValue = "";
    };

    if (isDirty) {
        window.addEventListener("beforeunload", handler);
        return () => {
            window.removeEventListener("beforeunload", handler);
        };
    }

    return () => {};
}