import {Page, PageButtonsProps, PageHeader} from "PlattixUI/core/Header";
import {ContentCardButtons, PlattixCard, PlattixCardContainer} from "PlattixUI/core/components/ContentCard";
import {
    PlattixCheckbox,
    PlattixInput,
    PlattixSubmitButton,
    PlattixValidatedInput
} from "PlattixUI/core/components/form/Input";
import {t} from "PlattixUI/PlattixReactCore/i18n";
import {PlattixMultiStepForm, PlattixMultiStepFormStep} from "PlattixUI/core/components/MultiStepForm";
import {AddressComponent} from "PlattixUI/core/components/AddressComponent";
import {useFieldArray, useForm, UseFormReturn} from "react-hook-form";
import {PlattixCodeFormSelect, SelectOption} from "PlattixUI/core/components/form/Select";
import React, {Fragment, useCallback, useEffect, useState} from "react";
import {doPost, isHttpError, useHostUrl, usePlattixMutation, usePlattixQuery} from "PlattixUI/PlattixReactCore/api/Api";
import {PlattixDataGrid} from "PlattixUI/core/grid/PlattixDataGrid";
import {PlattixForm} from "PlattixUI/core/components/form/Form";
import {AccountModel} from "PlattixUI/types/AccountTypes";
import {PlattixSwal} from "PlattixUI/core/components/Swal";
import {LoadingScreen} from "PlattixUI/core/components/Loader";
import {useCodesQuery} from "PlattixUI/util/CodesUtil";
import {Iban, Required, RequiredIf, VatNumber} from "PlattixUI/core/forms/FormValidators";
import {HttpError} from "PlattixUI/PlattixReactCore/CoreTypes";
import {toast} from "react-toastify";
import {AddButton, CancelButton, ConfirmButton, DeleteIconButton, EditButton} from "PlattixUI/core/components/Buttons";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {generatePath, Link} from "react-router-dom";
import {Actions, ActionsLeft, ActionsRight} from "../PlattixUI/core/components/ActionBar";
import {GridDensity, GridRowSelectionModel} from "@mui/x-data-grid-premium";
import {Endpoints} from "../configuration/ApiEnpointMap";
import {styled} from "goober";
import {useHistory, useParams} from "react-router";
import {DefaultValues} from "react-hook-form/dist/types/form";
import _ from "lodash";

export interface AccountDataComponentProps {
    isReadonly?: boolean,
    form: any,
    isValid?: any,
    error?: FormErrorType,
}

export interface CreateAccountWizardParams {
    /**
     * URL dat je kan meegeven om na de submit te redirecten naar een andere pagina.
     * Wrap de url in encodeURIComponent() mo tekens zoals "/" te behouden.
     **/
    returnUrl?: string;
}

export interface CreateAccountWizardProps {
    postCallback?: string;
    onCancel?: any;
    /**
     * Function that is called when account is successfully created
     * @param accountId: the accountId of the newly created account
     */
    onSuccess?: (accountId: number, account: AccountModel) => void;
    /* Use the endpoint of the project, instead of using the endpoints of Tactix. */
    useInternalUrl?: boolean;
    defaultValues?: DefaultValues<AccountModel> | undefined;
}

export interface AccountComponentProps {
    accountId: number;
    pageHeader?: string;
    standalone?: boolean;
    hidebank?: boolean;
    formName?: string;
    hideSubmitButton?: boolean;
    /* Use the endpoint of the project, instead of using the endpoints of Tactix. */
    useInternalUrl?: boolean;
}

export interface AccountsComponentProps {
    companyName?: string | null;
    selectedAccountId?: number | null;
    buttons?: PageButtonsProps;
}

export enum enumAccountType {
    EnergyCommunity = "2",
    Private = "3",
    Company = "4",
    PublicInstitution = "5",
    ChargingStation = "6",
    City = "Stad/Gemeente"
}

type FormErrorType = HttpError | null;

export function AccountTypeDataComponent(props: AccountDataComponentProps) {
    const form = props.form;
    const {register, watch} = form;
    const accountTypeCode = watch("accountTypeCode");

    useEffect(() => {
        form.trigger("accountTypeCode");
    }, [accountTypeCode, form])

    return (
        <>
            <div className="form-group row form-group-center accountTypeSelector">
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="radio"
                        value={enumAccountType.Private}
                        id={'PrivateAccount'}
                        {...register("accountTypeCode", {required: true})}
                    />
                    <label
                        className="form-check-label"
                        htmlFor={'PrivateAccount'}
                    >
                        {t("PrivateAccount")}
                    </label>
                </div>
                <div className="form-check custom-radio">
                    <input
                        className="form-check-input"
                        type="radio"
                        value={enumAccountType.Company} 
                        {...register("accountTypeCode", {required: true})}
                        id={'CompanyAccount'}
                    />
                    <label
                        className="form-check-label"
                        htmlFor={'CompanyAccount'}
                    >
                        {t("CompanyAccount")}
                    </label>
                </div>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="radio"
                        value={enumAccountType.City} 
                        {...register("accountTypeCode", {required: true})}
                        id={'CityAccount'}
                    />
                    <label
                        className="form-check-label"
                        htmlFor={'CityAccount'}
                    >
                        {t("CityAccount")}
                    </label>
                </div>
            </div>
        </>
    )
}

AccountGeneralDataComponent.defaultValues = {
    deliveryAddressSame: true,
    invoiceAddressSame: true
}

export function AccountGeneralDataComponent(props: AccountDataComponentProps) {
    const form = props.form;
    const {register, setValue, getValues, watch} = form;
    const accountTypeCode = watch("accountTypeCode");
    const deliveryAddressSame = watch("deliveryAddressSame");
    const invoiceAddressSame = watch("invoiceAddressSame");
    const [initialized, setInitialized] = useState<Boolean>(false);

    useEffect(() => {
        if (!initialized) {
            setValue("deliveryAddressSame", getValues("deliveryAddressSame"));
            setValue("invoiceAddressSame", getValues("invoiceAddressSame"));
            setInitialized(true);
        }
    }, [initialized, setValue])

    return (
        <>

            <fieldset>
                <legend>
                    <h5>{t('Account.Settings.PersonalData')}</h5>
                </legend>
                {(accountTypeCode === enumAccountType.Private) &&
                    <PlattixValidatedInput
                        // register={register('firstName')}
                        formHook={form}
                        name={'firstName' as never}
                        label={t('FirstName')}
                        validation={(accountTypeCode === enumAccountType.Private) ? Required() : undefined}
                        readOnly={props.isReadonly}
                        error={props.error}
                    />
                }

                <PlattixValidatedInput<AccountModel>
                    label={t('name')}
                    formHook={form}
                    name={'name'}
                    validation={Required()}
                    readOnly={props.isReadonly}
                    error={props.error}
                />

                <PlattixCodeFormSelect<AccountModel>
                    name="languageCode"
                    form={form}
                    label={t("Language")}
                    namespace={"DataAccessLayer.Data"}
                    tableName={"Language"}
                    error={props.error}
                />
            </fieldset>

            <fieldset>
                <legend>
                    <h5>{t('Account.Settings.Servicepoint')}</h5>
                </legend>
                <AddressComponent 
                    form={form} 
                    addressModel={"mailingAddress"} 
                    isReadonly={props.isReadonly}
                />
            </fieldset>

            <fieldset>
                <legend>
                    <h5>{t('Account.Settings.ShippingAddress')}</h5>
                </legend>

                <PlattixCheckbox
                    register={register("deliveryAddressSame")}
                    label={t("RequestController.DeliveryAddressQuestion")}
                    error={props.error}
                />

                {(!deliveryAddressSame) &&
                    <AddressComponent
                        form={form}
                        addressModel="deliveryAddress"
                    />
                }
            </fieldset>

            <fieldset>
                <legend>
                    <h5>{t('Account.Settings.InvoiceAddress')}</h5>
                </legend>

                <PlattixCheckbox
                    register={register("invoiceAddressSame")}
                    label={t("RequestController.InvoiceAddressQuestion")}
                    error={props.error}
                />
                {(!invoiceAddressSame) &&
                    <AddressComponent 
                        form={form} 
                        addressModel="invoiceAddress"
                    />
                }
            </fieldset>

        </>
    )
}

export function AccountCompanyDataComponent(props: AccountDataComponentProps) {
    const form = props.form;
    const {register} = form;
    
    return (
        <fieldset>
            <legend>
                <h5>{t('Account.Settings.CompanyData')}</h5>
            </legend>
            <PlattixValidatedInput<AccountModel>
                name={"customer.companyNumber"} 
                label={t("CompanyNumber")} 
                formHook={form}
                validation={[RequiredIf('accountTypeCode', {
                    value: enumAccountType.Company,
                    errorMessage: t('Account.Error.RequiredForCompany')
                })]}
                error={props.error}
            />
            <PlattixValidatedInput<AccountModel>
                name={"customer.vatNumber"} 
                formHook={form} 
                label={t("VatNumber")}
                validation={[VatNumber(), RequiredIf('accountTypeCode', {
                    value: enumAccountType.Company,
                    errorMessage: t('Account.Error.RequiredForCompany')
                })]}
                error={props.error}
            />
            <PlattixCodeFormSelect<AccountModel> 
                name="customer.vatTypeCode" 
                form={form} 
                label={t("VatType")}
                namespace={"DataAccessLayer.Data"}
                tableName={"Vattype"}
                error={props.error}
            />
            <PlattixCodeFormSelect<AccountModel> 
                name="customer.paymentTypeCode"
                form={form} 
                label={t("PaymentType")}
                namespace={"DataAccessLayer.Data"} 
                tableName={"PaymentType"}
                error={props.error}
            />
            <PlattixCodeFormSelect<AccountModel> 
                name="customer.invoiceFrequencyCode"
                form={form}
                label={t("Contract.Contract.InvoiceFrequency")}
                namespace={"DataAccessLayer.Data"}
                tableName={"InvoiceFrequency"}
                error={props.error}
            />
            <PlattixInput 
                name="FixedDueDate" 
                register={register("customer.fixedDueDate")}
                label={t("FixedDueDate")}
                error={props.error}
            />
            <PlattixCheckbox
                register={register("customer.coContractor")}
                label={t("CoContractor")}
                error={props.error}
            />

            <PlattixCheckbox
                register={register("customer.intraCommunity")}
                label={t("intraCommunity")}
                error={props.error}
            />

            <PlattixCheckbox
                register={register("customer.reverseChargeReference")}
                label={t("reverseChargeReference")}
                error={props.error}
            />
        </fieldset>
    )
}

export function AccountCommunicationDataComponent(props: AccountDataComponentProps) {
    const form = props.form;
    const {register, getValues} = form;

    const communicationTypesQuery = useCodesQuery('AccountCommunicationType');
    const communicationTypes = communicationTypesQuery.data ?? [];

    return (
        <fieldset>
            <legend>
                <h5>{t('Account.Settings.Communication')}</h5>
            </legend>

            {!getValues().accountCommunications ? (
                communicationTypes.map((x, index) => {
                        return (
                            <div key={x.value.toString()}>
                                <PlattixInput
                                    type="hidden"
                                    register={register(`accountCommunications[${index}].accountCommunicationTypeCode`, {value: x.value})}
                                    label={x.value.toString()}
                                    error={props.error}
                                />
                                <PlattixInput
                                    register={register(`accountCommunications[${index}].value`)}
                                    label={x.label}
                                    error={props.error}
                                />
                            </div>
                        )
                    }
                )
            ) : (
                communicationTypes.map((x, index) => {
                    const element = getValues().accountCommunications?.findIndex(element => element.accountCommunicationTypeCode === x.value);
            
                    if (!element && element !== 0) return (
                        <Fragment key={x.value.toString()}></Fragment>
                    );
            
                    return (
                        <div key={x.value.toString()}>
                            <PlattixInput
                                type="hidden"
                                register={register(`accountCommunications[${element}].value`, {value: x.value})}
                                label={x.label.toString()}
                                error={props.error}
                            />
            
                            <PlattixInput
                                register={register(`accountCommunications[${element}].value`)}
                                label={x.label}
                                error={props.error}
                            />
                        </div>
                    )
                })
            )}
        </fieldset>
    )
}

type AccountBankAccountComponentProps = {
    form: UseFormReturn<AccountModel, object>
    error?: FormErrorType;
}

export function AccountBankAccountComponent(props: AccountBankAccountComponentProps) {

    const fieldArray = useFieldArray<AccountModel>({
        control: props.form.control,
        name: "bankAccountNumbers"
    })

    return (
        <fieldset>
            <legend>
                <h5>{t('Account.Settings.BankingAccount')}</h5>
            </legend>

            {fieldArray.fields.map((field, index) => {
                const bankAccountNumber = props.form.watch(`bankAccountNumbers.${index}.bankAccountNumber`) ?? '';
                const belgianNumber = !!bankAccountNumber?.toLowerCase().startsWith('be');
                return (
                    <AccountBankAccountComponentFieldset>
                        <AccountBankAccountComponentLegend>
                            <b>{t('Account.Settings.BankingAccount.Account.Title', {index: index + 1})}</b>
                            <DeleteIconButton onClick={() => fieldArray.remove(index)} />
                        </AccountBankAccountComponentLegend>
                        <React.Fragment key={field.id}>
                            <PlattixValidatedInput<AccountModel>
                                type={'hidden'}
                                formHook={props.form}
                                name={`bankAccountNumbers.${index}.accountBankAccountNumberId` as const}
                            />

                            <PlattixValidatedInput<AccountModel>
                                label={t('bankAccountNumber')}
                                // validation={[Required(), Iban()]}
                                validation={[Required()]}
                                formHook={props.form}
                                error={props.error}
                                name={`bankAccountNumbers.${index}.bankAccountNumber` as const}
                            />
                            <PlattixValidatedInput<AccountModel>
                                label={t('bicCode')}
                                formHook={props.form}
                                error={props.error}
                                readOnly={belgianNumber || !bankAccountNumber?.length}
                                name={`bankAccountNumbers.${index}.bankingInstitutionCode` as const}
                            />
                            <PlattixValidatedInput<AccountModel>
                                label={t('Bank')}
                                formHook={props.form}
                                error={props.error}
                                readOnly={belgianNumber || !bankAccountNumber?.length}
                                name={`bankAccountNumbers.${index}.bankingInstitutionDescription` as const}
                            />
                            <PlattixValidatedInput<AccountModel>
                                label={t('showOnInvoice')}
                                formHook={props.form}
                                error={props.error}
                                name={`bankAccountNumbers.${index}.showOnInvoice` as const}
                                type={"checkbox"}
                            />
                        </React.Fragment>
                    </AccountBankAccountComponentFieldset>
                );
            })}

            <ContentCardButtons>
                <EditButton
                    icon={faPlus}
                    onClick={() => fieldArray.append({accountBankAccountNumberId: -1})}
                >
                    {t('account.bankAccountNumber.Add')}
                </EditButton>
            </ContentCardButtons>

        </fieldset>
    )
}

export const AccountBankAccountComponentLegend = styled('legend', React.forwardRef)(() => {
    return `
        display: flex;
        flex-flow: row nowrap;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
        background-color: #f5f5f5;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 10px;
        border-bottom: unset;
    `;
});

export const AccountBankAccountComponentFieldset = styled('fieldset', React.forwardRef)(() => {
    return `
    `;
});

export function CreateAccountWizard(props: CreateAccountWizardProps) {
    const form = useForm<AccountModel>({
        mode: "all",
        defaultValues: {
            languageCode: 'NL',
            deliveryAddressSame: true,
            invoiceAddressSame: true,
            ...props.defaultValues,
        }
    });
    const {register, handleSubmit, watch} = form;
    const accountTypeCode = watch("accountTypeCode");
    const [initialized, setInitialized] = useState<boolean>(false);
    const TactixUrl = useHostUrl("Tactix");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<FormErrorType>(null);
    
    const history = useHistory();
    const params = useParams<CreateAccountWizardParams>();
    
    const submitUrl = !props.useInternalUrl ? `${TactixUrl}/api/Accounts/Save` : `/Accounts/Save`;

    useEffect(() => {
        if (!initialized) {
            register("extended", {value: true});
            setInitialized(true);
        }
    }, [initialized, register]);

    const onSubmit = async (data) => {
        setLoading(true)
        // Only called when formstate is valid

        // const response: any = await doPost<AccountModel>(`${TactixUrl}/api/Accounts/Save`, data, {remote: true})
        const response = await doPost<AccountModel>(submitUrl, data, {remote: !props.useInternalUrl})
        if (isHttpError(response)) {
            setError(response);
            setLoading(false)
            return;
        }
        setError(null)

        if (props.postCallback && response.accountId) {
            const url = `${props.postCallback}&accountid=${response.accountId}`
            const postCallbackResponse = await doPost(url, undefined, {remote: true})
            if (isHttpError(postCallbackResponse)) {
                await PlattixSwal({
                    icon: 'error',
                    text: postCallbackResponse.detail,
                });
                setLoading(false)
                return;
            }
        }

        props.onSuccess?.(response.accountId, response);
        toast.success(t('Account.Creation.Successful.Msg'))
        setLoading(false);
        if (!!params.returnUrl) history.push(decodeURIComponent(params.returnUrl));
    }

    return (
        <>
            <PlattixCard header={t('Account.Create.New')}>
                <PlattixMultiStepForm 
                    onSubmission={handleSubmit(onSubmit)} 
                    onCancel={props.onCancel} 
                    loading={loading}
                    form={form as any}
                >
                    <PlattixMultiStepFormStep step={1} title={t('Account.ChooseType')}>
                        <AccountTypeDataComponent form={form} error={error} />
                    </PlattixMultiStepFormStep>

                    {(accountTypeCode) &&
                        <PlattixMultiStepFormStep step={2} title={t('Account.General')}>
                            <AccountGeneralDataComponent form={form} error={error} />
                        </PlattixMultiStepFormStep>
                    }

                    {(accountTypeCode) &&
                        <PlattixMultiStepFormStep step={3} title={t('Account.Communications')}>
                            <AccountCommunicationDataComponent form={form} error={error} />
                        </PlattixMultiStepFormStep>
                    }

                    {(accountTypeCode === enumAccountType.Company || accountTypeCode === enumAccountType.City) &&
                        <PlattixMultiStepFormStep step={4} title={t('Account.Customer')}>
                            <AccountCompanyDataComponent form={form} error={error} />
                        </PlattixMultiStepFormStep>
                    }
                </PlattixMultiStepForm>
            </PlattixCard>
        </>
    )
}

export function AccountComponent(props: AccountComponentProps) {
    const [error, setError] = useState<FormErrorType>(null)
    const form = useForm<AccountModel>({ mode: 'all' });
    const {handleSubmit, watch, reset} = form;
    const accountTypeCode = watch("accountTypeCode");
    const TactixUrl = useHostUrl("Tactix");

    const queryEnabled = (!!TactixUrl && !!props.accountId)
    const queryKey = ['AccountQuery', props.accountId];
    const url = (!props.useInternalUrl && !!TactixUrl) ? `${TactixUrl}/api/Accounts/${props.accountId}` : `/Accounts/${props.accountId}`;

    const accountQuery = usePlattixQuery<AccountModel>(
        queryKey,
        url,
        {},
        {
            enabled: queryEnabled
        },
        {
            remote: !props.useInternalUrl,
        }
    );

    const onSuccessHandler = (model) => {
        toast.success(t('Saved'))
        setError(null)
    };
    
    const onErrorHandler = (error, variables, context) => {
        toast.error(t('Error'))
        setError(error)
    };

    const accountMutation = usePlattixMutation(queryKey, url, {},{
        onSuccess: onSuccessHandler,
        onError: onErrorHandler,
    },{
        remote: !props.useInternalUrl,
    })

    const account = accountQuery.data;
    
    useEffect(() => {
        reset(account);
    }, [account, reset]);
    const onSubmit = async (data) => {
        await accountMutation.mutateAsync(data)
        // if (accountMutation.isSuccess) window.location.reload();
    }

    if (accountQuery.isLoading) return <LoadingScreen/>;

    const component = (
        <>
            <PlattixCard header={t("Account")} width={'span2'}>
                <PlattixForm id={props.formName ?? "AccountForm"} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    
                    <AccountTypeDataComponent form={form} error={error} />
                    <AccountGeneralDataComponent form={form} error={error} />
                    {(accountTypeCode === enumAccountType.Company 
                        || accountTypeCode === enumAccountType.EnergyCommunity 
                        || accountTypeCode === enumAccountType.City) 
                    && <AccountCompanyDataComponent form={form} error={error} /> }
                    <AccountCommunicationDataComponent form={form} error={error} />
                    {!props.hidebank && <AccountBankAccountComponent form={form} error={error} />}

                    { !props.hideSubmitButton &&
                        <ContentCardButtons>
                            <PlattixSubmitButton
                                name={t("Submit")}
                                form={props.formName ?? "AccountForm"}
                                onSubmit={handleSubmit(onSubmit)}
                                loading={accountMutation.isLoading}
                            />
                        </ContentCardButtons>
                    }

                </PlattixForm>
            </PlattixCard>
        </>
    );

    if (props.standalone) return (
        <Page
            title={t('Account')}
            loading={queryEnabled && accountQuery.isLoading}
            error={accountQuery.error ?? accountMutation.error}
            backButton
        >
            <PlattixCardContainer>
                {component}
            </PlattixCardContainer>
        </Page>
    );
    return component;
}

export function AccountsComponent(props: AccountsComponentProps) {
    const [loading, setLoading] = useState(false);
    const [addAccounts, setAddAccounts] = useState<boolean>(false);
    /* State dat bijhoudt welke rijen er geselecteerd zijn. */
    const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
    
    const history = useHistory();

    const gridRowSelectionHandler = (selectionModel, details) => {
        setSelectedRows(selectionModel);
    };

    /* Functie dat wordt uitgevoerd op het moment dat de gebruiker op de knop drukt om de owners toe te kennen. */
    const createAccountOwners = async () => {
        if (!props.selectedAccountId) return;
        if (!selectedRows.length) return toast.error(t('Accounts.NoSelectedRows.Title'));
        setLoading(true);

        const response = await doPost(generatePath(Endpoints.Account.CreateAccountOwners, {ownerAccountId:  props.selectedAccountId}), selectedRows);

        setLoading(false);

        if (isHttpError(response)) return toast.error(t('Accounts.CreateAccountOwners.Error.Title'));
        console.log(`response`, response)
        toast.success(t('Accounts.CreateAccountOwners.Success.Title'));
    }
    
    const onAddAccountsButtons: PageButtonsProps = {
        other: {
            cancelAssignCustomer: (
                <CancelButton onClick={() => setAddAccounts(false)}>
                    {t('assignCustomer.Cancel.Button')}
                </CancelButton>
            ),
        },
        base: {
            createAssignments: (
                <AddButton onClick={() => createAccountOwners()}>
                    {t('createAssignments')}
                </AddButton>
            ),
        }
    }
    
    const defaultButtons: PageButtonsProps = {
        other: {
            assignCustomer: (
                <EditButton onClick={() => {setAddAccounts(!addAccounts)}}>
                    {t('assignCustomer')}
                </EditButton>
            ),
        },
        base: {
            /** ***********************************************
             * Dit werkt niet binnen Tactix omdat het endpoint hiervan niet bestaat binnen Tactix. Als je dit nodig hebt; definiëer deze knop dan als prop in de route of de parentcomponent buiten de submodule.
             *********************************************** **/
            // newAccount: (
            //     <ConfirmButton
            //         onClick={() => history.push(generatePath(`/RegisterServicepointWizard`, {
            //             returnUrl: encodeURIComponent(generatePath(`/AccountsOverview`))
            //         }))}
            //     >
            //         {t('assignCustomer.New.Button')}
            //     </ConfirmButton>
            // ),
        }
    }
    
    // const pageButtons: PageButtonsProps = {
    //     ...props.buttons,
    //     ...(!!addAccounts ? onAddAccountsButtons : defaultButtons)
    // };
    
    const pageButtons: (() => PageButtonsProps) = () => {
        const buttons = (!!addAccounts ? onAddAccountsButtons : defaultButtons);
        return _.merge(buttons, props.buttons)
    };

    return (
        <Page
            title={t("AccountsOverview", {Company: props.companyName ?? ''})}
            backButton
            buttons={pageButtons()}
        >
            
            {(!!props.selectedAccountId && (props.selectedAccountId > 0)) &&
                <PlattixCardContainer>
                    {!addAccounts &&
                        <PlattixCard header={t("AccountsOverview", {Company: props.companyName ?? ''})} width={'full'}>
                            <PlattixDataGrid 
                                gridCode={"AccountsForOwner"}
                                defaultDensity={"compact"}
                                includeLanguageCode
                                showEditButton
                                parameters={{"param_owner_account_id": props.selectedAccountId.toString()}}
                                onEdit={(row) => `/Account/${row.id}`}
                            />
                        </PlattixCard>
                    }

                    {!!addAccounts &&
                        <PlattixCard header={t("AccountsOverviewNotAssigned", {Company: props.companyName ?? ''})} width={'full'}>
                            <PlattixDataGrid 
                                gridCode={"Accounts"}
                                defaultDensity={"compact"}
                                includeLanguageCode
                                showCheckbox
                                onSelectionChanged={gridRowSelectionHandler}
                                showEditButton
                                parameters={{"param_owner_account_id": props.selectedAccountId.toString()}}
                                onEdit={(row) => `/Account/${row.id}`}
                            />
                        </PlattixCard>
                    }
                </PlattixCardContainer>
            }
        </Page>
    )
}

