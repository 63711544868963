import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faCaretLeft} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {emptyDataValue} from "PlattixUI/core/components/ContentCard";

/**
 * Capitalize the first letter of the string.
 * 
 * The rest of the string is not changed
 * @param string: string to capitalize
 */
export function Capitalize(string: string): string{
    return string[0].toUpperCase() + string.substring(1)
}

export const pad = (number: number) => {
    if (number < 10) {
        return '0' + number;
    }
    return number;
}

export const formatDateTimeBaseValue = (date) => {
    const d = date;
    return d.getFullYear()
        + '-' + pad(d.getMonth() + 1)
        + '-' + pad(d.getDate())
        + 'T' + pad(d.getHours())
        + ':' + pad(d.getMinutes())
    // + ':' + pad(d.getSeconds());
}

export const emptyNumberValue: string = `--,--`;
export const emptyStringValue: string = `---`;

export interface FormatValueType {
    value?: string | number | Date | null;
    prefix?: string;
    suffix?: string;
}

export const formatValue = (data: FormatValueType) => {
    const prefix = data.prefix ? `${data.prefix} ` : ``;
    const suffix = data.suffix ? ` ${data.suffix}` : ``;
    const descriptionValue = !!data.value ? `${prefix}${data.value}${suffix}` : emptyDataValue;

    return descriptionValue;
};

export function formatStructuredPaymentMessage(data: string) : string {
    return data?.trim().replace(/^(\d{3})(\d{4})(\d{5})$/g, '+++$1/$2/$3+++');
}

export function sortLabels( a, b ) {
    if ( a.label < b.label ) return -1;
    if ( a.label > b.label ) return 1;
    return 0;
}