import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import {FontAwesomeIcon, FontAwesomeIconProps} from "@fortawesome/react-fontawesome";
import {styled} from "goober";
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { PlattixTooltip } from './ContentCard';
import {PlattixImage, PlattixImageProps} from "PlattixUI/util/thumbor/PlattixImage";
import {t} from "PlattixUI/PlattixReactCore/i18n";

export function PlattixNavigation(props: React.PropsWithChildren<any>) {
    return (
        <div className="menu" id="menu">
            {props.children}
        </div>
    );
}

export interface CustomPlattixNavigationItemProps extends PlattixNavigationItemProps {
    menuItems?: CustomPlattixNavigationItemProps[];
}

export interface PlattixNavigationItemProps {
    Classes?: string;
    Group?: boolean;
    Inactive?: boolean;
    OptionType?: string;
    Name: string;
    Hidden?: boolean;
    Link?: string;
    PageRefresh?: boolean;
    HasPermission?: string;
    PreventClosing?: boolean;
    translationKeysProvided?: boolean;
    
    icon?: PlattixImageProps;
    
    indicator?: {
        show?: boolean;
        message?: string;
    }
}

export function PlattixNavigationItem(props: React.PropsWithChildren<PlattixNavigationItemProps>) {
    const [closed, setClosed] = useState(false);
    
    const translationKeyHandler = (key: string) => {
        if (!props.translationKeysProvided) return key;
        return t(key);
    };
    
    const closedHandler = (e) => {
        e.stopPropagation();
        setClosed(!closed);
    }
    
    const indicator = () => {
        if (!props.indicator?.show) return <></>;
        
        if (props.indicator.message) return (
            <>
                <PlattixTooltip
                    title={translationKeyHandler(props.indicator.message)}
                >
                    <WarningIcon />
                </PlattixTooltip>
            </>
        );
        
        return <FontAwesomeIcon icon={faCircleExclamation} />;
    }
    
    return (
        <div 
            className={'item' 
                + (props.Classes ? ' ' + props.Classes : '') 
                + (props.Group ? ' group' : '') 
                + (props.Inactive ? ' inactive' : '') 
                + (props.children && !props.Group ? ' has-children' : '') 
                // + (props.Hidden && props.Group ? ' hide' : '')
                + (props.Hidden ? ' hide' : '')
                + (props.children && closed ? ' closed' : '')
            } 
            {...props.OptionType ? { 'option-type': props.OptionType } : {}} 
            {...props.HasPermission ? { 'has-permission': props.HasPermission } : {}} 
            {...props.PreventClosing ? '' : { 'onClick': closedHandler }} 
            
        >

            {(props.children) ?
                [
                    <p key={props.Name}>
                        <MenuIconContainer>
                            {!!props.icon &&
                                <PlattixImage
                                    width={props.icon.width ?? 10}
                                    height={props.icon.width ?? 10}
                                    alt={props.icon.alt}
                                    fileSource={props.icon.fileSource}
                                />
                            }
                            {translationKeyHandler(props.Name)}
                        </MenuIconContainer>
                        {((props.Group && props.children)
                            ? <FontAwesomeIcon icon={closed ? 'angle-left' : 'angle-down'} />
                            : null
                        )}
                    </p>
                ]
                :
                <>
                    <NavLink exact to={props.Link ? props.Link : '#'}>
                        <MenuText>
                            {indicator()}
                            {translationKeyHandler(props.Name)}
                        </MenuText>
                    </NavLink>
                </>
            }
            
            
            {props.children}
            
        </div>
    );
}

export const MenuText = styled('span', React.forwardRef)(() => {
    return `
        display: flex;
        flex-flow: row nowrap;
        gap: 5px;
        align-items: center;
        
        svg {
            font-size: 0.8em;
            &, * {
                color: var(--textColorRed);
            }
        }
    `;
});

export const MenuIconContainer = styled('span', React.forwardRef)(() => {
    return `
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;
        
        img {
            height: 15px;
            object-fit: contain;
        }
    `;
});

export const WarningIcon = React.forwardRef(function MyComponent(props: Omit<FontAwesomeIconProps, 'icon'>, ref) {
    return (
        <FontAwesomeIcon
            icon={faCircleExclamation}
            forwardedRef={ref}
            {...props}
        />
    );
});