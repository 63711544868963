import {availableUrlParams, LogoutResultModel} from "pages/Auth/Auth";
import {doGet, isHttpError} from "PlattixUI/PlattixReactCore/api/Api";
import {useEffect, useMemo} from "react";
import {BaseApiEndpoints} from "PlattixUI/PlattixReactCore/api/BaseApiEndpointMap";
import {toast} from "react-toastify";
import {t} from "PlattixUI/PlattixReactCore/i18n";

export interface LogoutProps {
    
}

export function Logout(props: LogoutProps) {
    const urlParams = useMemo(() => new URLSearchParams(window.location.search), [window.location.search]);
    const logoutId = urlParams.get(availableUrlParams.logoutId);
    
    const logoutHandler = async () => {
        if (!logoutId) return;

        const response = await doGet<LogoutResultModel>(BaseApiEndpoints.Account.Logout, {
            logoutId: logoutId,
        });
        
        if (isHttpError(response)) return toast.error(t('Logout.Error.Title'));
        if (!response.postLogoutRedirectUri) return toast.error(t('Logout.Error.NoPostLogoutRedirectUri.Title'));
        
        return window.location.replace(response.postLogoutRedirectUri);
    };

    useEffect(() => {
        logoutHandler();
    }, []);
    
    return (
        <>
            <p>Logout</p>
        </>
    );
}