import {css, styled} from "goober";
import React from "react";

export const dataGridStyling = {
    width: '100%',
    border: 'none',
    '.MuiDataGrid-main': {
        // 'border-top': 'var(--borderBottom1)',
        // 'margin-top': '25px',
    },
    '.MuiCollapse-root': {
        'borderBottom': 'var(--borderBottom1)',
    },
    '.MuiDataGrid-toolbarContainer': {
        'borderBottom': 'var(--borderBottom1)',
        'marginBottom': '15px',
        'gap': '15px',
        'paddingBottom': '10px',
    },
    '.MuiButton-root': {
        'fontFamily': 'var(--defaultFontFamilyText)',
        'textTransform': 'none',
        'color': 'unset',
        'display': 'flex',
        'justifyContent': 'center',
        'lineHeight': 'unset',
        'fontSize': '1em',
    },
    '.MuiButton-root:hover': {
        'backgroundColor': '#f5f5f5',
    },
    '.MuiDataGrid-filterForm': {
        'width': '100%',
        'gap': '10px',
        '.MuiDataGrid-filterFormColumnInput, .MuiDataGrid-filterFormOperatorInput, .MuiDataGrid-filterFormValueInput': {
            'width': '100%',
            '.MuiBox-root': {
                'padding': 0,
                'width': '100%',
                '.MuiFormControl-root': {
                    'width': '100%',
                }
            },
        },
    },
    '.MuiDataGrid-panelFooter': {
        'justifyContent': 'flex-end',
        'padding': '10px 0',
    },
    '.MuiDataGrid-columnHeaders': {
        'background': '#f5f5f5',
        'borderRadius': '10px',
    },
    '.MuiDataGrid-pinnedColumnHeaders': {
        'background': '#f5f5f5',
        'boxShadow': 'none',
    },
    '.MuiDataGrid-cell--editable': {
        'cursor': 'pointer',
    },
    '.MuiDataGrid-row--editing': {
        'backgroundColor': '#22628522',
        '.MuiDataGrid-cell': {
            'backgroundColor': 'unset',
        },
    },
    '.module-content-search': {
        'marginLeft': 'auto',
        'maxWidth': '500px !important',
        'width': 'fit-content',
        '.MuiInput-root': {
            '&>svg': {
                'cursor': 'unset'
            },
            
            '.MuiInput-input': {
                'width': '300px',
            },
        },
        '.MuiTextField-root': {
            'margin': 0,
            '.MuiInput-root': {
                'gap': '10px',
            },
            '.MuiInputAdornment-root': {
                'display': 'flex',
                'height': '100%',
                'maxHeight': 'unset',
                'gap': '0px',
                'margin': 0,
                '.MuiButtonBase-root': {
                    'all': 'unset',
                    'cursor': 'pointer',
                    'borderRadius': '10px',
                    'padding': '2px 7px',
                    '&:hover': {
                        'background': '#f5f5f5',
                    },
                },
            },
        },
        '.MuiInputBase-root': {
            '.MuiInputBase-input': {
                '&:focus-visible': {
                    'boxShadow': 'none !important',
                },
            },
            '&:after': {
                'borderBottom': '2px solid var(--styleColor1)',
            },
        },
    },
    '.completed': {
        'backgroundColor': '#15C39A22',
    },
    '.MuiDataGrid-editInputCell': {
        'height': '100%',
    },
    '.MuiDataGrid-aggregationColumnHeaderLabel': {
        'fontSize': '0.8em',
        'bottom': '2px',
    },
    '.treeview-groupheader-servicepoint': {
        'backgroundColor': '#E5F1F0',
    },
    '.treeview-groupheader-energycommunity': {
        'backgroundColor': '#E2F0E2',
    },
    '.newRow': {
        'backgroundColor': '#F1FDFF',
    },
    '.okRow.': {
        'backgroundColor': '#EDFFE9',
    },
    '.cancelled': {
        'backgroundColor': '#ECECEC',
    },
    '.errorRow': {
        'backgroundColor': '#A92F43',
    },
    '.almostOkRow': {
        'backgroundColor': '#FFF9EC',
    },
    '.notOkRow': {
        'backgroundColor': '#FFEFEF',
    },
    '.parent': {
        'backgroundColor': '#effbff',
    },
    '.regular': {
        'backgroundColor': '#FFFFFF'
    },
    '.grayedOut': {
        'opacity': 0.7,
        // 'MuiDataGrid-cellContent': {
        //     color: '#333333EE'
        // },
    },
}

export const DataGridToolButton = css`
    cursor: pointer;
    fontFamily: var(--defaultFontFamilyText);
    textTransform: none;
    color: unset;
    display: flex;
    justifyContent: center;
    lineHeight: unset;
    fontSize: 1em;
    border: none;
    padding: 4px 5px;
    background: none;
    borderRadius: 4px;
    gap: 8px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition-property: background-color, box-shadow, border-color, color;
    transition-duration: 250ms, 250ms, 250ms, 250ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: 0ms, 0ms, 0ms, 0ms;
    
    &:hover {
        background-color: #f5f5f5;
    }
`;

export const DataGridHeader = css`
    background: green;
`;

export interface CellStatusComponentProps {
    color: string;
}

export const CellStatusComponent = styled('div', React.forwardRef)((props: CellStatusComponentProps) => {
    return `
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 3px 7px;
        
        border: 1px solid ${props.color};
        
        &, p {
            color: ${props.color};
            font-size: 0.9em;
        }
    `;
});