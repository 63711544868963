import {PropsWithChildren} from "react";
import {Link} from "react-router-dom";

export interface PlattixLinkProps {
    link?: string | undefined | null,
    onClick?: () => void
}

export function PlattixLink(props: PropsWithChildren<PlattixLinkProps>){
    
    // console.log(`props.onClick`, props.onClick)
    
    if (props.link)
        return <Link to={props.link} >{props.children}</Link>
    
    if (props.onClick)
        return <p className={'pointer'} onClick={props.onClick}>{props.children}</p>
    
    return <>{props.children}</>
}