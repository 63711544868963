import React, {forwardRef, PropsWithChildren, useEffect, useRef, useState} from 'react';
import {MainMenu} from "../../MainMenu";
import {css, styled} from "goober";
import {faArrowLeft, faArrowRight, faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BottomNavigation, BottomNavigationAction, useMediaQuery} from "@mui/material";
import {PlattixConfiguration} from "../../configuration/Configuration";
import {t} from "../PlattixReactCore/i18n";
import {bodyPaddingX, deviceDimensions, ResponsiveComponent, ResponsiveSwipeDrawer} from "./components/Responsive";
import {useHistory, useLocation} from "react-router";
import {LogoutLink} from "./components/Buttons";
import {LanguageMenuComponent} from "./Partials/LanguageMenu";
import {useAppSelector} from "../PlattixReactCore/hooks";
import {userSelector} from "../PlattixReactCore/UserSlice";
import {
    CustomPlattixNavigationItemProps,
    PlattixNavigation,
    PlattixNavigationItem,
    PlattixNavigationItemProps
} from "./components/MenuComponents";
import {Spin as Hamburger} from 'hamburger-react'
import {Link} from "react-router-dom";
import {CookieBannerComponent} from "PlattixUI/core/Partials/CookieBanner";
import {SidebarItem} from "PlattixUI/core/Sidebar";
import {PlattixImage} from "PlattixUI/util/thumbor/PlattixImage";
import i18n from "PlattixUI/PlattixReactCore/i18n";
import {LoadingScreen} from "PlattixUI/core/components/Loader";

export const menuBarHeight = '60px';

const NavigationColapseBtnContainer = styled('div')`
    display: flex;
    flex-flow: column wrap;
    align-items: flex-end;
    width: 100%;
    gap: 10px;
`;

const NavigationColapseBtn = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background: #f5f5f5;
    border-radius: 10px;
    cursor: pointer;
    
    &:hover {
        background: #ccc;
    }
`;

export interface NavigationComponentProps {
    menuItems?: PlattixNavigationItemProps[];
}

export function NavigationComponent(props: NavigationComponentProps) {
    const [navigationOpened, setNavigationOpened] = useState(true);
    
    const navigationCollapseToggle = () => {
        setNavigationOpened(!navigationOpened);
    };

    return (
        <>
            <section className={`navigation ${!navigationOpened ? 'closed' : ''}`}>
                <NavigationColapseBtnContainer>
                    <NavigationColapseBtn onClick={navigationCollapseToggle}>
                        <FontAwesomeIcon icon={navigationOpened ? faArrowLeft : faArrowRight} className='module-icon'/>
                    </NavigationColapseBtn>
                    <hr />
                </NavigationColapseBtnContainer>

                {navigationOpened &&
                    <>
                        {props.menuItems ?
                            <CustomMainMenu menuItems={props.menuItems} />
                            :
                            <>
                                <MainMenu/>
                            </>
                        }
                    </>
                }
                
            </section>

        </>
    );
}

// const MobileTabPage = styled('div')`
//     border: 1px dotted red;
//     width: 100vw;
//     height: calc(100vh - ${menuBarHeight});
//     background: #FFF;
//     position: fixed;
//     top: 0;
//     left: 0;
// `;

interface MobileTabPageProps {
    show?: number,
}

const MobileTabPage = styled('div')((props: MobileTabPageProps) => {
    return `
        width: 100vw;
        height: 100vh;
        background: #FFF;
        position: fixed;
        top: 0;
        left: 0;
        padding-bottom: calc(${menuBarHeight} + ${bodyPaddingX});
        display: ${!props.show ? 'none' : 'flex'};
        flex-flow: column nowrap;
        gap: 20px;
    `
});

const MobileTabPageHeader = styled('div')`
    display: flex;
    width: 100%;
    padding: 15px ${bodyPaddingX};
    background: var(--backgroundColor2);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    gap: 20px;
`;

const MobileTabHeaderInfo = styled('div')`
    display: flex;
    width: 100%;
    flex-flow: column nowrap;
    gap: 5px;
    
    & > * {
        margin: 0;
    }
`;

const MobileTabSubHeading = styled('div')`
    display: flex;
    width: 100%;
    padding: 0;
`;

const MobileTabNav = styled('div')`
    display: flex;
    height: 20px;
    width: 100%;
    padding: 0 ${bodyPaddingX};
`;

const MobileTab = styled('div')`
    width: 100%;
    max-height: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: visible;
    display: flex;
    flex-flow: column nowrap;
    gap: 25px;
    padding: 0 ${bodyPaddingX};
    font-size: 1.3em;
`;

const MobileTabHeaderIcon = styled('div')`
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #333;
    color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: none;
    position: relative;
    top: 5px;
`;

interface MobileNavigationProps {
    currentType: string,
    type: string,
    title?: string,
    subHeading?: Element | JSX.Element,
    headerIcon?: Element | JSX.Element,
}

export function MobileNavigation(props: PropsWithChildren<MobileNavigationProps>) {    
    const showMobileTabPage = +(props.currentType === props.type);

    return (
        <MobileTabPage show={showMobileTabPage}>            
            <MobileTabPageHeader>
                {!!props.headerIcon &&
                    <MobileTabHeaderIcon>
                        <>
                            {props.headerIcon}
                        </>
                    </MobileTabHeaderIcon>}
                
                <MobileTabHeaderInfo>
                    <h5>{props.title}</h5>
                    {!!props.subHeading &&
                        <MobileTabSubHeading>
                            <>
                                {props.subHeading}
                            </>
                        </MobileTabSubHeading>}
                </MobileTabHeaderInfo>
                
            </MobileTabPageHeader>
            {/*<hr />*/}
            {/*<MobileTabNav>nav</MobileTabNav>*/}
            <MobileTab>
                {props.children}
            </MobileTab>
        </MobileTabPage>
    );
}

const MenuBar = css`
    position: fixed;
    width: 100vw;
    bottom: 0;
    height: ${menuBarHeight} !important;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    background-color: #f5f5f5 !important;
    padding: 0 ${bodyPaddingX};
    
    .MuiBottomNavigationAction-root {
        gap: 5px;
        background: #f5f5f5;
        padding: 6px 12px;
        min-width: 50px;
        
        &.Mui-selected {
            color: var(--styleColor1);
        }
        
        img {
            height: 23px;
        }
        
        svg {
            height: 15px;
        }
        
        .MuiBottomNavigationAction-label {
            font-size: 1em;
            white-space: nowrap;
            
            &:not(.Mui-selected) {
                display: none;
            }
        }
    }
`;

const UserNameContainer = styled('div')`
    display: flex;
    width: 100%;
    flex-flow: column nowrap;
    font-size: 1.3em;
`;

export function MobileBarMenu() {
    const defaultTab = 'main';
    // const [selected, setSelected] = useState(defaultTab);
    const [showMobileNavigation, setShowMobileNavigation] = useState(defaultTab);
    
    const {user} = useAppSelector(userSelector);

    const location = useLocation()
    
    const handleMobileNavigation = (type) => {
        if (showMobileNavigation === type) {
            setShowMobileNavigation(defaultTab);
        } else {
            setShowMobileNavigation(type);
        }
    }
    
    useEffect(() => {
        setShowMobileNavigation(defaultTab)
    }, [location]);

    return (
        <>
            <MobileNavigation 
                currentType={showMobileNavigation} 
                type={'search'}
                title={t('Search')}
            >
                {t('Search')}
            </MobileNavigation>
            <MobileNavigation 
                currentType={showMobileNavigation} 
                type={'menu'}
                title={t('Menu')}
            >
                <MainMenu/>
            </MobileNavigation>
            <MobileNavigation 
                currentType={showMobileNavigation} 
                type={'notifications'}
                title={t('Notifications')}
            >
                
                {t('Notifications')}
            </MobileNavigation>
            <MobileNavigation 
                currentType={showMobileNavigation} 
                type={'profile'}
                title={`${user?.firstName ? user?.firstName : ''} ${user?.lastName ? user?.lastName : ''}`}
                // title={t('Profile')}
                headerIcon={<FontAwesomeIcon icon={'user'}/>}
                subHeading={
                    <UserNameContainer>
                        {user?.email}
                    </UserNameContainer>
                }
            >
                
                
                {/*<p className="role">{user?.roles.join(', ')}</p>*/}
                {/*<hr/>*/}
                {/*<LanguageMenuComponent/>*/}
                {/*<p className="option">{t('MenuUserSettings')}</p>*/}
                {/*<LogoutLink className="option link"/>*/}
                <ProfileMenu/>
            </MobileNavigation>
            
            <BottomNavigation
                value={showMobileNavigation}
                onChange={(value, newValue) => {
                    setShowMobileNavigation(newValue);
                }}
                className={MenuBar}
            >
                <BottomNavigationAction
                    label={PlattixConfiguration.platformName}
                    // icon={<img src={PlattixConfiguration.appIcon} alt={PlattixConfiguration.platformName}/>}
                    icon={<PlattixImage
                        width={30}
                        height={30}
                        fileSource={PlattixConfiguration.appIcon}
                        alt={PlattixConfiguration.platformName}
                    />}
                    value={'main'}
                    onClick={() => handleMobileNavigation('main')}
                />
                <BottomNavigationAction
                    label={t('Search')}
                    icon={<FontAwesomeIcon icon={'search'}/>}
                    value={'search'}
                    onClick={() => handleMobileNavigation('search')}
                />
                <BottomNavigationAction
                    label={t('Menu')}
                    icon={<FontAwesomeIcon icon={'bars'}/>}
                    value={'menu'}
                    onClick={() => handleMobileNavigation('menu')}
                />
                <BottomNavigationAction
                    label={t('Notifications')}
                    icon={<FontAwesomeIcon icon={'inbox'}/>}
                    value={'notifications'}
                    onClick={() => handleMobileNavigation('notifications')}
                />
                <BottomNavigationAction
                    label={t('Profile')}
                    icon={<FontAwesomeIcon icon={'user'}/>}
                    value={'profile'}
                    onClick={() => handleMobileNavigation('profile')}
                />
            </BottomNavigation>
        </>
    );
}

export function ProfileMenu() {
    const [openDrawer, setOpenDrawer] = useState({
        language: false,
    });
    
    const toggleDrawer = (anchor: string, open: boolean, e?: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
        setOpenDrawer({ ...openDrawer, [anchor]: open });
        if (e) e.stopPropagation();
    };
    
    // const handleMenuClick = (anchor: string, open: boolean) => {
    //     console.log(e)
    // };

    return (
        <PlattixNavigation>
            <PlattixNavigationItem
                Name={t('User.Settings')}
                Group={true}
                PreventClosing={true}
            >
                
                <div className={'item'}>
                    <p onClick={(e) => toggleDrawer('language', true, e)}>
                        {t('Language')}
                    </p>
                    
                        <ResponsiveSwipeDrawer
                            devices={['mobile', 'tablet']}
                            title={t('Language')}
                            open={openDrawer['language']}
                            onClose={() => toggleDrawer('language', false)}
                            onOpen={() => toggleDrawer('language', true)}
                        >
                            <LanguageMenuComponent/>
                        </ResponsiveSwipeDrawer>
                </div>
                
                <LogoutLink className="option link"/>

            </PlattixNavigationItem>
        </PlattixNavigation>
    );

}

export function ScrollToTopOnNavigation() {
    const { pathname } = useLocation();

    useEffect(() => {
        console.log('scroll')
        window.scrollTo(0, 0);
    }, [pathname]);
    
    return null;
}

export interface MobileMenuProps {
    firstName?: string;
    lastName?: string;
    email?: string;
    menu?: JSX.Element;
    loggedIn?: boolean;
}

export function MobileMenu(props: PropsWithChildren<MobileMenuProps>) {
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [touchStart, setTouchStart] = useState<number | null>(null)
    const [touchEnd, setTouchEnd] = useState<number | null>(null)

    const cookieAcceptedString: string = 'cookies-accepted';
    const [openDrawer, setOpenDrawer] = useState({
        cookies: localStorage.getItem(cookieAcceptedString) === 'true' ? false : true,
        language: false,
        help: false,
    });

    const toggleDrawer = (anchor: string, open: boolean) => {
        setOpenDrawer({ ...openDrawer, [anchor]: !openDrawer[anchor] });
    };
    
    const initalOpenSidebarItem = localStorage.getItem(cookieAcceptedString) === 'true' ? null : 'cookies';
    const currentOpenItem = useState<string | null>(initalOpenSidebarItem);
    const [theCurrentOpenItem, setTheCurrentOpenItem] = currentOpenItem;

    const contentRef = useRef<null | HTMLDivElement>(null);

    const mobile = useMediaQuery(deviceDimensions.mobile);
    const tablet = useMediaQuery(deviceDimensions.tablet);
    const desktop = useMediaQuery(deviceDimensions.desktop);
    
    const history = useHistory();
    
    // region Swipe events

    const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
    const minSwipeDistance = 150;

    const onTouchStart = (e) => {
        setTouchEnd(null);
        setTouchStart(e.targetTouches[0].clientX)
    };
    
    useEffect(() => {
        if (localStorage.getItem(cookieAcceptedString) === 'true') setOpenDrawer({ ...openDrawer, ['cookies']: false });
    }, [localStorage.getItem(cookieAcceptedString)]);

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        
        // if (!iOS) return;
        
        if (isLeftSwipe) setMenuOpen(false);
        if (isRightSwipe) setMenuOpen(true);
    };
    
    // endregion

    history.listen((location, action) => {
        if (menuOpen) setMenuOpen(false);
    })

    const contentClickHandler = () => {
        if (desktop) return;
        if (!menuOpen) return;
        setMenuOpen(false);
    };
    
    if (desktop) return (
        <>
            {props.children}
        </>
    );
    
    return (
        <MobileMenuContainerBackground
            openmenu={menuOpen ? 'true' : undefined}
        >
            <TopBarMenu>
                <TopBarMenuContent>
                    <Hamburger
                        toggled={menuOpen}
                        toggle={setMenuOpen}
                        size={20}
                        rounded
                        hideOutline={false}
                        color={'#333'}
                    />
                </TopBarMenuContent>
            </TopBarMenu>
            
            <MobileMenuContainer 
                openmenu={menuOpen ? 'true' : undefined} 
                onTouchStart={onTouchStart} 
                onTouchMove={onTouchMove} 
                onTouchEnd={onTouchEnd}
            >

                <MobileMenuMenu openmenu={menuOpen ? 'true' : undefined}>
                    {props.loggedIn &&
                        <>
                            <MobileMenuHeader>
                                <MobileMenuHeaderIcon>
                                    <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
                                </MobileMenuHeaderIcon>
                                <MobileMenuHeaderText>
                                    <h5>{props.firstName} {props.lastName}</h5>
                                    <p>{props.email}</p>
                                </MobileMenuHeaderText>
                            </MobileMenuHeader>
                            <hr />
                        </>
                    }
                    <MainMenu/>

                    {props.loggedIn &&
                        <LogoutLink className="option link"/>
                    }
                    <hr />
                    <MobileMenuFooter>

                        <SidebarItem
                            name={'cookies'}
                            icon={'cookie-bite'}
                            explanation={t('Cookies')}
                            circle={true}
                            needsConfirmation={true}
                            onClick={() => toggleDrawer('cookies', true)}
                            hideOptions={(mobile || tablet)}
                            show={currentOpenItem}
                        >
                            <ResponsiveSwipeDrawer
                                devices={['mobile', 'tablet']}
                                title={t('Cookies.WeUseCookies')}
                                open={openDrawer['cookies']}
                                onClose={() => toggleDrawer('cookies', false)}
                                onOpen={() => toggleDrawer('cookies', true)}
                            >
                                <CookieBannerComponent show={currentOpenItem}/>
                            </ResponsiveSwipeDrawer>
                        </SidebarItem>

                        <SidebarItem
                            name={'language'}
                            icon={'globe-europe'}
                            explanation={t('Language')}
                            circle={true}
                            onClick={() => toggleDrawer('language', true)}
                            hideOptions={(mobile || tablet)}
                            show={currentOpenItem}
                        >
                            <ResponsiveSwipeDrawer
                                devices={['mobile', 'tablet']}
                                title={t('Language')}
                                open={openDrawer['language']}
                                onClose={() => toggleDrawer('language', false)}
                                onOpen={() => toggleDrawer('language', true)}
                            >
                                <LanguageMenuComponent/>
                            </ResponsiveSwipeDrawer>
                        </SidebarItem>

                        <SidebarItem
                            name={'help'}
                            icon={'question'}
                            explanation={t('Plattix.HelpCenter')} circle={true}
                            onClick={() => toggleDrawer('help', true)}
                            hideOptions={(mobile || tablet)}
                            show={currentOpenItem}
                        >
                            <ResponsiveSwipeDrawer
                                devices={['mobile', 'tablet']}
                                title={t('Plattix.HelpCenter')}
                                open={openDrawer['help']}
                                onClose={() => toggleDrawer('help', false)}
                                onOpen={() => toggleDrawer('help', true)}
                            >
                                {/*<Link className="option" to="/Legal/CompanyDetails">{t('Plattix.CompanyDetails')}</Link>*/}
                                <Link className="option" to="/Legal/TermsAndConditions">{t('Plattix.TermsAndConditions')}</Link>
                                <Link className="option" to="/Legal/PrivacyPolicy">{t('Plattix.PrivacyPolicy')}</Link>
                                <Link className="option" to="/Legal/CookiePolicy">{t('Plattix.CookiePolicy')}</Link>
                            </ResponsiveSwipeDrawer>
                        </SidebarItem>
                        
                    </MobileMenuFooter>
                </MobileMenuMenu>

                <MobileMenuContent 
                    openmenu={menuOpen ? 'true' : undefined}
                    onClick={contentClickHandler}
                    ref={contentRef}
                >
                    {props.children}
                </MobileMenuContent>
                
            </MobileMenuContainer>
        </MobileMenuContainerBackground>
    );
}

export interface MobileMenuStylingProps {
    openmenu?: string;
}

export const mobileMenustylingSnippets = {
    transition: `transition: all 0.3s cubic-bezier(0.63, 0.06, 0.47, 0.95);`,
    padding: `padding: 25px;`,
    menuWidth: `73vw`,
    scale: `scale(0.75)`,
    topBarHeight: `60px`,
    // background: `var(--styleColor3)`,
    // background: `#FEF9E6`,
    // background: `#F5F7F9`,
    background: `#EAF0F5`,
    borderRadius: `20px`,
};

export const TopBarMenu = styled('div')(() => {
    return `
        width: 100vw;
        height: ${mobileMenustylingSnippets.topBarHeight};
        display: flex;
        align-items: center;
    `;
});

export const TopBarMenuContent = styled('div')(() => {
    return `
        width: ${mobileMenustylingSnippets.menuWidth};
        height: 100%;
        display: flex;
        align-items: center;
        padding: 25px 25px 20px 10px;
        
        .hamburger-react {
            user-select: none;
            margin-right: auto;
            
            & > div {
                height: 3px !important;
            }
        }
        
        .link {
            font-weight: 700;
        }
    `;
});

export const MobileMenuContainer = styled('div')((props: MobileMenuStylingProps) => {
    const fillAvailable = false ? `
        @supports (-webkit-touch-callout: none) {
            height: -webkit-fill-available;
            min-height: -webkit-fill-available;
            max-height: -webkit-fill-available;
        }
    ` : ``;
    
    return `
        width: 100vw;
        height: calc(100vh - ${mobileMenustylingSnippets.topBarHeight});
        min-height: calc(100vh - ${mobileMenustylingSnippets.topBarHeight});
        max-height: calc(100vh - ${mobileMenustylingSnippets.topBarHeight});
        height: -webkit-fill-available;
        min-height: -webkit-fill-available;
        max-height: -webkit-fill-available;
        display: flex;
        flex-flow: row nowrap;
        overflow: hidden;
       
       ${fillAvailable}
        
        ${!!props.openmenu && `
            .content * {
                user-select: none;
                pointer-events: none;
            }
        `}
    `;
});

export const MobileMenuContent = styled('div', forwardRef)((props: MobileMenuStylingProps) => {
    return `
        position: fixed;
        width: 100vw;
        height: 100%;
        left: ${props.openmenu ? mobileMenustylingSnippets.menuWidth : '0vw'};
        transform: ${props.openmenu ? mobileMenustylingSnippets.scale : 'none'};
        border-radius: ${props.openmenu ? mobileMenustylingSnippets.borderRadius : '0'};
        box-shadow: ${props.openmenu ? 'var(--shadow2)' : 'none'};
        filter: ${props.openmenu ? 'blur(1px)' : 'none'};
        
        ${!!props.openmenu ? `
            & * {
                user-select: none;
                pointer-events: none;
            }
        ` : ''}
        
        ${mobileMenustylingSnippets.transition}
        
        &, .content {
            overflow-y: ${props.openmenu ? 'hidden' : 'auto'};
        }
    `;
});

export const MobileMenuMenu = styled('div')((props: MobileMenuStylingProps) => {
    return `
        position: fixed;
        width: ${mobileMenustylingSnippets.menuWidth};
        height: calc(100vh - ${mobileMenustylingSnippets.topBarHeight});
        left: ${props.openmenu ? '0vw' : '-' + mobileMenustylingSnippets.menuWidth};
        transform: ${props.openmenu ? 'none' : mobileMenustylingSnippets.scale};
        display: flex;
        flex-flow: column nowrap;
        gap: 10px;
        
        @supports (-webkit-touch-callout: none) {
            height: -webkit-fill-available;
        }
        
        ${mobileMenustylingSnippets.transition}
        ${mobileMenustylingSnippets.padding}
                
        .menu {
            width: 100%;
            position: unset;
            left: unset;
            top: unset;
        }
        
    `;
});

export const MobileMenuHeader = styled('div')(() => {
    return `
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        gap: 10px;
    `;
});

export const MobileMenuHeaderIcon = styled('div')(() => {
    return `
        min-width: 30px;
        width: 30px;
        height: 30px;
        min-height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        background: var(--styleColor4);
        color: var(--styleColor5);
        font-size: 14px;
        margin-top: 5px;
    `;
});

export const MobileMenuHeaderText = styled('div')(() => {
    return `
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        gap: 5px;
        
        h5, p {
            margin: 0;
        }
    `;
});

export const MobileMenuContainerBackground = styled('div')((props: MobileMenuStylingProps) => {
    return `
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-flow: column nowrap;
        background: ${props.openmenu ? mobileMenustylingSnippets.background : 'none'};
        
        @supports (-webkit-touch-callout: none) {
            min-height: -webkit-fill-available;
        }
    `;
});

export const MobileMenuFooter = styled('div')(() => {
    return `
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        gap: 10px;
        align-items: center;
        justify-content: space-around;
        
        .option {
            &.active {
                font-weight: 700;
            }
        }
        
        .icon {
            width: 30px;
            height: 30px;
            background: #333;
            color: #fff;
            border-radius: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    `;
});

export interface CustomMainMenuProps {
    menuItems: CustomPlattixNavigationItemProps[];
}

export function CustomMainMenu(props: CustomMainMenuProps) {
    const renderMenuItems = (menuItems: CustomPlattixNavigationItemProps[]) => {
        return (
            <>
                {menuItems.map((menuItem, index) => {
                    return (
                        <PlattixNavigationItem key={menuItem.Name} {...menuItem}>
                            {menuItem.menuItems && renderMenuItems(menuItem.menuItems)}
                        </PlattixNavigationItem>
                    )
                })}
            </>
        );
    }
    
    return (
        <PlattixNavigation>
            {renderMenuItems(props.menuItems)}
        </PlattixNavigation>
    );
}