import {UseFormReturn} from "react-hook-form";
import {GridProps} from "../../../grid/PlattixDataGrid";
import {useEffect, useState} from "react";
import {ChooseFromGridModal} from "../../../grid/ChooseFromGridModal";
import {t} from "../../../../PlattixReactCore/i18n";
import {
    AccountPlattixInputButtons,
    AccountPlattixInputContainer,
    AccountPlattixInputContent
} from "./AccountPlattixInput";
import {PlattixValidatedInput} from "../Input";
import {PlattixTooltip} from "../../ContentCard";
import {DeleteIconButton, EditIconButton} from "../../Buttons";
import {faPen} from "@fortawesome/free-solid-svg-icons/faPen";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {Required} from "../../../forms/FormValidators";
import {AccountModel} from "../../../../types/AccountTypes";


export type InvoicePlattixInputProps = {
    form: UseFormReturn,
    name: string,
    readonly?: boolean,
    required?: boolean,
    options?: ChooseInvoiceOptions,
}

export type InvoicePlattixInputType = {
    salesId: number;
    number: string;
    customerAccount: AccountModel;
    totalBaseVatAmount: number;
    totalVatAmount: number;
}

export interface ChooseInvoiceOptions extends Pick<GridProps, "includeLanguageCode" | "parameters"> {
    gridCode?: string,
    title?: string,
    description?: JSX.Element | string,
    allowCreateAccount?: boolean,
    onInvoiceSelect?: (row) => void,
    showModalByDefault?: boolean
}

export interface InvoiceGridModel {
    salesId: number,
    invoiceNumber: string,
    customerName: string,
    invoiceDate: string,
    amount: number,
    paymentStatus: string
}

export function InvoicePlattixInput(props: InvoicePlattixInputProps) {
    const [showChooseInvoiceModal, setShowChooseInvoiceModal] = useState<boolean>(props.options?.showModalByDefault ?? false);

    const invoice = props.form.watch(props.name) as InvoicePlattixInputType | undefined;

    function onSelect(id: any, row: any) {
        props.form.setValue(props.name, {
            salesId: id,
            number: row.invoicenumber,
            customerAccount: {
                fullName: row.customerfullname
            },
            totalBaseVatAmount: row.totalbasevatamount,
            totalVatAmount: row.totalvatamount,
        }, {shouldDirty: true, shouldTouch: true, shouldValidate: true})
        props.options?.onInvoiceSelect?.(row);
    }

    function clearInvoice(){
        props.form.setValue(props.name, undefined, {shouldDirty: true, shouldTouch: true, shouldValidate: true})
    }

    useEffect(()=>{
        props.form.register(props.name, {required: props.required})
        props.form.trigger(props.name)
    },[props.form, props.name, props.required])
    

    return (
        <>
            <AccountPlattixInputContainer>
                <AccountPlattixInputContent>
                    <PlattixValidatedInput type={"hidden"} formHook={props.form} name={props.name} required={props.required}/>
                    {invoice?.salesId &&
                        <>
                            {invoice.number} - {invoice.customerAccount?.fullName ?? "Unknown"}: {'\u20AC'}{invoice.totalBaseVatAmount + invoice.totalVatAmount}
                        </>
                    }
                    {!invoice?.salesId &&
                        <>
                            {t('Input.Invoice.NoInvoiceSelected')}
                        </>
                    }
                </AccountPlattixInputContent>
                {!props.readonly &&
                    <AccountPlattixInputButtons>
                        {!props.required && invoice &&
                            <PlattixTooltip title={t('Input.Account.ClearInvoice')}>
                                <DeleteIconButton
                                    icon={faXmark}
                                    onClick={() => clearInvoice()}
                                />
                            </PlattixTooltip>
                        }
                        <PlattixTooltip title={t('Input.Account.ChooseInvoiceModal')}>
                            <EditIconButton
                                icon={faPen}
                                onClick={() => setShowChooseInvoiceModal(true)}
                            />
                        </PlattixTooltip>
                    </AccountPlattixInputButtons>
                }
            </AccountPlattixInputContainer>
            {!props.readonly &&
                <>

                    <ChooseFromGridModal
                        gridCode={props?.options?.gridCode ?? "InvoicesForOwner"}
                        parameters={props?.options?.parameters}
                        title={t('chooseInvoice')}
                        includeLanguageCode={props?.options?.includeLanguageCode ?? true}
                        onSelect={onSelect}
                        show={showChooseInvoiceModal} onClose={() => setShowChooseInvoiceModal(false)}
                        {...props.options}
                    />

                </>
            }
        </>
    );
}