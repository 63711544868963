import React, {useState} from 'react';
import {t, useTranslation} from '../../../PlattixReactCore/i18n';
import {UserState} from "../../../PlattixReactCore/UserSlice";
import {PlattixInput, PlattixSubmitButton} from "../../components/form/Input";
import {useForm} from "react-hook-form";
import {Spinner} from "../../components/Loader";
import {ForgotPasswordModel, request2faReset} from "../../../PlattixReactCore/api/AccountApi";
import {isHttpError} from "../../../PlattixReactCore/api/Api";
import {CancelButton, ConfirmButton} from "../../components/Buttons";
import {Link} from "react-router-dom";
import {ContentCardButtons, PlattixFormError} from "../../components/ContentCard";
import {HttpError} from "../../../PlattixReactCore/CoreTypes";
import {PlattixForm} from "../../components/form/Form";
import {LoginOptions, LoginOptionsProps} from "PlattixUI/core/pages/Account/LoginOptions";

export interface TwoFAProps { 
    userState?: UserState;
    hideTitle?: boolean;
    returnUrl?: string | null;
    loginOptions?: LoginOptionsProps;
}

export function Reset2FAPage(props: TwoFAProps) {
    const {register, handleSubmit, watch, formState: {errors}} = useForm<ForgotPasswordModel>();

    const [loading, setLoading] = useState(false);
    const [resetSuccess, setresetSuccess] = useState(false);
    const [resetError, setResetError] = useState<HttpError | null>(null);
    
    async function onSubmit(model: ForgotPasswordModel) {
        setLoading(true)

        const response = await request2faReset(model);

        setLoading(false);
        if (isHttpError(response)) {
            setResetError(response);
        } else {
            setresetSuccess(true);
        }
    }

    if (resetSuccess) {
        return (
            <div className="login-form">
                <p>{t('Email.TwoFactorConfirmationToUser.CheckText')}</p>
                <ContentCardButtons>
                    <Link to={'/'}><ConfirmButton>{t('Link.To.Login')}</ConfirmButton></Link>
                </ContentCardButtons>
            </div>
        )
    }

    return (
        <div className="login-form">

            {!props.hideTitle &&
                <div>
                    <h5 className="login-title">{t('Forgot2FAH4')}</h5>
                    {/*<p>{t('Reset2FA.Reset')}</p>*/}
                </div>
            }
            
            <PlattixForm onSubmit={handleSubmit(onSubmit)}>
                <PlattixInput
                    disabled={loading}
                    type={"email"}
                    name="Email"
                    register={register("email", {required: true})}
                    label={t('Email')}
                    placeholder={t('EmailPH')}
                    error={errors.email}
                />

                {loading && <Spinner/>}

                <ContentCardButtons>
                    {/*<Link to={"/"}><CancelButton/></Link>*/}
                    <PlattixSubmitButton disabled={loading} name={t('Reset2FA')}/>
                </ContentCardButtons>
                
            </PlattixForm>
            
            {
                !!resetError &&
                <PlattixFormError>
                    <p className="login-error">{resetError.errors?.["_global"]}</p>
                </PlattixFormError>
            }

            <LoginOptions
                hide={{
                    TwoFactorAuth: true,
                    TwoFactorRecoveryAuth: true,
                }}
                returnUrl={props.returnUrl}
                {...props.loginOptions}
            />

        </div>
    );
}